@import "../../bootstrap/variables.less";
@import "../variables.less";

/* START giftregistry.less */
// Affects: Giftregistry
//====================
.giftregistry
{
    font-size: 11px;
    font-weight: 700;
    min-height: 20px;
    height: auto;
    z-index: 9999;
    position: absolute;
    top: 0;
    right: 0;

    // DVPDS - Reworking of the less file to generate the same CSS
    .Menu-Skin-StaticItem {font-size: small;}
    .Menu-Vertical {
        position: relative;
        left: 0em;
        z-index: 9850;
    }
    // The menu adapter renders an unordered list (ul) in HTML for each tier in the menu. So, effectively says:style all tiers in the menu this way...
    ul {z-index: 9888;}

    // Top tier 
    .Menu-Vertical ul.Menu {
        width: 163px;
    }

    // This rule effectively says:style all tiers EXCEPT THE TOP TIER in the menu this way... In other words, this rule can be used to style 
    // the second and third tiers of the menu without impacting  the topmost tier's appearance.
    ul.Menu ul {
        width: 163px;
        left: 0;
        top: 1.9em;
        z-index: 9888;
        background-color: @background-lighter;
        ul {
            width: 163px;
            left: 9em;
            z-index: 9889;
        }
    }

    // The menu adapter generates a list item (li) in HTML for each menu item. Use this rule create the common appearance of each menu item. 
    ul.Menu li {
        height: 1%; //IE6 for 7
        
        // Within each menu item is a link or a span, depending on whether or not the MenuItem has defined it's
        // NavigateUrl property. By setting a transparent background image here you can effectively layer two images
        // in each menu item.  One comes from the CSS rule (above) governing the li tag that each menu item has.
        // The second image comes from this rule (below).
        a, span
        {
            color: @color-white;
            padding: 3px 2px 5px 14px;
            display: block;
            position: relative;
            border-bottom: 0;
            height: 1%; //IE6 for 7
            background: transparent url(../../images/arrowRight_white.gif) left center no-repeat;
        }
    }


    // When a menu item contains no submenu items it is marked as a "leaf" and can be styled specially by this rule.
    ul.Menu li.Menu-Leaf a,
    ul.Menu li.Menu-Leaf span {
        background-image: none;
        cursor: pointer;
        display: block;
        position: relative;
    }

    // Not used presently.  This is here if you modify the menu adapter so it renders img tags, too.
    ul.Menu li a img {
        border-style: none;
        vertical-align: middle;
    }


    // When you hover over a menu item, this rule comes into play.
    // Browsers that do not support the CSS hover pseudo-class, use JavaScript to dynamically change the
    // menu item's li tag so it has the Menu-Hover class when the cursor is over that li tag.
    // See MenuAdapter.js (in the JavaScript folder).
    ul.Menu li:hover,
    ul.Menu li.Menu-Hover {
        background: #64443b;
    }


    ul.Menu li:hover a,
    ul.Menu li:hover span,
    ul.Menu li.Menu-Hover a,
    ul.Menu li.Menu-Hover span,
    ul.Menu li:hover li:hover a,
    ul.Menu li:hover li:hover span,
    ul.Menu li.Menu-Hover li.Menu-Hover a,
    ul.Menu li.Menu-Hover li.Menu-Hover span,
    ul.Menu li:hover li:hover ul a:hover,
    ul.Menu li:hover li:hover ul span.Asp-Menu-Hover,
    ul.Menu li.Menu-Hover li.Menu-Hover ul a:hover,
    ul.Menu li.Menu-Hover li.Menu-Hover ul span.Asp-Menu-Hover {
        color: @color-white;
        cursor: pointer;
    }


    ul.Menu li:hover ul a,
    ul.Menu li:hover ul span,
    ul.Menu li.Menu-Hover ul a,
    ul.Menu li.Menu-Hover ul span,
    ul.Menu li:hover li:hover ul a,
    ul.Menu li:hover li:hover ul span,
    ul.Menu li.Menu-Hover li.Menu-Hover ul a,
    ul.Menu li.Menu-Hover li.Menu-Hover ul span {
        color: @color-grey-dark;
        cursor: pointer;
    }

    // While you hover over a list item (li) you are also hovering over a link or span because
    // the link or span covers the interior of the li.  So you can set some hover-related styles
    // in the rule (above) for the li but set other hover-related styles in this (below) rule.
    ul.Menu li a:hover,
    ul.Menu li span.Asp-Menu-Hover {
        color: @color-white;
    }

    ul.Menu li.Menu-Leaf a:hover {
        background-image: none;
    }

}

/* Alternate used on Gift Registry */
table.giftdetails {
    background-color: @background-lighter;

    tr td {
        background-color: @background-lighter;
        padding: 2px 3px;
    }

    tr td.tdhdr {
        font-weight: 700;
        color: @color-grey-dark;
        background-color: @background-default;
    }

    tr td.tdnopad {
        padding: 0;
    }

    tr td.tdpad {
        //defaults
    }
    tr td.tdnopadalt {
        background-color: @background-default;
        padding: 0;
    }

    tr td.tdpadalt {
        //defaults
    }
}


div.eventaddress
{
    background-color: #57281e;
    margin: 0 0 10px 10px;
    > div {
        background-color: #e9f6ec;
    }
}


/* used in the description area of the gift registry template */
span.bday {
    font-size: 13px;
}

/* Temp setting for display */
// DVPDS - to be removed
//.GiftRegOff {
//    background-image: url(../../../images/btn_gift_registry.gif);
//    border: none;
//}

//.GiftRegOn {
//    background-image: url(../../../images/btn_gift_registry_shop.gif);
//    border: none;
//}

// DVPDS - added to position the gift registry button
.gift-registry-wrapper {
    margin:15px 0;
}


/* this sets the max width of the uploaded image in the gift registry */
.uploadedimage {
    //width: 100%; //DVPAL20140815 - removed to prevent image being stretched
    max-width: 620px;
}

/* END giftregistry.less */