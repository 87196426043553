@import "../variables.less";

/* START ratings.less */
// Rating Stars for Dynamic forms
//---------------------------------------------------

// DVPAL20150408 - rating stars on product template pages
.h1rating{
    display:inline-block;
}
div.ratingContainer{
    font-size: 12px;
    display:inline-block;
    @media (max-width:@screen-xs-max){
        width:100%;
    }
}

// DVPDS - Reworking of ratingstar for LESS
.ratingStar
{
    white-space: nowrap;
    margin: 4px 4px 4px 0;
    height: 14px;
    color: @ratingStarColor;

    .ratingItem
    {
        font-size: 0pt;
        width: 13px;
        height: 12px;
        margin: 0;
        padding: 0;
        display: block;
        background-repeat: no-repeat;
        cursor: pointer;
    }

    .Filled,
    .Empty,
    .Saved {Font-family: FontAwesome;}

    .Filled:before {
        content: "\f005";
        color: @ratingStarFilledColor;
    }

    .Empty:before {
        content: "\f006";
        color: @ratingStarEmptyColor;
    }

    .Saved:before {
        content: "\f005";
        color: @ratingStarSavedColor;
    }
}



// ****************** Gauge ******************
// DVPDS - Reworking of ratingGauge for LESS
.ratingGauge
{
    white-space: nowrap;
    font-size: 0pt;
    width: 122px;
    height: 12px;
    padding: 1px 0 1px 1px;
    margin: 1em;
    background-color: transparent;
    background-position: top left;
    background-repeat: no-repeat;
    background-image: url(../../images/rating/ratingGauge.png);

    .ratingItem {
        font-size: 0pt;
        width: 20px;
        height: 8px;
        margin: 0;
        padding: 0;
        display: block;
        background-repeat: repeat-x;
        cursor: e-resize;
    }

    .Filled {background-color: transparent;}
    .Empty {background-color: #ff0;}
    .Saved {background-color: #f00;}
}
   

// ****************** Thermometer ******************
// DVPDS - Reworking of ratingThermometer for LESS
.ratingThermometer
{
    white-space: nowrap;
    width: 220px;
    height: 0;
    padding: 26px 20px 20px 16px;
    margin: 1em;
    background-color: transparent;
    background-position: top left;
    background-repeat: no-repeat;
    background-image: url(../../images/rating/ratingThermometer2.png);

    .ratingItem {
        font-size: 0pt;
        width: 10px;
        height: 8px;
        margin: 0;
        padding: 0;
        display: block;
        background-repeat: repeat-x;
        cursor: e-resize;
    }

    .Filled {background-image: url(../../images/rating/ratingFilled.png);}
    .Empty {background-image: url(../../images/rating/ratingEmpty.png);}
    .Saved {background-image: url(../../images/rating/ratingSaved.png);}
}

/* END ratings.less */