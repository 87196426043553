@import "../../bootstrap/variables.less";
@import "../variables.less";

/* START favorites.less */
// Affects: myfavorites.ascx
//============================
// TODO: doesn't seem to be used
// DVPDS - no reference exists to this
//td.padded {
//    background-color:#B3B3B3; 
//    padding:3px 0 0 0;
//}

// Affects: FavoriteGroupList.ascx
//==================================
// DVPDS - no reference exists to this
//table.favorites {
//    padding: 2px;
//    width:105px;
//}

// The notice when an item has been added to a shopping cart
div.notice {
    text-align: center;

    span.red,
    a {
        font-size: @font-size-base;
        padding: 0 1px 0 0;
        margin: 0;
    }
}

// Affects: FavoriteGroupList.ascx
.favourite-group-list {
    margin-bottom: 5px;
    margin-top: 5px;
}

.alert-box {
    clear:both;
    width:100%;

    display: none;
    padding: 5px;
    margin: 0 0 5px 0;
    font-size: @font-size-base;
    float: left;
    background:@background-default;
    border:1px solid @themeColor;
    text-align:center;

    span.message {
        color: @themeColor;
    }

    a.page-link {
        font-weight:600;
    }
}

.itemqty .btn-addtofavorites {
    margin: 0 5px 5px;
}

div.btn-addtofavorites{
    //margin-top: 5px; DVPAL20140605 - removed to discontinue button-specific margins
    //margin-bottom: 5px;
    
    @media (max-width: @screen-sm-max)
    {
        width: 100%;
    }

    a.btn{
        @media (max-width: @screen-sm-max){
            width: 100%;
        }
    }

    select{
        width: 100%;
        background: @favListDropDown;
        top: auto;
        padding: 10px;
        color: @favListDropDownText;
    }

    select option.favlistcat{
        color: @color-grey-darker;
    }
}

div.childitems-fav .btn-addtofavorites{

    select{
        width:auto;
        left:auto;
    }
}

/*DVPEP20140529 - NTN7.2 - New style rules surrounding favorites - BEGIN*/

/* DVPAL20140608 - Parametric_Search_Items.ascx - custom chart favorite select size*/

.qtyTable .btn-addtofavorites select{
    left:auto;
    width:auto !important;


}

/*AddFavoriteGroup.ascx - containers and buttons*/
.new-fav-group{

    /*margin-left:45px;
    margin-right:45px;*/
    padding:5px;
    background-color:@background-lightest;
    .borderStyle-base;

    span{
        line-height:3;
    }

    @media (max-width:@screen-sm-max){
        div:first-of-type{
        text-align:center !important;
        }
        div:last-child
        {
            margin-top:10px;
        }
    }
    
}

.favorite-hdr{

    padding:5px;
    margin-bottom:5px;
    background-color:@background-lightest;
    .borderStyle-base;
    margin-left:0px;
    margin-right:0px;

    div:first-of-type {
        line-height:3;
    }
}

#favorites-nav{
    display: inline-block;
    background-color:@themeColor;
    border:1px solid @themeColor;
    width:100%;
    line-height:3;
    span{
        color:@color-white;

    }
    span a.others{
        color:@color-grey-light !important;
    }
}

.favorites-title{
    padding-left:0;
}

.favorites-container > div{
    display:inline-block !important;
}
/*DVPEP20140529 - NTN7.2 - New style rules surrounding favorites - END*/


/* END favorites.less */