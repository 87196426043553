@import "../bootstrap/variables.less";
@import "../bootstrap/buttons.less";
@import "../bootstrap/mixins.less";
@import "../base/forms.less";

@import "variables.less";

/* START buttons.less */
// Sets style on links that do not require special attributes
a.learnmore,
a.others {
    color: @link-color;
    text-decoration:none;
    .breakword;
    &:hover {
        color:@link-hover-color;
    }
}

.btn-icon {
    text-decoration: none !important;
}

.btn-alert {
    margin-top: 5px;
    padding: 4px 10px;
}



// The Store availability/location button
.btn-location-group {
    width:100%;
    margin-bottom: 5px;
    display: table;

    a.btn{
        margin-left:0 !important; //DVPAL20140605 - added to prevent offset
    }
    a.btn-avail-add{
        background:@background-default;
        color: @themeColor;
        width:20%; /*DVPAL20140610 - plus sign button, split 20/80% with store availability in the grouped button*/

        &:hover
            {
            background:@themeColor-light;
            color:@color-white;
        }
    }

    & > a {
        display: table-cell;
    }
    /*DVPEP20140528 - NTN7.2 - Adjust styles so store availability button will expand length of screen in smaller viewports. - BEGIN */
    @media (max-width:@screen-sm-max){
        width:100%;

        & > a.btn-avail-add{
            //width:10%;
        }
        & > a.btn-avail-small{
            //width:90%;
        }
    }
    /*DVPEP20140528 - NTN7.2 - Adjust styles so store availability button will expand length of screen in smaller viewports. - END */
}

// Product Page Buttons
// --------------------------------------------------
.btn-submit {

}

.btn-avail-big {
    .btn-block;
    padding: 7px 10px;
}

.btn-avail-small {
  display: inline-block !important;
  margin: 0;
  text-align: left;
  width:80%; // split 80/20% with plus sign in grouped button


}

.btn-avail-add {
    height: 100%;
    display: inline-block !important;
}

.btn-filter-remove {
    color: @color-red;
    text-decoration: none;

    &:hover {
        color: black;
        text-decoration: none;
    }
}

//DVPDS - added for the add to cart button (with the text added)
.btn-add-to-cart {
    padding: 6px 0px 6px 6px;
}


/* DVPAL20140528 - added for View Product Details button*/
.btn-view {
    .btn-item;
    margin-bottom:0;
    @media (max-width: @screen-sm-max){
        width:100%;
    }
}

.btn-predictor-right-arrow { margin-right: 10px; }
.btn-predictor-left-arrow { margin-left: 10px; }

.btn-predictor-left-arrow,
.btn-predictor-right-arrow {
    color: @color-black;
    font-size: 24px;
    line-height: 62px;
    display: block;
    height: 100%;
}

.btn-predictor-left-arrow:hover,
.btn-predictor-right-arrow:hover {
    color: @color-grey;
    text-decoration: none;
}

div.wishlist-groupbtn{
    padding-right:0px;
    @media (max-width: @screen-sm-max){
        padding:0px;
    }
}

//DVPAL20150529 - added to style add-to-cart buttons on multidim item templates - START

div.addtocart {
    font-size:@font-size-base;
}

//DVPAL20150529 - added to style add-to-cart buttons on multidim item templates - END


//DVPAL20150622 - my account/login button - START
#NavBar #NavButtons #acctpnlbtn.btn.acc-toggle{
    color:@background-default;
    padding:3px 10px;
    font-size:@font-size-base;
    line-height:1;
    margin-left:10px;
    border-radius:0;
    margin-top:15px;
    background:@themeColor;
    height:22px;
    i.fa{
        margin:0px;
        font-size:15px;
    }
    &:hover, &:focus{
        background:@themeColor-dark;
    }
    @media (max-width: @screen-sm-max){
        margin-top:10px;
    }

}

//DVPAL20150622 - my account/login button - END



/* END buttons.less */