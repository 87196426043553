﻿// CUSTOM.LESS
// -------------------------
// Theme: Nav-to-Net 7.X
// Author: Digital Vantage Point Inc.
// Description: The default base theme for Nav-to-Net 7.X
// Last Updated: In development

// The purpose of custom.less is to neatly list out all the main attributes for a theme's look and feel as well
// as keeping all the theme-specific classes separate from the base product.
// Attributes are either set to a particular parameter or points to one that is predefined.
// This makes it easier to change a theme's base color without having to touch anything else, for example,
// the themeColor attribute points to @color-blue, which is defined as a hex color code. To change the theme's
// base color, setting themeColor to another color is all it would take, since many of the CSS classes reference
// themeColor rather than the color itself. The most common attributes are outlined in custom.less, and attributes
// that tend not to be changed as often are in variables.less.

// Imports
// -------------------------
@import "./bootstrap/mixins.less";
@import "../Styles/base/variables.less";

// Theme - START --------------------------------------------------

// Colors
@themeColor:            @color-blue;
@themeColor-light:      @color-blue-light;
@themeColor-dark:       @color-blue-dark;

// < cosi-mgl
@logoColorRed: #ed1b24;
@logoColorBlack: #231f20;
@logoColorGray: #c6c7c9;

/*@themeColor: #c23239;*/ /*(Homepage)*/
/*@themeColor: #ed1b24;*/ /*(Logo)*/
/*@themeColor: #d52e2e;*/ /*(Old Shop)*/
/*@themeColor: #2e3842;*/
@themeColor: @logoColorBlack;
@themeColor-light: lighten(@themeColor, 5%);
@themeColor-dark: darken(@themeColor, 5%);
/*@themeColor-dark: @secondaryThemeColor;*/

@secondaryThemeColor: @logoColorRed;
@secondaryThemeColor-light: lighten(@secondaryThemeColor, 5%);
@secondaryThemeColor-dark: darken(@secondaryThemeColor, 5%);

@tertiaryThemeColor: @logoColorGray;

@navbarHoverColor: @themeColor-dark;
/*@btn-success-bg: @secondaryThemeColor-light;*/

@sectionTitleBG: @secondaryThemeColor;

/*@checkoutHeaderBackgroundActive: @secondaryThemeColor-light;*/
@checkoutHeaderBackgroundActive: @themeColor-light;

@input-border-focus: @tertiaryThemeColor;
// > cosi-mgl


// Footer
@footerlinkSectionBackground: #f4f7f8;
@footerSmallBackground: #efebdf; // small footer
@footerSmallColor: #cc3300;
@footerBackground: #565656;
@footerBackground2:darken(@footerBackground,5%);
@footerColor: @color-white;
@footerSectionBackground: #5a2c1c;
@footerSectionPadding: 0;
@footerMediumSize: 970px;

// Layout
// -------------------------
@layout-container-width: 1170px;


// Breadcrumb
// -------------------------
@breadcrumbLinkColor: @color-blue;
@breadcrumbBorderColor: #e3e3e3;

// Backgrounds
// -------------------------
// Define the site's various background colors here
@background-default: @color-white; //#fff
@background-light: @color-grey-light; //#ccc
@background-light2: @color-grey-light2; //#ddd
@background-lighter: @color-grey-lighter; //#eaeaea
@background-lightest: @color-grey-lightest; //#f5f5f5
@background-widget: @color-grey-lightest; //#f5f5f5
@background-grey: @color-grey; //#949494

// Navigation
// -------------------------
// Menu bar background colors that are predefined
/*@menubarlevel1Background:#428bca;
@menubarlevel2Background:#246ead;
@menubarlevel3Background:#175a94;
@menubarlevel4Background:#124b7d;
@menubarlevel5Background:#0d385f;*/

// Alternatively: menu bar background colors that are based off of theme color
@menubarlevel1Background:@themeColor;
@menubarlevel2Background:darken(@themeColor, 3%);
@menubarlevel3Background:darken(@themeColor, 6%);
@menubarlevel4Background:darken(@themeColor, 9%);
@menubarlevel5Background:darken(@themeColor, 12%);

@navbarBackgroundBaseColor: #d9e8f5;

@categoryTop: 0;
@categoryPosition: relative;
@categoryLeft:  5px;
@categoryWidth: 158px;
@categoryHeight: auto;
@topCategoryWidth: auto;
@menuBarGradientTop: #123579;
@menuBarGradientBottom: #0065c8;
@menuVerticalTop: 1px;
@categoryBackgroundBaseColor: #123579;
@categoryBackgroundHighlightColor: #0065c8;
@categoryBorders: 1px solid  #123579;

// Tabs
.gradientSelectedTab{
    .gradientLightBlue;
}

// Favorites
// -------------------------
@favListDropDown: #f8f8f8;
@favListDropDownText: #292929;

// Table rows
// -------------------------
@background-tablerow-header:@background-lighter;
@background-tablerow-alt: #EFEFEF;

// Multi-DIM
// -------------------------
@multidim-green: #beebc2;
@multidim-blank: #eaeaea;
@multidim-blue: #bed3eb;
@multidim-grey: #dcc2c2;

@multidim-box-green-focus:#ECFFED;
@multidim-box-green: #7eb17e;
@multidim-box-grey: #C59393;
@multidim-box-grey-focus: #FFEFEF;

@multidim-box-blue:#89B6EB;
@multidim-box-blue-focus:#E2EFFF;


// Buttons
//--------------------------
@button-default: @themeColor;
@button-default-border: 1px solid #357ebd;
@button-default-hover: #3276b1;
@button-default-border-hover:1px solid #285e8e;

@button-success: @color-green-light;
@button-success-hover: ##47a447;
@button-success-border: 1px solid #4cae4c;
@button-success-border-hover: 1px solid #398439;

@button-passive: @color-white;
@button-passive-text: @color-grey-dark;
@button-passive-border: 1px solid #dfdfdf;

// Featured Items
@featuredItemHighlightBackgroundColor: #eff2f5;
@featuredItemBorderColor:@color-grey-lighter;
@featuredItemBorderColor-hover:@themeColor;
@featuredItemBoxBorder: 1px solid #dfdfdf;

// Borders
// -------------------------
// Define the site's generic border colors
@default-border-color: @color-grey-light;
@default-border-color-light: @color-grey-lighter;
@default-border-color-lighter: @color-grey-lightest;
@default-border-color-dark: @color-grey;
@default-border-color-darker: @color-grey-dark;

// Left Category Widget
// -------------------------
@leftCategoryBodyBackground: @themeColor;
@leftCategoryBodyBorder: 1px solid @themeColor;
@leftCategoryBodyLinkColor: @color-white;
@leftCategoryBodyLinkHoverColor: @themeColor-dark;


// Mixins
// -------------------------
.borderStyle-base(@color: @default-border-color, @border-width: 1px, @radius: 0px, @border-style: solid){
    border-radius:@radius;
    border: @border-width @border-style @color;
}


// For homepage promo content
.hoverZoomImg(@scale: 1.15, @speed: 0.2s)
{
    .transition(all @speed ease-in-out);
    &:hover{
        -webkit-transform:scale(@scale); /* Safari and Chrome */
        -moz-transform:scale(@scale); /* Firefox */
        -ms-transform:scale(@scale); /* IE 9 */
        -o-transform:scale(@scale); /* Opera */
         transform:scale(@scale);
        .transition(all @speed ease-in-out);

    }
}

// Image not found - font icon sizes - TODO: move to its own LESS file to define sizes
// -------------------------

//Set default colors for no-img image icons
span.noimg{
    .fa-ban{
        color:@state-danger-text;
    }
    .fa-picture-o{
        color:@themeColor;
    }
}

//Recently Viewed
div.RecentlyViewedControl div.image-col span.fa-stack{
    font-size:12px;
}

//Featured Items & predictor upsell
div.Featured-Items-Rep div.Featured-Item span.fa-stack, div#predictor div.thumbnailcontainer span.fa-stack{
    font-size:27px;
}

//Highlight Catalog Items
div.FeatItemContainer div.FeatItemImage span.fa-stack{
    font-size:50px;
}

//Primary Image on item details page
div.primaryimage span.fa-stack{
    font-size:95px;
}

//Category Page
div.category-standard-image-col{
    span.fa-stack{
        font-size:90px;
    }
}

//Compare page
td.comparetdr{
    span.fa-stack{
        font-size:40px;
    }
}

//Whats hot
div.whatshotmenu{
    div.hotitem{
        div.hotitem-image{
            span.fa-stack{
                font-size:30px;
            }
        }
    }
}

//Category items
div.each-category-item{
    div.thumbnailImg{
        width:100%;
        height:auto;
        min-height:110px;
        span.fa-stack{
            font-size:50px;
        }
    }
}

//Subcategories
div.each-sub-category{
    div.sub-category-image{
        width:100%;
        height:auto;
        min-height:110px;
        span.fa-stack{
            font-size:50px;
        }
    }
}

//Tables
div.div-table-row div.image-wrapper span.fa-stack{
    font-size:15px;
    @media (max-width:@screen-sm-max){
        font-size:30px;
    }
}

//Carousel

div.carousel-inner div.item.active{
    &:focus{
        transition: all 200ms ease-in-out;
        border:5px solid @themeColor-dark;
    }
}

.carousel-control-panel{
    height:20px;
    font-size:15px;
    a{
        position:absolute;
        //DVPAL20150617 - slider automatically plays, so the play button is hidden at first and toggled through JS
        &#hero-play{
            display:none;
        }
    }
}

.carousel-control{
    .glyphicon-chevron-right,.glyphicon-chevron-left{
        @media (max-width:@screen-sm-max){
            font-size:25px;
        }
    }
}

//DVPAL20150609 - Custom spinning animations
.fa-spin-custom, .glyphicon-spin {
    -webkit-animation: fa-spin 1200ms infinite ease-in-out;
    animation: fa-spin 1200ms infinite ease-in-out;
}


.sr-only{
    color:@color-black;
    background:@color-white;
    position:absolute !important;
}

/* Fix display of autocomplete list */
.ui-menu .ui-menu-item .ui-state-focus,
.ui-menu .ui-menu-item .ui-state-active {
    /*font-weight: normal;*/
    margin: -1px;
}



/**:focus{
    outline:2px solid @themeColor !important;
}*/


// THEME - END --------------------------------------------------

// THEME-SPECIFIC CSS CLASSES - START


// THEME-SPECIFIC CSS CLASSES - END


// < cosi-mgl

@navBarBackgroundColor: @secondaryThemeColor;
@navBarBackgroundColorOnHover: @themeColor;

#Header,
div#Navigation,
div#TopCategory {
    background-color: @navBarBackgroundColor;
}

@media (min-width: 768px) {
    //div#category a.categorytext,
    #Header #Navigation #category a.categorytext {
        /*background-color: @secondaryThemeColor-light;*/
        background-color: @navBarBackgroundColor;

        &:hover {
            /*background: @secondaryThemeColor-dark;*/
            /*background: @themeColor-dark;*/
            background-color: @navBarBackgroundColorOnHover;
            text-decoration: none;
        }
    }
}

.navmenu ul.Menu li .menu-chevron a:focus,
.navmenu ul.Menu li .menu-chevron span:focus {
    background-color: unset;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
    color: #fff;
    background-color: @themeColor-light;
    /*border-color: #204d74;*/
}

a.grid {
    color: @text-black;
}

a.breadcrumblink {
    color: @text-black;
}

li.searchmodule #searchNavBar #SimpleSearch div.input-group input.simplesearch:focus {
    background-color: mix(@tertiaryThemeColor, #fff, 50%);
}


/* Responsive images */

.subcategory-list-container .each-sub-category .sub-category-image,
.categoryItemContainer .each-category-item .each-category-item-Image,
.FeatItemContainer .FeatItem .FeatItemImage {
    a .img-container img {
        width: initial !important;
        height: initial !important;
    }

    .responsive-container {
        height: 110px;
        min-width: 110px;
    }
}

div.Featured-Items-Rep div.Featured-Item span.fa-stack,
div#predictor div.thumbnailcontainer span.fa-stack {
    font-size: 56px;
}


.responsive-container {
    position: relative;
    width: 100%;
}

.dummy {
    padding-top: 100%; /* forces 1:1 aspect ratio */
}

.img-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center; /* Align center inline elements */
    font: 0/0 a;
}

.img-container .centerer {
    display: inline-block;
    vertical-align: middle;
    height: 100%;
}

.img-container img {
    vertical-align: middle;
    display: inline-block;
    max-height: 100%; /* <-- Set maximum height to 100% of its parent */
    max-width: 100%; /* <-- Set maximum width to 100% of its parent */
}

// > cosi-mgl
