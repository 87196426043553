@import "../variables.less";

/* START topsection.less */
// DVPDS - no longer used (referenced only in topsection.ascx, which is no longer used)
// div#LogoSection {
//	position: absolute;
//    left: 7px;
//    top: 10px;
//	width: auto;
//}

div#topCartSum {
    position:absolute; 
    right: @topCartSumRight; 
    top: @topCartSumTop;

    a, span {
        color: @topCartSumColor;
    }
}

// DVPDS - no longer used
//div.sitelinks {
//    position:absolute; 
//    right: @siteLinksRightPosition; 
//    top: @siteLinksTopPosition;
//}

div.sitelink
{
    float: left;
    vertical-align: middle;
    padding: 2px 5px 0 5px;
}

// DVPDS - no longer used
//div.sitediv
//{
//    float: left;
//    vertical-align: middle;
//    padding: 1px 5px 0 5px;
//}

// DVPDS - no longer used (referenced only in topsection.ascx, which is no longer used)
//div#flags
//{
//    position: absolute;
//   right: 5px;
//    top: 6px;
//    display: none;
//}

// DVPDS - no longer used (referenced only in topsection.ascx, which is no longer used)
//a.navtop {
//    font-weight:700;
//    color:@topNavLinkColor;
//    text-decoration:none;

//    &:hover {
//        color: @topNavLinkColorHover; 
//        text-decoration:none;
//    }
//}

/* END topsection.less */