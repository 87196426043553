@import "../../bootstrap/variables.less";
@import "../variables.less";


/* START rma.less */
// Used for "Check ALL" option in RMA request page
div#rmacheck {
    background-color:@background-lightest; // DVPDS - moved from control (used to be an inline style)
    .borderStyle-base;
	width:130px;
    text-align:center;
    padding:3px;
    @media (max-width: @screen-sm-max){
        width:100%;
    }

    input[type="checkbox"]{
        margin:0;
        vertical-align:middle;
    }
}

/* DVPAL20140702 - RMA request - START*/

div.div-table-col.desc{
    width:35%;
}

div.div-table-col.oqty,div.div-table-col.rqty{
    width:15%;
}

div.div-table-col.comments{
    width:25%;
}

@media (max-width:@screen-sm-max){
    div.div-table-col.comments,div.div-table-col.rqty,div.div-table-col.oqty,div.div-table-col.desc{
        width:100%;
    }
}

/* DVPAL20140702 - RMA request - END*/
/* END rma.less */