@import "../../bootstrap/variables.less";
@import "../variables.less";

/* START paging.less */
// ### Start Paging control styles ### */
// Sets the style on "numbers" when the number reflects the page you are on
input.pageon {
	font-weight:700;
	background-color:@background-lightest;
	color:@color-grey-darker;
	.borderStyle-base;
	cursor:pointer;
	padding:0 1px 0 1px;
	margin:0;
}

// Sets the style on "numbers" when the number does not reflect the page you are on
input.pageoff {
	background-color:@background-default;
	border:1px solid @color-white;
	cursor:pointer;
	padding:0 1px 0 1px;
	margin:0;
}
// ### End Paging control styles ###
.pagingctrlparent
{
    float: right !important;
    display: inline-block; // DVPJL20140516 - NavToNet7 - Adjust to fit parametric_search_item.ascx
    margin-top: 6px; // DVPJL20140516 - NavToNet7 - Adjust to fit parametric_search_item.ascx

    @media (max-width: @screen-sm-max) 
    {
        float:right !important; // DVPAL20140528 - NTN7 - float to right in XS and S viewport
        display: block; // DVPJL20140516 - NavToNet7 - Adjust to fit parametric_search_item.ascx
    }

    .firstlbl
    {
        display: inline-block;
    }

    .firstbutton
    {
        display: inline-block;
    }

    .previousbutton
    {
        display: inline-block;
    }

    .numberbuttonsparent
    {
        display: inline-block;

        .numberbuttonstext
        {
            display: inline-block;
        }

        .numberbuttons
        {
            display: inline-block;
        }
    }

    .numberbuttonstext
    {
        display: inline-block;
    }

    .numberbuttons
    {
        display: inline-block;
    }

    .nextbutton
    {
    }

    .lastbutton
    {
    }
}

/* used for the paging control (webpagesize.ascx) */

.page-size {

    div {
        float:left;
        line-height:32px;
        margin:0 5px 0 0;
    }
}

/* DVPAL20140528 - sort control*/
div.sortcontrolparent{
    float:left; //float set to left so it appears on the same row as paging control

    .sort-label{
        line-height:3;
        padding-left:0;
        padding-right:0;
        float:left;
        margin-right:5px;
    }
    .sort-control-container{
        float:left;
    }
}


/* END paging.less */