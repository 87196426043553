@import "../bootstrap/variables.less";
@import "variables.less";

/* START tables.less */
// defines border color of dhtml section of the product details template
.dhtmbdr {
    background-color:#999;
    font-size: @font-size-base;
    border:1px solid #dddddd;
    border-width:0 1px 1px 1px;

    p {
        font-size: @font-size-base !important;
    }
    table {
        //background-color: #fff;
    }
}

// sets style on tables that display various fields i.e. User account info
// column colours determined by td.dleft and td.dright
table.details {
    border:1px solid @breadcrumbBorderColor; 
    background-color:@background-default;
}


//DVPAL20140618 - quote details page - START (this section might need to be moved to a more appropriate less file)

div.totalcontainer{
    text-align:right;
    margin-bottom:10px;
    width:100%;

    .totalrow.last{
        border-top:1px solid @default-border-color;
    }

    .totalrow{
        .totallabel{
            width:auto;

               
            }
        
        .totalnumber{
            min-width:10%;

             @media (max-width: @screen-xs-max){
                  min-width:85px;
                }
        }
        .totalnumber,.totallabel{
            display:inline-block;
        }
    }
}

div.radio.inline.discount{
    margin-right:30px;
    @media (max-width: @screen-sm-max){
        margin-right:15px;
    }
}

div.quote-details-discount{
    margin:10px 0 10px 0;
    width:100%;
}

// DVPAL20140618 - added for responsive behaviour on button
 div.radio.inline.discount {
         @media (max-width: @screen-sm-max) {
        display:inline-block;
    }
 }

div.quote-details-form .btn-toolbar .btn, div.radio.inline.updatebtn .btn{
    @media (max-width: @screen-sm-max) {
        width:100%;
        margin-left: 0; 
        margin-top: 5px;
    }
}

div.radio.inline.updatebtn .btn{
        @media (max-width: @screen-sm-max) {
        width: 100%; 
    }
}

div.quote-details-form div.radio.inline {
    @media (max-width: @screen-sm-max) {
        padding:0;
    }
}

//DVPAL20140618 - quote details customer info
.quotedetails{
    width:45%;
    float:left;
    margin:10px;
    @media (max-width: @screen-sm-max){
        margin:0px;
        width:100%;
    }

    .quote-row{
        margin-bottom:10px;
    }

    .icon-calendar
    {
        border-radius:0px 4px 4px 0px;
    }
}

.quoteDetailsCustomerInfo{
    @media (max-width:@screen-sm-max){
        display:inline-block;
        width:100%;
    }
}

.div-table.shopping-cart .div-table-row input[type="text"]{
    @media (max-width:@screen-sm-max){
        width:100% !important;
    }
}
// discount radio buttons
.quote-details-discount .radio.inline input[type="radio"]{
    @media (max-width:@screen-sm-max){
        float:none;
        vertical-align:middle;
        margin:0px;
        padding:0;

    }
}
// discount text field
.quote-details-discount .radio.inline input[type="text"]{
    @media (max-width:@screen-sm-max){
     display:inline-block;
    }
}

// quote details page END

/*DVPEP20140502 - NTN7.2 - New responsive style rules to handle response tables. For smaller viewports
                      the table structure will change and display labels and values in a vertical view opposed to horizontal. - BEGIN */
.dvp-table-responsive
{
    tbody tr td{
        padding-left:3px;
    }
    .edit-column{
        padding:0;
        text-align:center;
        
        a.btn{
            @media (max-width: @screen-sm-max){
                width:100%;
            }
        }
    }
    td{
        vertical-align:middle !important;
    }
    th{
        font-weight:bold;
    }
}

table.dvp-table-responsive.variant-list
{
    thead tr{
        background:@background-tablerow-header;
    }

    tbody:nth-child(odd){
        background:@background-tablerow-alt;
    }
    .col-variant-code
    {
        width: 35%;
    }

}

@media (max-width:@screen-sm-max)
{
    .dvp-table-responsive
    {
        width: 100%;
        border-style:none !important;

        /* Force table to not be like tables anymore */
        table, thead, tbody, th, td, tr
        {
            display: block;
        }
            /* Hide table headers (but not display: none;, for accessibility) */
            thead tr
            {
                position: absolute;
                top: -9999px;
                left: -9999px;
            }

        tr
        {
            margin-top:-3px; //DVPAL20140620 - added to place boxes right under each other in 
            //border: 1px solid @default-border-color;// DVPAL20140818 - remove border in XS/S viewports
            
            td{
                text-align:left !important;
            }
        }
  
        /*Change background color for odd rows*/
        tr:nth-child(odd) { 
            background-color:@background-lightest; 
            @media (max-width: @screen-sm-max){
                background:none;
            }
        } 
        
        /*class spanned-row will have a different style versus an unspanned row.*/
        tr.spanned-row{
            border-style:none !important;
            td{
                padding-left:6px !important;
            }
        }
        
        td
        {
            /* Behave  like a "row" */
            border: none !important;
            position: relative !important;
            /*Important to no combine padding in short form syntax - BEGIN */
            padding-left: 50% !important;
            padding-top:3px !important;
            padding-bottom:3px !important;
            padding-right:6px !important;
            /*Important to no combine padding in short form syntax - BEGIN */
            width:auto !important;
        }

        td:before
        {
            /* Now like a table header */
            position: absolute;
            /* Top/left values mimic padding */
            top: 5px;
            left: 6px;
            width: 45%;
            padding-right: 10px;
            white-space: nowrap;
            content: attr(data-title);
            font-weight:bold;
        }
      
    }
    
}
/*DVPEP20140502 - NTN7.2 - New responsive style rules to handle response tables. For smaller viewports
                         the table structure will change and display labels and values in a vertical view opposed to horizontal. - END */

/*DVPEP20140509 - NTN7.2 - New response class structure for div tables. A table can be changed into a div using the below class rules. - BEGIN */
.div-table
{
    display:table;
    border-collapse:collapse;
    width:100%;
    //border:@tableBorder; //DVPAL20140818 - removed table borders
    label{
        margin-bottom:0;
    }
    .cartNameRowContainer{
        font-size:0;
        a, span.shortdescr{
            font-weight:600;
            font-size:@font-size-base;
        }
    }
}

/* DVPAL20140612 - remove border in the last child to prevent double borders at the bottom*/
.div-table .div-table-row:last-child{
    border-bottom:none !important;
}

.div-table-col
{
    display: table-cell;
    padding:@tablePadding; //DVPDS - changed to use variable
    //border-right:@tableBorder; //DVPDS replaced line below with this
    vertical-align: middle;
}

.div-table-row
{
    overflow:auto; //DVPAL20140617 - added to prevent contained images from clipping borders
    display:table-row;

    padding:3px;
}

.row-header
{
    display:table-header-group !important;
    font-weight:600;
    border:@tableBorder;
    background-color:@tableHeadingBackgroundColor !important; //DVPDS - changed color to something more in line with current scheme (#07435D)
    color:@tableHeadingTextColor; //DVPDS - changed to use variable (used to be #fff)
    //position:relative; //DVPAL20140611 - removed to prevent border clipping in Firefox
    height:auto !important;
}

.div-header-col
{
    display:table-cell;
}

.div-table:after{

    clear:both;
}
 
div.div-table.master-child{
    .col-item{
        width:45%;
    }
    .col-header-item{
        width:45%;
    }
    .col-price{

    }
    .col-uom{
        width:15%;
    }
    .col-qty{

    }
    .col-image
    {   
    background-color:@background-default;
    width:35px;
    height:35px;
    .image-wrapper{

        overflow:hidden; /*DVPEP20140605 - NTN7.2 - Firefox alt text overflows outside the image container. Hide overflow.*/
        width:35px;
        height:35px;

            .thumb{
                display:inline-block;
                overflow:hidden;
            }
            .main{
                display:none;
            }
        }
    }
    .col-header-image{
         //border-right-width: 0px !important; //DVPAL20140616 - removed to put border for image columns
         border-right:1px solid @default-border-color;
    }
}

div.div-table.parametric, div.div-table.master-child{
    .row-header{ //DVPAL20140609 - row header is hidden in SM and XS viewport
        @media (max-width:@screen-sm-max){
            display:none !important;
        }
    }
    .col-item{
        width:45%;
    }
    .col-header-item{
        width:45%;
    }
    .col-price{

    }
    .col-uom{
        width:15%;
    }
    .col-qty{

    }
    .col-image
    {   
    //border-right-width: 0px !important; //DVPAL20140616 - removed to put border for image columns
    //border-right:1px solid@default-border-color;
    width:35px;
    height:35px;
    background-color:@background-default;
    .image-wrapper{

        overflow:hidden; /*DVPEP20140605 - NTN7.2 - Firefox alt text overflows outside the image container. Hide overflow.*/
        width:35px;
        height:35px;

            .thumb{
                display:inline-block;
                overflow:hidden;
            }
            .main{
                display:none;
            }
        }
    }
    .col-header-image{
         //border-right-width: 0px !important;//DVPAL20140616 - removed to put border for image columns
                  //border-right:1px solid @default-border-color;
    }

    .btn-addtofavorites a, .webAvailTable a, .priceTable a { /*DVPAL20140609 - parametric/master-child search table-specific styling for some buttons*/
        color:@tableHeadingTextColor;
        border:@featuredItemBoxBorder;
        margin-top:2px;
        margin-bottom:0;
        background:@background-default;

       @media (max-width:@screen-sm-max){
           margin-top:5px;
           background:@default-border-color-light;
           color:@color-grey-darker;
           border:1px solid @default-border-color;
       }

        &:hover{
            color:@color-white;
            background:@button-default;
            border:@button-default-border;
        }
    }
}

div.div-table.shopping-cart
{
    #uomwrapper{
        width:60%; //DVPAL20140617 - set width in shopping cart table, split 40-60 with quantity field
        display:inline-block;
        span{
            text-align: left;
            line-height: 34px;
            display: block;
            padding-left: 18px;
            @media (max-width: @screen-sm-max){
                display:inline-block;
                padding:0;
                line-height:1;

    }
        }
    }
     .catalog-container, .variant-container, .productid-container{
        display:inline-block;
        width:50%;
        font-size:@font-size-small; 
     }

    .col-item{
        width:40%; //DVPAL20140617 - changed to 45% to allow for more room in the name field
    }
    .col-item-qty{
        width:20%;
    }
    .qtywrapper{
        float:left;
        margin-right:-1px; //DVPAL20140617 - added to overlap 1px with UOM field in shopping cart
        width:40%;
    }

}

div.div-table.cat-item-list{

    .col-image
    {   
    background-color:#fff;
    width:35px;
    height:35px;
    .image-wrapper{

        overflow:hidden; /*DVPEP20140605 - NTN7.2 - Firefox alt text overflows outside the image container. Hide overflow.*/
        width:35px;
        height:35px;

            .thumb{
                display:inline-block;
                overflow:hidden;
            }
            .main{
                display:none;
            }
        }
    }
    .col-header-image{

    }

    .col-price
    {
        width: 10%;
    }

    .col-item-no
    {
        width:10%;
    }

    .col-item-uom{
        width:15%;
    }

    .col-item-qty{
        width:20%;
    }

}

div.div-table.favorites{

    .col-image
    {   
    background-color:#fff;
    width:35px;
    height:35px;
    .image-wrapper{

        overflow:hidden; /*DVPEP20140605 - NTN7.2 - Firefox alt text overflows outside the image container. Hide overflow.*/
        width:35px;
        height:35px;

            .thumb{
                display:inline-block;
                overflow:hidden;
            }
            .main{
                display:none;
            }
        }
    }
    .col-header-image{

    }

    .col-price
    {
        width: 10%;
    }

    .col-item-no
    {
        width:10%;
    }

    .col-item-uom{
        width:15%;
    }

    .col-item-qty{
        width:20%;
    }

}

div.div-table.advanced-search
{
    .col-image
    {
        background-color:#fff;
        width: 35px;
        height: 35px;

        .image-wrapper
        {
            overflow:hidden; /*DVPEP20140605 - NTN7.2 - Firefox alt text overflows outside the image container. Hide overflow.*/
            width: 35px;
            height: 35px;

            .thumb
            {
                display: inline-block;
                overflow: hidden;
            }

            .main
            {
                display: none;
            }
        }
    }

    .col-price
    {
        width: 15%;
        @media (max-width: @screen-sm-max){
            width:100%;
        }
    }

    .col-item-no
    {
        width:10%;
         @media (max-width:@screen-sm-max){
            width:auto; /*DVPAL20140529 - width set to auto in SM viewport*/
        }
    }

    .col-item-uom{
        width:15%;

        @media (max-width:@screen-sm-max){
            width:auto; /*DVPAL20140529 - width set to auto in SM viewport*/
        }
    }

    .col-item-qty{
        width:25%;

        @media (max-width:@screen-sm-max){
            width:auto; /*DVPAL20140529 - width set to auto in SM viewport*/
        }
    }



}

/*Change background color for odd rows*/
.div-table-row:nth-child(odd) { background-color:@background-tablerow-alt; }

div.cfg-cart-row{
    background-color:@background-default !important;
}

div.cfg-cart-row-alt{
    background-color:@background-tablerow-alt !important;

}

@media (max-width:@screen-sm-max)
{
    .div-table-row:nth-child(even) { 
        background-color:@background-default; 

    } // DVPAL20140528 - removed gray colour on alternate table rows in smaller viewports

      .row-header
    {
        position:absolute;
        top:-2000px;
    }
    
    .div-table{
        display:block;
    }

    .div-table-col
    {
        display:block;  
        overflow:hidden; 
        vertical-align:baseline;  
        border-right-width:0px;
    }
     
    .div-table-row
    {
        display:block;
        width:auto;
        margin-bottom:20px;
        /*overflow:hidden;*/
    }

    .div-table-row:after{
        clear:both;
    }

    .col-text
    {
        text-align:left;
    }

    /* DVPAL20140528 - selecting compare checkboxes*/
    div.col-text[data-title=Compare]
    {
        float:right;
    }

    .col-text:before
    {
        content: attr(data-title);
        font-weight:bold;
        white-space:nowrap;
        vertical-align:top;
    }

    #uomwrapper{
        width:100%; //DVPAL20140528 - S and XS viewport, field spans across container
        display:inline-block;

        select.form-control{
            width:100%;
        }
    }
    div.div-table.master-child
    {
        .col-item{
            width:auto;
        }
        .col-header-item{
            width:auto;
        }
        .col-image
        {
            float: left !important;
            width: auto;
            height: auto;

            .image-wrapper
            {
                width: 110px;
                height: 110px;

                .thumb
                {
                    display: none;
                }

                .main
                {
                    display: inline-block;
                }
            }
        }
        .col-image:after{
        clear:left;
        }
    }

     div.div-table.parametric
    {
        .col-item{
            width:auto;

            span.col-inner-label{
                display:none;
            }

            #spnShortDescription{
                font-size:16px;
                font-weight:bold;
            }
        }
        .col-header-item{
            width:auto;
        }
        .col-uom{
            width:auto;
        }
        .col-image
        {
            float: left !important;
            width: auto;
            height: auto;

            .image-wrapper
            {
                width: 110px;
                height: 110px;

                .thumb
                {
                    display: none;
                }

                .main
                {
                    display: inline-block;
                }
            }
        }
        .col-image:after{
        clear:left;
        }
    }

     div.div-table.cat-item-list
    {

        .col-price
        {
            width: auto;
        }

        .col-item-no
        {
            width:auto;
        }

        .col-item-uom{
            width:auto;
        }

        .col-item-qty{
            width:auto;
        }

        .col-image
        {
            float: left !important;
            width: auto;
            height: auto;

            .image-wrapper
            {
                width: 110px;
                height: 110px;

                .thumb
                {
                    display: none;
                }

                .main
                {
                    display: inline-block;
                }
            }
        }
        .col-image:after{
        clear:left;
        }
    }

    div.div-table.favorites
    {

        .col-price
        {
            width: auto;
        }

        .col-item-no
        {
            width:auto;
        }

        .col-item-uom{
            width:auto;
        }

        .col-item-qty{
            width:auto;
        }

        .col-image
        {
            float: left !important;
            width: auto;
            height: auto;

            .image-wrapper
            {
                width: 110px;
                height: 110px;

                .thumb
                {
                    display: none;
                }

                .main
                {
                    display: inline-block;
                }
            }
        }
        .col-image:after{
        clear:left;
        }
    }

    div.div-table.advanced-search
    {
        .col-item{
            width:auto;
        }
        .col-header-item{
            width:auto;
        }
        .col-image
        {
            float: left !important;
            width: auto;
            height: auto;

            .image-wrapper
            {
                width: 110px;
                height: 110px;

                .thumb
                {
                    display: none;
                }

                .main
                {
                    display: inline-block;
                }
            }
        }
        .col-image:after{
        clear:left;
        }
    }

    div.div-table.shopping-cart
    {
        #uomwrapper
        {
            display: block;
            width: 100%;
        }

            #uomwrapper:before
            {
                content: attr(data-title);
                font-weight: bold;
                white-space: nowrap;
                vertical-align: top;
            }

        #productid-container
        {
            display: block;
            width: 100%;
            padding: 3px 0px 0px 0px;
            border-right: 0 !important;
        }

        #variant-container
        {
            display: block;
            padding: 3px 0px 0px 0px;
            border-right: 0 !important;
            width: 100%;
        }

        #catalog-container
        {
            display: block;
            padding: 3px 0px 0px 0px;
            border-right: 0 !important;
        }

        .col-item
        {
            width: auto;

            #spnShortDescription
            {
                font-size: 16px;
                font-weight: bold;
            }

            a.others
            {
                font-size: 16px !important;
                font-weight: bold !important;
            }
        }

        .col-item > div
        {
            display: inline; /*DVPEP20140605 - NTN7.2 - Changed from inline-block to inline.*/
        }

        .col-item-qty
        {
            width: auto;
            vertical-align: middle !important;
        }

        .qtywrapper
        {
            /*float:none;*/
            width: 100%;
            display: block;
        }

        #qty-col-container
        {
            display: block;
            width: 100%;
        }
    }
    
}

//DVPAL20140617 - added to remove border radius and counter offset in select forms under quantity in shopping cart
#qty-col-container{
    input.form-control, select.form-control{
        margin-left:1px; 
        border-radius:0px !important;
    }
}


/*DVPEP20140509 - NTN7.2 - New response class structure for div tables. A table can be changed into a div using the below class rules. - END */

/* for the related files on the product details page */
.related-files {
    width:100%;
    tbody tr td {
        width:25%;
        padding:3px;
        background:#f4f4f4;
        a {
            cursor:default;
        }
    }
    tbody tr td:nth-child(2){
        text-align:right; //DVPAL20140818 - align view file button to right
    }
    tbody tr td:nth-child(1) {
        width:75%;
    }
}

/*DVPAL20140624 - savedcarts.aspx, saved_cart.xsl - START*/

/* used for the add to saved cart control */
.AddToSavedCart {
    div {
        float:left;
        line-height:32px;
        margin:0 5px 15px 0;
        
        @media (max-width:@screen-sm-max){
            width:100%;
            margin:0 0 5px 0;
            line-height:2;
        }
        a.btn{
            @media (max-width:@screen-sm-max){
                width:100%;
                margin:0 0 5px 0;
            }
        }
    }
}

.sc-span-container{
    width:100%;
    display:inline-block;
    margin-bottom:10px;
    padding:2px;
}

.detailssc2{
    -webkit-transition: all 0.15s ease-in-out;  /* Chrome 1-25, Safari 3.2+ */
    -moz-transition: all 0.15s ease-in-out;  /* Firefox 4-15 */
    -o-transition: all 0.15s ease-in-out;  /* Opera 10.50�12.00 */
    transition: all 0.15s ease-in-out;  /* Chrome 26, Firefox 16+, IE 10+, Opera 12.50+ */
}

.sc-header{
    display:inline-block;
    @media (max-width:@screen-sm-max){
        width:100% !important;
    }
}
.sc-row-header{
    width:100%;
    display:inline-block;

    .scshopper,.scname{
        width:25%;
    }
    .sctotal,.scprice{
        width:20%;
    }
}

.sc-info-row{
    border-right:1px solid @default-border-color;
    border-left:1px solid @default-border-color;
    border-bottom:1px solid @default-border-color;
    width:100%;
    display:none;
    position:relative;
        .inner-btn-group
        {
            padding:10px;
            text-align:center;
            a.btn{
                @media (max-width:@screen-sm-max){
                    width:100%;
                    margin-top:5px;
                }
            }
        }
    }

.sc-col:last-child, .sc-label:last-child{
    border-right-width: 0 !important;
}


.sc-col.col-text:before{
    display:none;
}

@media (max-width: @screen-sm-max){ // DVPAL20140625 - added to have variable labels show up in SM and XS viewport
    .sc-col.col-text:before
    {
        content: attr(data-title);
        font-weight:bold;
        white-space:nowrap;
        vertical-align:top;
        width:20%;
        min-width:80px;
        display:inline-block;
    }
}

div.sc-inner-table{
    width:100%;
    .sc-label-container{
        background:@background-default;
        border-bottom: 1px solid @default-border-color;
        font-size:0; //DVPAL20140624 - added to prevent unwanted white space between divs

        @media (max-width: @screen-sm-max){
            display:none !important;
        }

        .sc-label, .sc-col{
            font-size:12px;
            display: inline-block;
            text-align: left;
            padding-left: 10px;
            width: 15%;
            border-right:1px solid @default-border-color;
            @media (max-width: @screen-sm-max){
                padding-top: 2px;
                padding-bottom: 2px;
                border-right:none !important;
                width:100% !important;
            }
        }
            .itemname{
                width:45%;
            }
            .itemno{
                width:15%;
            }
             .itemuom{
                 width:13%
             }
            .itemprice{
                width:10%;
            }
            .itemvariant{
                width:8%;
            }
            .itemqty{
                width:5%;
            }


    }
    .sc-inner-header{
        border-bottom:1px solid @default-border-color;
        background-color:@background-default;
        width:100%;
        display:inline-block;
        .sc-col{
            display: inline-block;
            text-align: left;
            padding-left: 10px;
            width: 15%;
            border-right:1px solid @default-border-color;
            @media (max-width: @screen-sm-max){
                padding-top: 2px;
                padding-bottom: 2px;
                border-right:none !important;
                width:100% !important;
            }
        }
        .itemname{
            width:45%;
        }
        .itemno{
            width:15%;
        }
        .itemuom{
            width:13%
        }
        .itemprice{
            width:10%;
        }
        .itemvariant{
            width:8%;
        }
        .itemqty{
            width:5%;
        }

    }
    .sc-inner-header:nth-child(even){ // DVPAL20140625 - added for alternating background colours for product entries
        background:@default-border-color-lighter;
    }
}
/*DVPAL20140624 - savedcarts.aspx, saved_cart.xsl - END*/


/*DVPEP20140623 - NTN7.2 - Multi dim variant table. - BEGIN*/
/*tr.row-top-axis td.multi-dim-data-col{
    display:table-cell;
}

tr.multi-dim-data-row td.multi-dim-data-col{
    display:table-cell;
}*/

.multi-dim-data-col{
    position:relative;
    .multiDimPriceLbl{
        font-size:@font-size-base;
    }
    .stockindicator{
        font-size:14px;
        position:absolute;
        top:3px;
        left:3px;
        .sBlank, .sGreen, .sRed, .sBlue{
            display:none;
        }
    }
}

@media (max-width:@screen-sm-max){

    tr.row-top-axis td.multi-dim-data-col{
        display:none;
    }

    tr.multi-dim-data-row td.multi-dim-data-col{
        display:none;
    }

    tr.row-top-axis td:nth-of-type(2)
    {
        display:table-cell;
    }
    tr.multi-dim-data-row td:nth-of-type(2)
    {
        display: table-cell;
    }


    .hide-td{
        display:none !important;
    }
        .show-td{
    display:table-cell !important;
    }
}

@media (min-width:@screen-md-min)
{
    tr.multi-dim-data-row td.multi-dim-data-col{
    display:table-cell !important;
    }

    .hide-td{
        display:table-cell !important;
    }
        .show-td{
        display:table-cell !important;
    }
}
/*DVPEP20140623 - NTN7.2 - Multi dim variant table. - END*/

/*DVPAL20140626 - NTN7.2 - Multi dim parameter container - START*/
div.multidim-param {
        width:100%;
    .md-locationbtn-container,.md-btn-container, .md-topaxis, .md-uom-container {
        width: 200px;
        display: inline-block;
        vertical-align: bottom;
        margin: 0 10px 0 0;

        a.btn-item{
            margin-bottom:0;
        }

        @media (max-width:@screen-sm-max){
            width:100%;
            margin:5px 0 0 0;
        }
         
    .btn-location-group{
        margin-bottom:0;
        }
    }
}
/*DVPAL20140626 - NTN7.2 - Multi dim parameter container - END*/

/*DVPAL20140627 - NTN7.2 - Multi dim variant table (styles) - START*/
.multi-dim-variant-table{
    table-layout:fixed;
    border:1px solid @default-border-color;
    .cItemCnt, .cItem{
        text-align:center;
        border: 1px solid @default-border-color;

        @media (max-width: @screen-sm-max){
            width:35%;
        }
    }
    input.small-squared{
    -webkit-transition: all 0.20s ease-in-out;
    -moz-transition: all 0.20s ease-in-out;
    -ms-transition: all 0.20s ease-in-out;
    -o-transition: all 0.20s ease-in-out;
    transition: all 0.20s ease-in-out;
    outline:none;
    }

    tr.multi-dim-data-row{
        @media (max-width: @screen-sm-max){
            height:40px;
        }
    }
}

td.avgreen, td.avblue, td.avgray, td.avblank{
    text-align:center;
    padding: 1px 3px 1px 3px;
    font-size:10px;
    color:@color-grey-darker;
}
td.avgreen{
    .stockindicator{
        .sGreen{
            color:@state-success-text;
            display:block;
        }
    }
    background:@multidim-green;
    input.small-squared{
        border:1px solid @multidim-box-green;
        &:focus{
            background:@multidim-box-green-focus;
            box-shadow: 0 0 5px @multidim-box-green;
        }
    }
}
td.avblue{
    .stockindicator{
        .sBlue{
            color: darken(@multidim-box-blue,20%);
            display:block;
        }
    }
    background:@multidim-blue;
    input.small-squared{
        border:1px solid @multidim-box-blue;
        &:focus{
            background:@multidim-box-blue-focus;
            box-shadow: 0 0 5px @multidim-box-blue;
        }
    }
}
td.avgray{
    .stockindicator{
        .sRed{
            color:@state-danger-text;
            display:block;
        }
    }
    background:@multidim-grey;
    input.small-squared{
        border:1px solid @multidim-box-grey;
        &:focus{
            background:@multidim-box-grey-focus;
            box-shadow: 0 0 5px @multidim-box-grey;
        }
    }
}
td.avblank{
    .stockindicator{
        .sBlank{
            display:block;
            color:@color-grey-dark;
        }
    }
    background:@multidim-blank;
}

.availtable-variant
{
    display:inline-block;
}

// RFQ - START
div.RFQcontainer{
    width:200px;
}
div.RFQcalendarcontainer{
    width:130px;
}

div.RFQcontainer, div.RFQcalendarcontainer{
    display:inline-block;
    margin-right:20px;
    @media (max-width: @screen-sm-max){
        width:100%;
        margin-right:0;
        margin-bottom:10px;
    }
}



div.RFQsearchfield{
    width: 200px;
    margin-top:15px;
    @media (max-width: @screen-sm-max){
        width:100%;
        margin-top:0;
        margin-bottom:10px;
    }
}

.rfqparent{
    background: @background-light !important;
}

.rfqstatusrow{
    background:@customerFormAltRowBackground !important;
    @media (max-width:@screen-sm-max){
        margin-top:20px;
        div{
            text-align:center !important;
            font-size:@font-size-large
        }
    }
}

.rfqsub{
    padding:0;
    .btn{
        width: 100%;
        border-radius: 0;
    }
}

.rfqparent, .rfqchild{
    div.div-table-col{
        padding-top:0;
        padding-bottom:0;
    }
}

.rfqchild{
    background: @background-default !important;
}
.rfqtable-itemlabel{
    min-width:175px;
}

.rfqtable-shipment{
    min-width:130px;
}


div.rfqtable .div-table-col{
    vertical-align:top;
}

// RFQ - END

//DVPAL20140902 - added to bold header row on quotelist.aspx
td.sortcol{
    input[type="submit"]{
    font-weight:bold; 
    }
}

//DVPAL20150512 - added to fix ship to address table
table.dvp-table-responsive.shiptoaddresstable{
    tbody tr td{
        height:auto;
        min-height:15px;
    }
}

/* END tables.less */