@import "../bootstrap/mixins.less";
@import "../bootstrap/variables.less";
@import "../custom.less";

// Variables
// --------------------------------------------------

// Accent colors
// -------------------------
/*@color-blue:                  #428bca; //DVPDS - old blue = #0065c8*/
@color-blue:                  #2B6CA5; // DVPAL20150708 - changed to a darker blue to comply with contrast ratios
@color-blue-dark:             darken(@color-blue, 5%);
@color-blue-light:            lighten(@color-blue, 5%);

/*@color-blue-light:            #8bc0ee; //DVPDS - new
@color-blue-dark:             #165b96; // DVPDS - changed to different shade (old= #123579)*/
@color-green:                 #46a546;
@color-green-light:           #5cb85c;
@color-red:                   #9d261d;
@color-yellow:                #ffc40d;
@color-orange:                #f89406;
@color-pink:                  #c3325f;
@color-purple:                #7a43b6;
@color-white:                 #fff;
@color-grey-white:            #fafafa;
@color-grey-lightest:         #f5f5f5;
@color-grey-lighter:          #eaeaea;
@color-grey-light2:           #ddd;
@color-grey-light:            #ccc;
@color-grey:                  #949494;
@color-grey-dark:             #666;
@color-grey-darker:           @gray-darker;
@color-black:                 #000;
@color-none:                  none; 

// Text colors

@text-lighter: @color-grey-light;
@text-light: @color-grey;
@text-normal: @color-grey-dark;
@text-dark: @color-grey-darker;
@text-black: @color-black;
@text-link-blue: #0075E8;
@text-link-blue-hover:#00478C;


// Text sizes
@text-h1-size: 16px;
@text-h2-size: 15px;
@text-h3-size: 14px;

// TopArea
//--------------------------
@topTopAreaBoxShadow: 0 0 10px rgba(0,0,0,0.5) ;
@topAreaBackground: #f4f7f8;
@mainSectionBackground: @background-default;

// Top Section Links
//--------------------------
@topNavLinkColor:   #0088cc;
@topNavLinkColorHover: lighten(@topNavLinkColor, 15%);

// Table settings
@tableBorder: 1px solid @color-grey-light;
@tablePadding: 3px 3px 3px 3px;
@tableHeadingBackgroundColor:@color-grey-lighter;
@tableHeadingTextColor:@color-black;


// Navbar
// -------------------------
// LEGACY NAVBAR FROM NTN6
@navbarHoverColor: @color-blue-dark; //DVPDS - old color: 123579
@navbarFontHoverColor: @color-white;
@navbarTop: 55px;
@navbarWidth: 100%;
@navbarMargins: 0 auto;
@navbarBorders: 1px solid #87A3D8; //DVPAL20140703 - changed from #123579
@navbarHeight: 26px;
@navbarBackgroundHighlightColor: @color-blue-dark; //DVPDS - changed from: #123579
@navbarBorderColor: #123579;
@navbarBorderColorTop: #123579;
@navbarBorderColorRight: #123579; 
@navbarBorderColorBottom: #F4F7F8;
@navbarBorderColorLeft: @color-black;

// Top Cart Summary
@topCartSumTop: 30px;
@topCartSumRight: 0;
@topCartSumColor: #0088cc;

// SimpleSearch Widget
@simpleSearchBackground: #f6f6f6; // #0065c8 - DVPDS - old blue
@simpleSearchBorder: 1px solid #cccccc; // #123579 - old color

// Advanced Search //DVPAL20140724 - no longer used
//@advanced-search-background: #f6f6f6;

// Section Titles
@ctrlTitleColor: @sectionTitleColor;

@topSectionBackground: url("../../images/6-SP3-top_section_bg3.gif") no-repeat;
@topSectionHeight: 55px;

// Marquee Settings
@marqueeBackground: @background-default;
@marqueeBorders: 1px solid @themeColor;
@marqueeTextColor:  @color-grey-darker;
@langSelectorBackground: @marqueeBackground;
@langSelectorTextColor: @marqueeTextColor;
@langSelectorBorders: @marqueeBorders;
@langSelectorTitleBackground: #ddd;
@langSelectorTitleColor: @langSelectorTextColor;
@langSelectorSelectedBackground: @color-grey-lighter;
@langSelectorSelectedColor: @color-grey-darker;


// END LEGACY

//=================================
// LAYOUTS
//=================================
@sectionTitleBG: @themeColor-dark;
@sectionTitleColor: @color-white;

@siteLinksTopPosition: 5px;
@siteLinksRightPosition:    110px;
// TODO: Remove these widths
@pageWidth: 960px;
@mainSectionWidth: 630px;
@mainSectionWidthAlt: 780px;
@rightSectionWidth: 140px;
@leftSectionWidth: 160px;

// CHECKOUT HEADER
@checkoutHeaderHeight:          38px;
@checkoutHeaderBorderColor:     @color-grey-light;
@checkoutHeaderBorderThickness: 1px;
@checkoutHeaderBorderStyle:     solid;
@checkoutHeaderBackgroundInactive: #e1e1e1;
@checkoutHeaderBackgroundActive: @color-blue;
@checkoutHeaderBadgeBackgroundInactive: @color-blue;
@checkoutHeaderBadgeBackgroundActive: @color-white;
@checkoutHeaderBadgeColorActive: @color-black;
@checkoutHeaderBadgeColorInactive: @color-white;

// CHECKOUT DIVS
@checkoutSectionBackground: #e5e5e5;

// Dynamic Form Variables
//--------------------------------
// Rating Stars
@ratingStarColor: #ff6a00;
@ratingStarFilledColor: @ratingStarColor;
@ratingStarEmptyColor: @ratingStarColor;
@ratingStarSavedColor: #f00;

// Customer Submission form 
//---------------------------------
@customerFormAltRowBackground: #bedae9;
@customerFormAltRowColor: @color-black;
@customerFormAltRowPadding: 2px 2px 2px 4px;
 
@customerFormNRowBackground: #e3edf3;
@customerFormNRowColor: @color-black;
@customerFormNRowPadding: 2px 2px 2px 4px;
 
@customerFormsubsubHeaderFontSize: 13px;
@customerFormsubsubHeaderFontWeight: bold;
@customerFormsubsubHeaderPadding: 5px 2px 5px 4px;
@customerFormsubsubHeaderBackground: #0557b1;
@customerFormsubsubHeaderColor: @color-white;

// Tabs
//--------------------------------
@tabBorderStyle: solid;
@tabBorderWidth: 1px 1px 0 1px;
@tabBorderColor: @color-grey-light;
@tabBackground: #efefef;
@tabTextColor: @color-grey-darker;
@tabFontWeight: bold;
// hover
@tabBackgroundHover: #ddd;
@tabBorderColorHover: @color-grey-light;
@tabTextColorHover: @color-grey-darker;
// active
@tabBackgroundActive: @color-grey-light;
@tabTextColorActive: @color-grey-darker;
// content
@tabContentBorderStyle: solid;
@tabContentBorderWidth: 4px;
@tabContentBorderColor: @color-grey-light;

// Hover effect mixins
.hoverShadow() { //DVPAL20140715 - mixin for box shadow hover effect
    .transition(all .15s ease-in-out); //transition mixin (found in mixins.less)
    &:hover{
    /*-webkit-box-shadow: 0px 0px 20px 0px rgba(50, 50, 50, 0.2);
    -moz-box-shadow:    0px 0px 20px 0px rgba(50, 50, 50, 0.2);
    box-shadow:         0px 0px 20px 0px rgba(50, 50, 50, 0.2);*/


        -webkit-box-shadow: 0px 3px 0 @themeColor;
        -moz-box-shadow: 0px 3px 0 @themeColor;
        box-shadow: 0px 3px 0 @themeColor;
        -webkit-transform: translate(0px, -3px);
        -moz-transform: translate(0px, -3px);
        -ms-transform: translate(0px, -3px);
        -o-transform: translate(0px, -3px);
        transform: translate(0px, -3px);



    }
}

// Gradient effect mixins
.gradientWhitetoGrey(){
    background: -moz-linear-gradient(top,  #ffffff 0%, #eaeaea 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#f5f5f5), color-stop(100%,#eaeaea));
    background: -webkit-linear-gradient(top,  #ffffff 0%,#eaeaea 100%);
    background: -o-linear-gradient(top,  #ffffff 0%,#eaeaea 100%);
    background: -ms-linear-gradient(top,  #ffffff 0%,#eaeaea 100%);
    background: linear-gradient(to bottom,  #ffffff 0%,#eaeaea 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#eaeaea',GradientType=0 );
}

.gradientGrey(){
    background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,0.75) 75%, rgba(245,245,245,1) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,0)), color-stop(75%,rgba(255,255,255,0.75)), color-stop(100%,rgba(245,245,245,1))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.75) 75%,rgba(245,245,245,1) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.75) 75%,rgba(245,245,245,1) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.75) 75%,rgba(245,245,245,1) 100%); /* IE10+ */
    background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.75) 75%,rgba(245,245,245,1) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#f5f5f5',GradientType=0 ); /* IE6-9 */
}

.gradientLightBlue(){
    background: -moz-linear-gradient(top,  rgba(217,232,245,1) 27%, rgba(232,241,249,1) 55%, rgba(255,255,255,0) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(27%,rgba(217,232,245,1)), color-stop(55%,rgba(232,241,249,1)), color-stop(100%,rgba(255,255,255,0)));
    background: -webkit-linear-gradient(top,  rgba(217,232,245,1) 27%,rgba(232,241,249,1) 55%,rgba(255,255,255,0) 100%);
    background: -o-linear-gradient(top,  rgba(217,232,245,1) 27%,rgba(232,241,249,1) 55%,rgba(255,255,255,0) 100%);
    background: -ms-linear-gradient(top,  rgba(217,232,245,1) 27%,rgba(232,241,249,1) 55%,rgba(255,255,255,0) 100%);
    background: linear-gradient(to bottom,  rgba(217,232,245,1) 27%,rgba(232,241,249,1) 55%,rgba(255,255,255,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d9e8f5', endColorstr='#00ffffff',GradientType=0 );
}

.gradientLightBlueUp(){
    background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 80%, rgba(217,232,245,1) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,0)), color-stop(80%,rgba(255,255,255,0.8)), color-stop(100%,rgba(217,232,245,1)));
    background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 80%,rgba(217,232,245,1) 100%);
    background: -o-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 80%,rgba(217,232,245,1) 100%);
    background: -ms-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 80%,rgba(217,232,245,1) 100%);
    background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 80%,rgba(217,232,245,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#d9e8f5',GradientType=0 );
}

.gradientLightBlueToDark(){
    background: -moz-linear-gradient(top, #428bca 0%, #165b96 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#428bca), color-stop(100%,#165b96));
    background: -webkit-linear-gradient(top, #428bca 0%,#165b96 100%);
    background: -o-linear-gradient(top, #428bca 0%,#165b96 100%);
    background: -ms-linear-gradient(top, #428bca 0%,#165b96 100%);
    background: linear-gradient(to bottom, #428bca 0%,#165b96 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#428bca', endColorstr='#165b96',GradientType=0 );
}