﻿@import "../bootstrap/variables.less";
@import "../bootstrap/grid.less";
@import "../bootstrap/mixins.less";
@import "../bootstrap/buttons.less";
@import "../bootstrap/utilities.less";
@import "../bootstrap/tables.less";
@import "variables.less";

/*wacg.less START*/
//DVPAL20150615 - added a new stylesheet form WACG 2.0 Level AA compliance
/*a{
    &:focus{
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
    }
    &:active, &:hover{
        outline:0;
    }
}*/


//DVPAL20150618 - hidden nav for skip-to links
div#hidden-nav{
    width:100%;
    position:absolute;
    /*text-align:center;*/


    a{
        border:2px solid @themeColor;
        background:@themeColor;
        color:@color-white;
        font-weight:500;
        text-align:center;
        font-size:@font-size-large;
        transition:opacity 500ms ease-in-out;
        -moz-transition:opacity 500ms ease-in-out;
        -webkit-transition:opacity 500ms ease-in-out;
        position:absolute;
        z-index:99999;
        padding:2px 15px;
        opacity:0;
        width:0;
        height:0;
        clip: rect(1px,1px,1px,1px);
        &:focus{
            outline:0;
            border:2px solid @themeColor-dark;
            opacity:1;
            width:auto;
            height:auto;
            clip: rect(auto,auto,auto,auto);
        }
    }
}

/*wacg.less END*/