@import "../../bootstrap/variables.less";
@import "../variables.less";

/* START popups.less */

//DVPAL20150609 - no longer using hardcoded heights
/*div.pricealert-loggedin {
    height: 380px !important; //DVPAL20140606 - when the user isn't logged in, the captcha appears and requires more space
    @media (max-width: @screen-sm-max) {
        height:340px !important;
    }
}*/

// Price alert (sidebutton)
div.pricealertside {
    position: absolute;
    font-size:0; //DVPAL20150609 - prevent gaps and spaces
    top: 202px;
    min-height:75px;
    z-index: 9936;
    display: none;
    background-color: @tabBorderColor;
    padding: 2px;
    width: 240px;
    margin-bottom: 5px;
    /*height: 250px;*/
    -moz-box-shadow: 0px 0px 13px 1px rgba(50, 50, 50, 0.3);
    -webkit-box-shadow: 0px 0px 13px 1px rgba(50, 50, 50, 0.3);
    box-shadow: 0px 0px 13px 1px rgba(50, 50, 50, 0.3);

    @media (max-width: @screen-sm-max) {
        left:0 !important; 
        top:0!important; 
        position:relative; 
        margin:10px auto; 
        width:100%;
        /*height:240px;*/
    }
}

// Add a review & tell a friend
//-----------------------------------
div.tellafriend {
    font-size:0; //DVPAL20150609 - prevent gaps and spaces
    position: absolute;
    top: 41px;
    min-height:75px;
    z-index: 9936;
    display: none;
    background-color: @tabBorderColor;
    padding: 2px;
    width: 320px;
    margin-bottom: 5px;
    //height: 450px;
    /*height: 330px;*/
    -moz-box-shadow: 0px 0px 13px 1px rgba(50, 50, 50, 0.3);
    -webkit-box-shadow: 0px 0px 13px 1px rgba(50, 50, 50, 0.3);
    box-shadow: 0px 0px 13px 1px rgba(50, 50, 50, 0.3);

    @media (max-width: @screen-sm-max) {
        left:0 !important; 
        top:0!important; 
        position:relative; 
        margin:10px auto; 
        width:100%;
    }
}

div.tellafriendMessage    
    {
    textarea{
            height:40px;
            resize:none;
            margin-bottom:5px;
        }
    }

div.addreview{
    font-size:0; //DVPAL20150609 - prevent gaps and spaces
    position: absolute; // DVPAL20140721 - changed to absolute
    z-index: 9936;
    min-height:75px;
    top:auto;
    /*top:123px !important;*/
    display: none;
    background-color: @tabBorderColor;
    padding: 2px;
    width: 320px;
    margin-bottom: 5px;
    /*height: 360px; //temporary*/
    -moz-box-shadow: 0px 0px 13px 1px rgba(50, 50, 50, 0.3);
    -webkit-box-shadow: 0px 0px 13px 1px rgba(50, 50, 50, 0.3);
    box-shadow: 0px 0px 13px 1px rgba(50, 50, 50, 0.3);

    @media (max-width: @screen-sm-max) {
        top:auto !important;
        left:0 !important;
        margin:10px auto;
        width:100%;

    }
}

//DVPAL20140604 - select boxes within the review iframe
/* DVPJL20140903 - ntn72013 - comment out and style in forms.less
.reviewframe input[type="text"], .reviewframe select, .tellafriend input[type="text"], .tellafriend select
{
    margin-bottom:5px;
        @media (max-width: @screen-xs-max){
        width:100%;
    }
}

.reviewframe table tbody tr td {
    vertical-align: middle;
    @media (max-width: @screen-xs-max) {
        width:50%;
    }
}

.reviewframe table tbody tr td tbody tr td{
    width:10%;
}
*/
.reviewframe table, .tellafriendframe table{
    @media (max-width: @screen-xs-max){
        width:100%;
    }
}

.reviewframe input.btn{
    width:100%;
}

// Cancel button styling for addreview and tellafriend
#form1 div a#btnCancelTop, body.tellafriendframe a#btnCancelTop,body.alertbodyframe a#btnCancelTop,body.reviewframe a#btnCancelTop{
    text-align:center;
    margin-left:auto;
    line-height:1.3;
    width:20px;
    height:20px;
    display:block;
    margin-bottom:5px;
    padding:0;

    //DVPAL20140605 - added for close button hover glow
    &:hover{
        -webkit-box-shadow: 0px 0px 6px 0px rgba(255, 0, 0, 0.55);
        -moz-box-shadow: 0px 0px 6px 0px rgba(255, 0, 0, 0.55);
        box-shadow: 0px 0px 6px 0px rgba(255, 0, 0, 0.55);
    }

    &:before{
        content:'\2716';
    }
}
body.tellafriendframe a#btnCancelTop, body.alertbodyframe a#btnCancelTop,body.reviewframe  a#btnCancelTop {
    float:right; // DVPAL20140605 - button needs to float right since there is a title in tellafriend
}

div.btn-tellafriend {
    margin:10px auto 0 auto;
    width:100%;
    .btn{
        width:46%;
    }
}

div.tellafriendForm, div.pricealertside {
    div.dleft{
        margin-top:5px;
    }

/* DVPAL20140605 - captcha section formatting specific to tellafriend - might remove soon after standardization*/
    div.captcharefreshdiv {
        vertical-align: bottom;
    }

    div.Captcha, div.captchainput input.form-control {
        width: 100%;
    }
    div.captchainput{
        margin-bottom:5px;
    }
}

// Sets style of IFrame used to wrap the Add Review control
iframe.reviewframe, iframe.tellafriendframe,iframe.pricealertframe {
    width:100%; 
    padding:8px; //DVPAL20140604 - the iframe takes up the entire space provided by the container
    height:100%; 
    border:0;
    background-color:@background-default;
}

iframe.reviewframe html{
    background-color:@background-default;
}
// Accessory
//-----------------------------------
div.accessory {
	position:absolute;
	right:14px;
	z-index:9932;
    display: none;
	background-color:@tabBorderColor;
	padding:3px;
	width:600px;
	left:30px;
}

.accessory {
	position:absolute;
	right:14px;
	z-index:9932;
    display: none;
	background-color:@tabBorderColor;
	padding:3px;
	width:600px;
	left:30px;
}

iframe.accessoryframe {
    width:600px;
    height:300px;
    border:0;
    left:20px;
}

// Price Alert
//-----------------------------------
div.pricealert {
	position:absolute;
	z-index:9931;
    display: none;
	background-color :@tabBorderColor;
	padding:1px;
	width:272px;
    height:242px;
    /*height: 100%;*/
}


// Location Panel
//-----------------------------------
div.locpanel {
	position:absolute;
	z-index:9931;
    display: none;
	background-color :@tabBorderColor;
	padding:2px;
	width:203px;
    height:125px;//DVPAL20140605 - fixed height for location panel since no more than three locations can be listed
    min-height:120px;
    -moz-box-shadow: 0px 0px 13px 1px rgba(50, 50, 50, 0.3);
    -webkit-box-shadow: 0px 0px 13px 1px rgba(50, 50, 50, 0.3);
    box-shadow: 0px 0px 13px 1px rgba(50, 50, 50, 0.3);

    @media (max-width: @screen-sm-max){
        width:100%;
        position:relative;
        margin-bottom:5px;
    }
}

iframe.locpanelframe {
    width:100%; //DVPAL20140604 - added auto allow iframe to take up entire container
    height:100%;
    border:0;
    padding:0px;
}

body.alertbodyframe, body.locframe{
    margin:0;
    background:@background-default;

    form#form1{
        padding:8px;
    }
}



//DVPAL20140605 - store availability location list styles
div.myAvailability{
    .locListContainer{
        width:100%;
        margin-bottom:10px;
        .locListimg, .locListlink{
            display:inline-block;
        }
    }
}

.parametric,.master-child{
    .locListContainer{
        margin-bottom:0 !important;
    }
}

//DVPAL20140728 - added for variant item locations in the table
div.avail-inline { 
    margin-left:10px;
    display:inline-block;
    vertical-align:middle;

    @media (max-width: @screen-sm-max){
        float:none;
        margin-bottom:10px;
    }

    .myAvailability{
        .locListContainer{
            margin:0;
            margin-right: 10px;
            display:inline-block;
            width:auto;
        }
    }
}

div.locList {
    div.locListRow {
        border:@tableBorder;
        margin-bottom:-1px;
        padding:0 0 2px 5px;
        background:@tabBackground;

        div {
            display: inline-block; // DVPAL20140605 - added to allow for divs under each row of the location list to be placed side-by-side
        }
    }

    div.inventoryStatus {
        margin-right: 5px;
    }
}

// Sets style of IFRAME used to wrap the Price Alert


// Availability Alert
//-----------------------------------
// Sets style of DIV used to wrap the 'Notify when in Stock'
div.availabilityalert {
	position:absolute;
	background-color :@tabBorderColor;
	padding:3px;
	width:280px;
    display: none;
}

// Sets style of iFrame for 'Notify when in Stock'
iframe.availabilityalertframe {
    width:280px;
    height:220px;
    border:0;
}

// used for iframes
body.reviewframe
{
    margin: 0;
    background-color: @background-default;
}

// Web availability & Price alert form styling

div.emailFieldContainer, div.yourEmailLabel{
    width:100%;
    display:block;
    margin-bottom:5px;
}
// DVPAL20140609 - captcha format and style
body.alertbodyframe form div.captchaRow #catptchadiv {
    display:block;
    .Captcha {
        width:100%;
        display:block;
        .captchaimgdiv, .captcharefreshdiv
        {
            vertical-align: middle;
        }
        .captchainput{
            margin-bottom:5px;
            width:100%;
            display:block;
            input.form-control{
                width:100%;
            }
        }
        
    }

}
// DVPAL20140609 - button format and style
body.alertbodyframe form {
    .btn-toolbar {
        text-align: center;
        width: 100%;

        input#btnsubmit, a#cancelbtn {
            width: 100%;
            margin-bottom: 5px;
        }
    }
}

div.loadingIcon{
  width: 100%;
  height: 100%;
  color:@themeColor;
  display: block;
  z-index: 9999;
  position: absolute;
  font-size: 40px;
  background: @background-default;
  text-align: center;
}

//DVPAL20150721 - saved cart close button
  .savedCartClose{
      margin-top:-50px;
    text-align:center;
    margin-left:auto;
    line-height:1.3;
    width:20px;
    height:20px;
    display:block;
    margin-bottom:5px;
    padding:0;

    //DVPAL20140605 - added for close button hover glow
    &:hover{
        -webkit-box-shadow: 0px 0px 6px 0px rgba(255, 0, 0, 0.55);
        -moz-box-shadow: 0px 0px 6px 0px rgba(255, 0, 0, 0.55);
        box-shadow: 0px 0px 6px 0px rgba(255, 0, 0, 0.55);
    }

  }


/* END popups.less */