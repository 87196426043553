﻿@import "../variables.less";
@import "../../bootstrap/mixins.less";

// DVPDS - Added mixins import above

/* START tabs.less */
// Used in the tabs of the Product Details templates - color of tab when selected
// Note:changes made here must also be made to the templates themselves, as the javascript used for the tabbing requires specific values
// ALSO USED IN LOGIN PAGE

//DVPDS - START old tab styles, no longer used
//td.mausover {background-color: #ddd;}
//td.mausnotover {background-color: #f5f5f5;}
//td.postmausover {background-color: #dddddd; padding: 2px;}

// Used on the Pagelinks, accountlinks and signinsummary controls
//td.navwhite {color: #fff;}

// used in the My Favorites control
//td.padded {background-color: #B3B3B3; padding: 3px 0 0 0;}
// DVPDS - END


//------------------------------
// New Tabs structure
// DVPTP20130826
//------------------------------
ul.tabs,
ol.tabs,
ul.tab-contents,
ol.tab-contents {
    margin-left: 0;
    list-style: none;
    .clearfix();

    li {
        float: left;
    }
}

ul.tabs{
    margin-bottom: 0;

    & > li {
        margin: 0 5px 0 0; 
        width: 122px;

        &:last-child {
            margin-right: 0;
        }

        a {
            display: block;
            padding: 5px 15px;
            height: 100%;
            text-decoration: none;
            border-radius: 5px 5px 0 0;
            border-style: @tabBorderStyle;
            border-width: @tabBorderWidth;
            border-color: @tabBorderColor;
            background: @tabBackground;
            color: @tabTextColor;
            font-weight: @tabFontWeight;
            text-align: center;

            &:hover {
                background: @tabBackgroundHover;
                border-color: @tabBorderColorHover;
                color: @tabTextColorHover;
            }
        }

        &.active {
            a,
            a:focus {
                background: @tabBackgroundActive;
                color: @tabTextColorActive;
            }
        }
    }
}

div.tab-contents {
    border: 1px  @tabContentBorderStyle @tabContentBorderColor;
    border-top: @tabContentBorderWidth @tabContentBorderStyle @tabContentBorderColor;

    & > div {
        padding: 5px;
        display: none;
        min-height: 150px;

        &.active {
            display: block;
        }
    }
}

table.tabs {
    table-layout: fixed;
    border-collapse: separate;
    width: 100%;
    margin-bottom: 0;

    td {
        vertical-align: top;

        a {
            display: block;
            padding: 5px 10px;
            height: 100%;
            text-decoration: none;
            color: @tabTextColor;
            font-weight: @tabFontWeight;
            text-align: center;
            margin-right: 2px;
            //border-radius: 5px 5px 0 0;
            border-style: @tabBorderStyle;
            border-width: @tabBorderWidth;
            border-color: @tabBorderColor;
            background: @tabBackground;

            &:hover {
                background: @tabBackgroundHover;
                border-color: @tabBorderColorHover;
                color: @tabTextColorHover;
            }
        }

        &:last-child a {
            margin: 0;
        }

        &.active {
            a,
            a:focus {
                background: @tabBackgroundActive;
                color: @tabTextColorActive;
            }
        }
    }
}


table#tabs-productdetails {
    margin-top: 20px;
}


div#userreview, article#tabbed_userreview{
    div.userreviewrow{
        margin:0;
        padding:15px;
        border-bottom:1px solid @default-border-color;
        &:last-child{
            border-bottom:none;
        }
        div.reviewername{
            margin-bottom:10px;
        }
    }
}
/* END tabs.less */