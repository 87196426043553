@import "../../bootstrap/variables.less";
@import "../../bootstrap/mixins.less";
@import "../variables.less";

/* START categoryitems.less */
// Shared settings for CategoryItems/CategoryHighlightItems
//=============================================================
// used for short desc text on CatalogHighLightItems and CategoryItems control
span.highlighttext
{
    font-size: @font-size-base;
    font-weight: normal;
    color: @color-grey-darker;
}

// Affects: CategoryItems
//=========================================
// DVPDS - no references found to this entry
//table.ItemListBlock
//{
//    background-color: #ededed;
//    text-align: center;
//    margin-bottom: 5px;
//   margin-top: 5px;
//    border: solid 1px #ccc;
//    // TODO: remove this reference
//    background-image: url(../../images/itemblockbg.gif);
//}

// DVPDS - no references found to this entry
//table.ItemList
//{
//    background-color: #fff;
//    width: 130px;
//}

// DVPDS - no references found to this entry
//table.CatItemBlock
//{
//    background-color: #eee;
//    text-align: center;
//    margin-bottom: 3px;
//    margin-top: 3px;
//    border: solid 1px #dfdfdf;
//}

// DVPDS - no references found to this entry
//table.CatItem
//{
//    background-color: #fff;
//    width: 90px;
//}

// DVPDS - no references found to this entry
//table.CatSmallItemBlock
//{
//    background-color: #ededed;
//    text-align: center;
//    margin-bottom: 3px;
//    margin-top: 3px;
//    border: solid 1px #ccc;
//}

// DVPDS - no references found to this entry
//table.CatSmall
//{
//    background-color: #fff;
//    width: 65px;
//}

// Affects: CatalogHighlightItems.ascx
//=====================================
// TODO: Clean this up for the responsive section as this will be handled through col-SIZE-WIDTH
h3#FeaturedTitle
{
    font-size: 22px;
    color:@themeColor;
    margin-top:15px;
    font-weight:300;
    @media (max-width: @screen-xs-max){
        text-align:center;
    }
}

.FeatItemContainer
{
    /*margin-left: -5px;
    margin-right: -5px;*/
    .clearfix;

    padding: 10px 0;

    @media (max-width: @screen-xs-max) {
        /*margin-right: -15px;
        margin-left: -15px;*/
    }

    .FeatItem
    {

        /*background-color: #fff;
        text-align: center;
        margin: 0 10px 20px 5px;

        border: @featuredItemBoxBorder;
        height: auto;
        padding: 5px;*/


        //DVPAL20140717 - new style START
        background-color: @background-default;
        border-radius:@border-radius-large;
        text-align: center;
        margin: 0 10px 20px 0px;
        .hoverShadow; //DVPAL20140715 - hover shadow effect (mixin)
        /*.gradientLightBlueUp; //DVPAL20140716 - gradient effect (mixin)*/

        //border: @featuredItemBoxBorder; DVPAL20140715 - removed border
        height: auto;
        padding: 25px 5px 5px 5px;
        border:1px solid @featuredItemBorderColor;
        &:hover{
            border:1px solid @featuredItemBorderColor-hover;
        }
        //DVPAL20140717 - new style END

        // NOTE: due to the responsive nature of the columns, we cannot target last/first-child since it will vary based on viewsize
        // so we will offset the difference in the various view queries.
    
        // ALOT of media queries place in order of precedence descending
        /*@media (max-width: @screen-md-max) {
            &.col-md-3 {
                width: 22%;
            }
        }

        @media (max-width: @screen-sm-max) {
            &.col-sm-5 {
                width: 29%;
            }
        }*/

        @media (min-width: @screen-sm-min) {
            &.col-lg-3 {
                width: 24.25%;
                margin-right:1%;
                &:nth-child(4n+0){
                    margin-right:0;
                }
            }
        }
    
        // This is our special case of row layout
        @media (max-width: @screen-xs-max) {
            padding: 25px 20px 10px 20px;
            border-radius:0;
            background:none !important; //DVPAL20140731 - gradient background collides with image
            margin: 0 0 20px 0; //DVPAL20140715 - changed bottom margin to 20px since we don't use borders anymore
            margin-bottom:10px;
           // margin-bottom: -1px; // just to avoid the duplicate lines from next box


            &.col-xs-12 {
                width: 100%;
            }

            .clearfix;

            .FeatItemImage {
                float: left;
                margin-right: 20px;
            }

            .FeatItemInfo {
                text-align: left;
            }
        }


        .FeatItemImage
        {
            min-height:110px;
            a
            {
                display: block;

                img
                {
                    width: 110px;
                    height: 110px;
                    border: 0;
                }
            }
        }

        .FeatItemInfo {
            h1 {
                font-size: @font-size-small;
                margin: 0;
            }

            .FeatItemShortDescription {
                font-size: 13px;
                font-weight: 600;
                color: @color-grey-dark;
                margin: 5px 0;
            }
            //DVPAL201503009 - highlight display update
            /*.FeatItemHighlightDescription {
                font-size: 13px;
                height: 16px;
                line-height: 12px;
                margin: 2px 0;
            }*/

            .FeatItemHighlightDescription{
                position:absolute;
                width:100%;
                margin:0 auto;
                padding:2px;
                left: 0;
                right: 0;
                top:0;
                background:@featuredItemHighlightBackgroundColor;
                border-radius:@border-radius-large @border-radius-large 0 0;
                text-align: center;
                @media (max-width: @screen-xs-max){
                    border-radius:0;
                }
            }

            .FeatItemMore {
                p {
                    margin: 0 0 5px;
                    font-size: 11px;
                    height: auto;
                    line-height: 12px;
                }
            }

            .FeatItemPrice {
                font-size: 15px;
                font-weight:bold;
                color: @color-grey-darker;
                padding: 0;
                margin: 2px 0 10px;
            }
        }
    }
}


// DVPDS - no references found to this entry
//table.Feat
//{
//    background-color: #fff;
//    width: 130px;
//}

// DVPDS - replaced FeatItemC with Featured-Items-Rep
/* used on the featureditems.ascx (used on the welcome page for logged in users) */
div.Featured-Items-Rep
{
    width:100%;
    background-color: @background-default;
    text-align: center;
    border: 0;
    display: inline-block;
    .Featured-Item {
        /*.hoverShadow; //DVPAL20140715 - hover shadow effect mixin*/
        float:left;
        width: 14%; // DVPDS - for large and above view
        padding:10px;
        margin:5px;
        //border:@featuredItemBoxBorder;

        // DVPAL20140529 - responsive box sizes

        @media (min-width: @screen-md-max) {
            width: 19%;
            padding:5px;
        }
        @media (max-width:  @screen-sm-max) {
            width: 100px;
            padding:5px;
        }
        @media (max-width:  @screen-xs-max) {
            width: 100%;
            height:auto;
            padding:10px;
        }
    }
}

// DVPDS - no longer used
//table.FeatItemD
//{
//    background-color: #fff;
//    width: 70px;
//}

div#FeatItemEContainer {
    margin-top: 30px;
    text-align: center;

    .item{ /*DVPAL20140610 - added for margin between items */
        margin-bottom: 10px; 
        @media (max-width: @screen-sm-max){
            margin-bottom:20px;
        }
    }

    h3 {
        text-align: left;
    }

    div.FeatItemE
    {
        background-color: @background-default;
        width: 64px;
        display: inline-block;
        float: left;
        margin: 0 5px;
        
        a {
            display: block;
            margin-bottom: 5px;
        }

        &.FeatItemEThin {
            margin: 0 3.25px;
        }
    }
}

 .itemlist-checkbox {
     // XS
     @media (max-width:  @screen-lg-min) {
     }
     // SM
     @media (min-width: @screen-sm-max) {
        
     }
 }

 .itemlist-image-wrapper,
 .itemlist-description-wrapper {
     display: inline-block;
     vertical-align: top;
 }

 .itemlist-image-wrapper {
     @media (max-width: @screen-sm-max) {
         
     }

     @media (max-width: @screen-xs-max) {
         display: block;
     }
 }

 .itemlist-description-wrapper {
     @media (max-width: @screen-sm-max) {
         
     }

     @media (max-width: @screen-xs-max) {
         display: block;

         .itemlist-addtocart-wrapper {
             display: block;
         }

         .itemlist-description {
             padding: 0;
         }
     }

     & > div {
         display: inline-block;
         vertical-align: top;
     }
 }

.itemlist-addtocart-wrapper
{
    @media (max-width: @screen-sm-max)
    {
    }
}

.item-list-row {
    
    border: 1px solid @color-grey-darker;
}

.item-list-col {

    @media (max-width: @screen-xs-max) {
        & > div,
        & > a {
            display: inline-block !important;
        }
    }
}

.item-list-item {   
    .item-list-col;
}
.item-list-itemno {   
    .item-list-col;
}
.item-list-name {   
    .item-list-col;
}
.item-list-webprice {   
    .item-list-col;
}
.item-list-salesprice {   
    .item-list-col;
}
.item-list-uom {
    .item-list-col;
}

.item-list-header
{
    .row;

    .item-list-item {}
    .item-list-itemno {   
        color: red;
    }
    .item-list-name {}
    .item-list-webprice {}
    .item-list-salesprice {}
    .item-list-uom {}
}

.item-list-body
{
    .item-list-item {}
    .item-list-itemno {}
    .item-list-name {}
    .item-list-webprice {}
    .item-list-salesprice {}
    .item-list-uom {}
    .item-list-addtocart {}
}

// DVPDS - duplicate of above - commented out
// .item-list-item {}


.categoryItemContainer
{
    margin-left: -5px;
    margin-right: -5px;
    .clearfix;
    padding: 10px 0;

    @media (max-width: @screen-xs-max)
    {
        /*margin-right: -16px; // offset of container padding + 1px for hiding the right border of Items
        margin-left: -15px;*/
        margin:0px;
    }

    .each-category-item
    {
        .each-category-item-price{
            font-size: 15px;
            color: @color-grey-dark;
        }
        /*.hoverShadow;*/
        background-color: @background-default;
        text-align: center;
        margin: 0 15px 20px 5px; //DVPDS - adjusted to align the items properly
        // margin: 0 10px 20px;
        /*border: solid 1px #dfdfdf;*/
        width :auto;
        padding: 5px;
        .clearfix;

        // NOTE: due to the responsive nature of the columns, we cannot target last/first-child since it will vary based on viewsize
        // so we will offset the difference in the various view queries.
        // ALOT of media queries place in order of precedence descending
        @media (max-width: @screen-md-max)
        {
            &.col-md-3
            {
                width: 22%;
            }
        }

        @media (max-width: @screen-sm-max)
        {
            &.col-sm-5
            {
                width: 28%;
            }

        }

        @media (min-width: @screen-lg-min)
        {
            &.col-lg-3
            {
                width: 22.5%;
            }

        }
        // This is our special case of row layout
        @media (max-width: @screen-xs-max)
        {
            padding: 10px 20px; 
            margin: 0; 
            margin-bottom: 10px;

            &.col-xs-12
            {
                width: 100%;
            }

            .clearfix;

            .each-category-item-Image
            {
                float: left;
                margin-right: 20px;
            }

            .each-category-item-info
            {
                text-align: left;
            }
        }


        .each-category-item-Image
        {
            .thumbnailImg
            {
                a
                {
                    display: block;

                    img
                    {
                        width: 110px;
                        height: 110px;
                        border: 0;
                    }
                }
            }

            .each-category-item-info-short-desc
            {
                font-size: 11px;
                font-weight: 700;
                color: @color-grey-darker;
                margin: 5px 0;
            }
        }

        .each-category-item-info
        {
            h1
            {
                font-size: @font-size-small;
                margin: 0;
            }

            .each-category-item-info-name
            {
                font-size: @font-size-small;
                font-weight: 700;
                color: @color-grey-darker;
                margin: 5px 0;
                text-overflow: ellipsis;
            }


            .each-category-item-learnitemmore
            {
                p
                {
                    margin: 0 0 5px;
                    font-size: 11px;
                    height: auto;
                    line-height: 12px;
                }
            }

            .each-category-item-price
            {
                font-size: 12px;
                color: @color-grey-dark;
                padding: 0;
                margin: 2px 0 10px;
            }
        }
    }
}

/* END categoryitems.less */