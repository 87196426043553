@import "../../bootstrap/variables.less";
@import "../../base/forms.less";
@import "../variables.less";

/* START recentlyviewed.less */
// Defines 'recently viewed' section within the LeftSection DIV
.RecentlyViewedControl {
    /*.borderStyle-base;*/
    border:1px solid @default-border-color;
    border-top:none;
    padding: 1px;
    //background:#f5f5f5; //DVPJL20140514 - Delete background color.

	.thumbnail {
        height:25px;
        width:25px;
        margin-bottom:5px;
        border-radius: 0;

		img {
			width: 100%;
		}
        
	}
	
	.link {
		font-size:12px;
		line-height: 12px;
		width: 60%;
		clear: none;

		&:hover {
			color: @color-black;
		}
	}
}

a.viewedfonts
{
    .breakword;
    font-size: 12px;
    display: block;
    position: relative;
    text-decoration: none;
}

a.viewedfonts:HOVER
{
    color: @color-black;
    text-decoration: none;
}

.recentitem-description-wrapper
{
    /*.row;*/
    margin: 0;
    /*height :28px;*/
    height:auto;
    padding:2px;
    
    .image-col,
    .arrow-col,
    .description-col {
        display: inline-block;
        vertical-align: top;
    }

    .image-col {
        width: 13%;
         border :none
      
    }

    .arrow-col {
        width: 3%;
        vertical-align:middle;
        padding: 1px;
    }

    .description-col {
        width: 85%; //DVPAL2014062014 - adjusted to 85% to provide more room for text
        vertical-align:middle
    }
}

/* END recentlyviewed.less */