@import "../../bootstrap/variables.less";
@import "../variables.less";

/* START itemlist.less */
// sets table in ItemList.ascx
// DVPDS - no references exist to this
//table.itemlisting
//{
//    width: 100%;
//    padding: 5px 3px 5px 3px;
//}

// sets TD in ItemList.ascx
// DVPDS - no references exist to this
//td.item
//{
//    width: 60px;
//    padding: 3px 2px 3px 3px;
//    background-color: #f5f5f5;
//    border: solid 1px #fff;
//    border-width: 0 1px 1px 0;
//}

// sets TD in ItemList.ascx
// DVPDS - no references exist to this
//td.itemimage
//{
//    width: 32px;
//    padding: 1px;
//    background-color: #fff;
//    border: solid 1px #fff;
//    border-width: 0 1px 1px 0;
//}

// sets TD in ItemList.ascx
// DVPDS - no references exist to this
//td.itemname
//{
//    width: 52%;
//    padding: 3px 2px 3px 3px;
//    background-color: #f5f5f5;
//    border: solid 1px #fff;
//    border-width: 0 1px 1px 0;
//}

// sets TD in ItemList.ascx
// DVPDS - no references exist to this
//td.itemtax
//{
//    width: 15%;
//    text-align: right;
//    padding: 3px 2px 3px 3px;
//    background-color: #f5f5f5;
//    border: solid 1px #fff;
//    border-width: 0 1px 1px 0;
//}

// sets TD in ItemList.ascx
// DVPDS - no references exist to this
//td.itemqty
//{
//    width: 14%;
//    text-align: right;
//    padding: 1px 2px 1px 2px;
//    background-color: #f5f5f5;
//    border: solid 1px #fff;
//    border-width: 0 0 1px 0;
//}

// Used for the Header on the itemlist.ascx control
// DVPDS - no references exist to this
//tr.headergrid th
//{
//    background-color: #e3e3e3;
//    font-size: 11px;
//    padding: 3px;
//}



td.col-item-qty
{
    background-color: @background-lighter;
    padding: 3px 5px;
    text-align: right;
}

td.col-item-qty,
td.itemqty
{
    & > span
    {
        padding: 1px 5px 1px 2px;
    }
}

    td.itemqty .wishlist,
    td.col-item-qty .wishlist
    {
        margin: 0 5px 5px;
    }

// used on:childitems.ascx, specification.ascx, VariantsTable.ascx
td.cItem
{
    background-color: @background-light2;
    padding: 4px 3px 4px 5px;
    font-weight: 600;
}

// used on:Myfavoritedetails, ChildItems.ascx and VariantsTable.ascx
td.cItemCnt
{
    background-color: @background-lighter;
    padding: 3px;
    .side-axis-header{
        font-weight:600;
    }
}

// used for images in the child items
td.cItemCntImg
{
    background-color: @background-default;
    padding: 2px;
    border: solid 1px @default-border-color-light;
}

// used for extra buttons area in the child items
td.cItemCntL
{
    background-color: @background-lighter;
    padding: 2px 2px 0 3px;
    border: solid 3px @color-white;
    border-width: 0 0 3px 0;
}

// used on the ChildItems and VariantsTable controls
td.cItemCart
{
    background-color: @background-lighter;
    padding: 0 0 1px 0;
    text-align: center;
}


// Affects:
//  ContactList.ascx,
//  ManageCustomer,
//  ManagerAgents,
//  OrderHistory,
//  OrderHistorySummary,
//  SelectCustomer,
//  reports_listing.ascx,
//  Reports_Navigate.ascx
td.itm
{
    background-color: @background-lightest;
    padding: 0 2px 0 3px;
}

td.itm2
{
    background-color: @background-lightest;
    padding: 1px 2px 1px 3px;
}


//----------------------
// NEW RESPONSIVE STYLES
//----------------------
.itemlist {
    div {
        vertical-align: top;
    }

    .itemlist-row {
        border-bottom: 1px solid @default-border-color-darker;
    }

    
}

// DVPDS - Pulled out of the .itemlist above:
//.itemlist-header {
//        .row;
//
//        @media (max-width: @screen-sm-max) {
//            display: none;
//        }
//    }
//
//    .itemlist-row {
//        .row;
//        position: relative;
//        border: 1px solid #333;
//    }
//
//    .itemlist-image-wrapper,
//    .itemlist-description-wrapper {
//        display: inline-block;
//    }

//    .itemlist-image-wrapper {
//        width: 5%;
//    }

//    .description {
//        display: inline-block;
//        .itemno,
//        .name,
//        .webprice,
//        .salesprice,
//        .uom {
//            display: inline-block;
//            border-right: 1px solid red;
//        }

//        .itemno {
//            width: 15%;
//        }
//    }

//    .label {
//        display: block;
//    }

//    .itemlist-image-wrapper,
//    .itemlist-description-wrapper,
//    .itemlist-addtocart-wrapper,
//    .description {
//        display: inline-block;
//    }

//    .itemlist-image-wrapper,
//    .itemlist-addtocart-wrapper,
//    .description {
//        border-left: 1px solid #333;
//    }

//   .itemlist-addtocart-wrapper {
//        float: right;
//    }

//    .description {
//        width: 50%;
//
//        .itemno,
//        .name,
//        .webprice,
//        .salesprice,
//        .uom {
//            display: inline-block;
//            width: 100%;
//        }
//    }

//    .label {
//        color: #333;
//        text-align: right;
// }
/* END itemlist.less */