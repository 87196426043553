
/* START captcha.less */
.Captcha {
    text-align: center;
    width:200px;
}

.captchaimgdiv,
.captcharefreshdiv
 {
    display: inline-block;
    margin: 0 5px;
}

.captchainput {
    .form-control {
        width:120px;
    }
}
/* END captcha.less */