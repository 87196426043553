@import "../variables.less";
@import "../../bootstrap/mixins.less";

/* START leftcategory.less */
// Affects: LeftCategoryWithoutTree.ascx
//---------------------------------------
// set top category div here

// DVPDS - Reworking of the leftCatwrapper (and child divs)
div#leftCatwrapper {
    .borderStyle-base;
    div {
        position: relative;

        // set first level category div here
        &.tcat {background-color: #cee3f7;}

        // set second level category div here
        div.twocat {background-color: #e4effa;}

        // set third level category div here
        div div.threecat {background-color: #f1f7fc;}

        // set fourth level category div here
        div div div.fourcat {background-color: @color-white;}

        // set fifth level category div here
        div div div div.fivecat {padding-left: 60px;}
    }
}




// START - top level link
a.tcat,
a.tcat:visited,
a.tcaton,
a.tcaton:visited
{
    font-weight: bold;
    color: @text-link-blue-hover;
    display: block;
    position: relative;
    padding: 5px 2px 5px 3px;
    text-decoration: none;
    // set hover color of top level links here
    &:hover
    {
        background-color: @background-default;
        text-decoration: none;
    }
}

// START - second level link
a.twocat,
a.twocat:visited,
a.twocaton,
a.twocaton:visited
{
    color: @text-link-blue-hover;
    display: block;
    position: relative;
    padding: 5px 2px 5px 18px;
    text-decoration: none;
    // set hover color of second level links here
    &:hover
    {
        background-color: @background-default;
        text-decoration: none;
    }
}

// start third level link here
a.threecat,
a.threecat:visited
{
    color: @text-link-blue-hover;
    display: block;
    position: relative;
    padding: 5px 2px 5px 33px;
    text-decoration: none;
    // set hover color of third level links here
    &:hover
    {
        background:@background-default;
        text-decoration: none;
    }
}

// start fourth level link here
a.fourcat,
a.fourcat:visited
{
    color: @text-link-blue-hover;
    display: block;
    position: relative;
    padding: 5px 2px 5px 48px;
    text-decoration: none;
    // set hover color of fourth level links here
    &:hover
    {
        background:@background-default;
        text-decoration: none;
    }
}

// start fifth level link here
a.fivecat,
a.fivecat:visited
{
    color: @text-link-blue-hover;
    display: block;
    position: relative;
    padding: 5px 2px 5px 63px;
    // set hover color of fifth level links here
    &:hover
    {
        background:@background-default;
    }
}




// Affects: LeftCategory with tree
a.ll1
{
    font-weight: 700;
    color: #464646;
    text-decoration: none;

    &:hover
    {
        color: @color-black;
        text-decoration: none;
    }
}

a.ll2
{
    font-weight: 700;
    color: #7b7b7b;
    text-decoration: none;

    &:hover
    {
        color: @color-black;
        text-decoration: none;
    }
}

a.ll3
{
    font-weight: 700;
    color: @color-grey-dark;
    text-decoration: none;

    &:hover
    {
        color: @color-black;
        text-decoration: none;
    }
}



// Defines 'left category' section within the LeftSection DIV
#LeftCategoryBody
{
    background: @leftCategoryBodyBackground;
    margin-left: 0;
    margin-top: 0;
    border: @leftCategoryBodyBorder;

    table tbody tr td:first-child{
        width:28px;
        display:block;

    }

    a
    {
        .transition(all 0.2s ease-in-out);
        color: @leftCategoryBodyLinkColor;
        text-decoration:none;
        padding:3px;
        position:relative;
        display:block;

        &:hover
        {
            //color: darken(@leftCategoryBodyLinkColor, 15%);
            /*background-color:@leftCategoryBodyLinkHoverColor;*/
        }
    }

    & > a
    {
        display: none;
    }
}
/* END leftcategory.less */