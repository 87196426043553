@import "../variables.less";
@import "../../bootstrap/variables.less";

/* START langregionselector.less */
.lang-region-container {
    @marquee-height: 24px;

    height: @marquee-height;
    width: 80px;
    margin-top: 10px;
    margin-left: 15px;
    padding-top: 0;

    position: relative;
    display: block;
    float: right;
    @media (max-width: @screen-xs-max){
        float:none;
        margin:0 auto;
        height:25px;
        padding-top:14px;
    }

    @media (min-width: @screen-sm-min) {
        padding-top: 15px;
        padding-bottom: 15px;
        margin-top: 0;
    }

    .lang-scroller-container {
        position:absolute;
        background:@background-default;
        //background-color: @marqueeBackground;
        z-index:9954;
        padding:0;
        border-radius:0;
        /*border-radius:@border-radius-base;*/
        text-align:right;
        border: @marqueeBorders;
        height:@marquee-height - 2px;
        min-width:80px;
        width: auto;
    
        p {
            padding: 2px 3px;
            white-space:nowrap;
            color: @marqueeTextColor !important;
        }

        .lang-scroller {
            cursor:pointer;
            position:absolute;
            z-index:9988;
            padding:0;
            right:1px;
            text-align:right;
            height:@marquee-height - 4px;
            width:73px;
        
            p {
                padding:3px; 
                border:solid 1px @default-border-color-darker; 
                position:relative; 
                display:block;
                border-width:0 0 1px 0;
                margin:0;
                white-space:nowrap;
                z-index:-1; //DVPAL20140827 - added to prevent blocking onclicks on FF
            }
        }
    }

    .lang-region-selector {
        min-width: 140px;
        /*border-radius:@border-radius-base;*/
        border-radius:0;
        display: none;
        position: absolute;
        background: @langSelectorBackground;
        color: @langSelectorTextColor;
        z-index: 9990;
        padding: 0;
        right: 0;
        text-align: right;
        .borderStyle-base();

        p {
            padding: 5px;
            margin: 0;

            span
            {
                padding: 0;
            }

            &.regtitle
            {
                margin: 0 0 5px 0;
                background: @langSelectorTitleBackground;
                color: @langSelectorTitleColor;
            }
        }

        .SelectedValue
        {
            padding: 0 5px 5px 0;
            background: @langSelectorSelectedBackground;

            a {
                color: @langSelectorSelectedColor !important;
                text-decoration: underline;
            }
        }

        .lang {
            margin:10px 0 10px 0;
            padding:0;
            position:relative;

            div {
                padding:0 5px 5px 0;
            }
        }

        .current-region {
            margin:10px 0 5px 0;
            padding:0;
            position:relative;

            div {
                padding:0 5px 5px 0;
            }
        }
    }
}

/* END langregionselector.less */