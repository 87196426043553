@import "../../bootstrap/variables.less";
@import "../../bootstrap/mixins.less";
@import "../variables.less";

/* START sitemap.less */
// following are used in the sitemap
td.smtop
{
    background-color: #cee3f7;

    a {
        font-size: 13px;
        font-weight: bold;
        color: #004280;
        position: relative;
        display: block;
        padding: 3px 2px 3px 2px;

        &:hover
        {
            background-color: @background-default;
        }
    }

}

td.smitem
{
    background-color: @background-lightest;

    a {
        font-size: 11px;
        color: #537caa;
        position: relative;
        display: block;
        padding: 2px 2px 2px 2px;
        
        &:hover
        {
            background-color: @background-default;
            color: #004280;
        }
    }
}


td.smitemtwo
{
    background-color: @background-lightest;

    a {
        font-size: 11px;
        color: #537caa;
        position: relative;
        display: block;
        padding: 2px 2px 2px 22px;

        &:HOVER
        {
            background-color: @background-default;
            color: #004280;
        }
    }
}

td.smitemthree
{
    background-color: @background-lightest;

    a{
        font-size: 11px;
        color: #537caa;
        position: relative;
        display: block;
        padding: 2px 2px 2px 42px;

        &:hover
        {
            background-color: #fff;
            color: #004280;
        }
    }
}

td.smsecond
{
    background-color: #e3eef8;

    a {
        font-size: 12px;
        font-weight: bold;
        color: #004280;
        position: relative;
        display: block;
        padding: 3px 2px 3px 22px;

        &:hover
        {
    background-color: @background-default;
            color: #004280;
        }
    }
}

td.smthird
{
    background-color: #edf3f8;

    a {
        font-size: 12px;
        font-weight: bold;
        color: #004280;
        position: relative;
        display: block;
        padding: 3px 2px 3px 42px;

        &:hover
        {
    background-color: @background-default;
            color: #004280;
        }
    }
}

/* END sitemap.less */