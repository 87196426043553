@import "../../bootstrap/variables.less";
@import "../variables.less";

/* START menus.less */
// ######## START OF CSSFriendly Adapter styles #######
//----------------------------------------------------------

//DVPDS - some structural changes for LESS
ul.Menu {
    position:relative;
    li {
	    position:relative;
	    list-style:none;
	    float:left;
	    height:1%;// IE6 for 7
        min-height:34px;

        a,
        span {
	        display:block;
	        text-decoration:none;
	        cursor:pointer;
	        height:1%;// IE6 for 7
        }
    }
}

ul.Menu, 
ul.Menu ul {
	margin:0;
	padding:0;
	display:block;
}

ul.Menu ul {
    @media (min-width: @screen-md-min) {
        position:absolute;
        visibility:hidden;
    }
}

// Add more rules here if your menus have more than three (3) tiers
ul.Menu li:hover ul ul,
ul.Menu li.Menu-Hover ul ul,
ul.Menu li:hover ul ul ul,
ul.Menu li.Menu-Hover ul ul ul,
ul.Menu li:hover ul ul ul ul,
ul.Menu li.Menu-Hover ul ul ul ul {
    visibility:hidden;
        @media (max-width: @screen-sm-max) {
        visibility:visible;
        background: none;
        display: block;
    }
}

// Add more rules here if your menus have more than three (3) tiers
ul.Menu li:hover ul,
ul.Menu li li:hover ul,
ul.Menu li li li:hover ul,
ul.Menu li:focus ul,
ul.Menu li li:focus ul,
ul.Menu li li li:focus ul,
ul.Menu li.Menu-Hover ul,
ul.Menu li li.Menu-Hover ul,
ul.Menu li li li.Menu-Hover ul,
ul.Menu li li li li:hover ul,
ul.Menu li li li li:focus ul,
ul.Menu li li li li.Menu-Hover ul,
ul.Menu li li li li li:hover ul,
ul.Menu li li li li li:focus ul,
ul.Menu li li li li li.Menu-Hover ul {
    visibility:visible;

    @media (max-width: @screen-sm-max) {
        background: none;
        display: block;
    }
}

.Menu-Vertical ul.Menu li {width:100%;}


/*DVPAL20150603 - div for create account message - START*/
div.createAcctText{
      text-align: center;
  font-size: 12px;
  width: 100%;
  line-height: 1;
  font-weight: 600;
  margin-top: 10px;
}
/*DVPAL20150603 - div for create account message - END*/

/* END menus.less */