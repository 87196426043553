@import "../../bootstrap/variables.less";
@import "../../bootstrap/mixins.less";
@import "../../bootstrap/buttons.less";
@import "../variables.less";

// Control: AccountMenu.ascx
//-----------------------
// Structure:
//-----------------------
// AccountMenuContainer    // Container/Wrapper
//     AccountMenu         // Contains The list, username and login/logout button
//         & > ul          // The menu list
//             & > li      // The item
//-----------------------
// Revised as of 7.2
// Revision notes: We are no longer setting this heavily wrapped inside the right column anymore so we will remove most of the old format



/* START accountmenu.less */
#AccountMenuContainer {
    text-align:left;
    padding: 10px;
    //min-width: 180px; // related white-space settings for "a"

    // "Account" title
    h1 {
        font-size: 1.1em;
        font-weight: bold;
        margin: 0 0 5px 0;
        //border-bottom: 1px solid @brand-primary;  // DVPDS turned off the underline
        width:150px; /*DVPDQ20140513-Change the div width to let the text in one line*/
        @media (max-width: @screen-sm-min) {
          margin: 1px 0 5px 0;
         }
    }

    #AccountMenu {
        #WelcomeLink {
            .btn;
            .btn-link;
            text-decoration: none !important;
            font-weight: bold;

            &:hover {
                color: @themeColor-dark;
            }
        }

        // using a class here instead of ID due to conflict of naming duplicate IDs in VB?
        .btn-login {
            .btn-block;
        }

        // keeping this separate from wrapping AccountMenuList and making the overrides more complicated
        #AccountMenuPanel {

        }

        #AccountMenuList {
            margin: 10px 0 -10px;
            list-style: none;
            border-top: 1px solid @themeColor;

            & > li {
                margin-left: -40px;

                a {
                    .transition(all .2s ease-in-out);
                    display: block;
                    padding: 5px 10px;
                    width: 100%;

                    // This is a per-use basis as various languages may have a huge shift in this 
                    white-space: nowrap;
                    
                    text-decoration: none !important;
                }

                &:hover {
                    a {
                        background-color: @themeColor-dark;
                        color: @color-white;
                    }
                }
            }
        }
    }
}


// TODO: Test for multiple user types
// - Sales person has two buttons

//---------------------------------
// TODO: Figure where this belongs and move it or remove it!
// DVPDS - No references exist to this entry
//div#accountBar {
//	padding:0;
//	z-index:8100;
//	margin-bottom:7px;
//}

/* END accountmenu.less */