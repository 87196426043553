@import "variables.less";
@import "../bootstrap/variables.less";
@import "../bootstrap/forms.less";
@import "../bootstrap/buttons.less";



/* START forms.less */

//=====================================
//
// Custom Submission form styles
//
//=====================================
td.subsubheader, div.subsubheader
{
    font-size: @customerFormsubsubHeaderFontSize;
    font-weight: @customerFormsubsubHeaderFontWeight;
    padding: @customerFormsubsubHeaderPadding;
    background: @customerFormsubsubHeaderBackground;
    color: @customerFormsubsubHeaderColor;
}

tr.nrow, div.nrow
{
    td, div
    {
        /*background: @customerFormNRowBackground;*/
        color: @customerFormNRowColor;
        padding: @customerFormNRowPadding;

        input.nrow
        {
            border: solid 1px @gray-light;
        }
    }
}


td.divrow div, div.divrow div
{
    width: 100%;
    height: 1px;
    border: 0;
    background-color: #eae7db;
    margin: 3px 0 3px 0;
}


tr.altrow, div.altrow 
{
    td, div
    {
        /*background: @customerFormAltRowBackground;*/
        color: @customerFormAltRowColor;
        padding: @customerFormAltRowPadding;

        input.altrow
        {
            border: solid 1px @gray-light;
        }
    }
}


tr.altrowtext td, div.altrowtext div
{
    /*background: @customerFormAltRowBackground;*/
    color: @customerFormAltRowColor;
    padding: @customerFormAltRowPadding;
}


td.divrow2 div, div.divrow2 div
{
    width: 100%;
    height: 1px;
    border: 0;
    background-color: #eae7db;
    margin: 3px 0 3px 0;
}

div.div-table input[type="text"]{
    margin:0 auto;
}

input[type="text"] {
    height: 34px;
    padding:7px; // DVPAL210140602 - added padding for input text fields
}

/*DVPAL20140528 - added for add to cart text field*/
div.form-add-to-cart input {
    @media (max-width: @screen-sm-max) {
        text-align: right; 
           }
}


/*DVPJL20140902 - ntn72013 - site survey - start*/
.formSurvey
{
    @media (min-width: @screen-md-min)
    {
        div.formInstruction
        {
            width: 25%;
            display: inline-block;
            vertical-align: top;
        }
        
        div.formInstruction + div
        {
            width: 75%;
            display: inline-block;
            vertical-align: top;
        }
    }

    @media (max-width: @screen-sm-max)
    {
        td {
            display: block !important;
        }
    }

    > div {
        margin: 5px 0px;
    }

    .captchadiv
    {
        text-align: center;
    }

    .captchainput input.form-control
    {
        margin: 0 auto !important;
    }

    div.formInstruction + div > select,
    div.formInstruction + div > input,
    div.formInstruction + div > textarea {
            .form-control !important;
    }
    
    > div:last-child > div {
        text-align: center; // Center submit button
    }

    input.btn.btn-submit {
        .btn-primary !important;
    }
}
/*DVPJL20140902 - ntn72013 - site survey - end*/

/*DVPJL20140902 - ntn72013 - add a review - start*/
.formReview {
    div.formInstruction {
            /*width: 50%;*/
            width:100%;
            display: inline-block;
            vertical-align: middle;
    }
        
    div.formInstruction + div {
            /*width: 50%;*/
            width:100%;
            display: inline-block;
            vertical-align: middle;
    }
   
    > div {
        margin: 5px 0px;
    }

    .captchadiv
    {
        text-align: center;
    }

    .captchainput input.form-control
    {
        margin: 0 auto !important;
        width: 100%;
    }
       
    div.formInstruction + div > select,
    div.formInstruction + div > input,
    div.formInstruction + div > textarea {
        .form-control !important;
    }

    input.btn.btn-submit {
        .btn-primary !important;
    }
}
/*DVPJL20140902 - ntn72013 - add a review - end*/
/*DVPLW20141009 Suggest Search*/
.simplesearch{}

.ui-autocomplete{
    z-index:9000 !important;

}
/*DVPLW20141009 Suggest Search END*/

/*DVPAL20141009 - ajax calendar date input field - START*/
.ajaxdateinput{
    @media (max-width: @screen-sm-max){
        width:100%;
    }
}

/*DVPAL20141009 - ajax calendar date input field - END*/

/*DVPAL20150202 - Reports section - START*/
input.reportsinput, select.reportsinput{
    width:150px;
    @media (max-width: @screen-sm-max){
        width:100%;
    }
}

div.calendardiv{
    display:inline-block;
    vertical-align: top;
    padding-right:20px;
    margin:0 auto;
    @media (max-width:@screen-sm-max){
        padding:0;
    }
}

a.aspxlinks.btn.btn-primary{
    padding:0 10px;
}

div.reportsearchdiv{
    width:500px;
    padding:15px;
    margin:0 auto;
    @media (max-width:@screen-xs-max){
        width:100%;
    }
}

/*DVPAL20150202 - Reports section - END*/

.breakword(){
    -ms-word-break:break-word;
    -ms-word-wrap:break-word;
    word-wrap:break-word;
    word-break:break-word;
}


//DVPAL20150604 - added for autofill background color on chrome
input:-webkit-autofill {
-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), inset 0 0 0 50px white;
}

/* END forms.less */