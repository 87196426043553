@import "../variables.less";
@import "../../bootstrap/variables.less";
@import "../../bootstrap/mixins.less";

/* START checkout.less */

.checkoutHeader {
    margin-top: 0;
    border-bottom: @checkoutHeaderBorderThickness @checkoutHeaderBorderStyle @checkoutHeaderBorderColor;
    padding-left: 0;
    @media (max-width: @screen-sm-max) 
    {
        border:none;
        padding-left: 0px;
    }
    @media (max-width: @screen-xs-max) 
    {
        padding-left: 0px;
    }
    height: auto;
    display: block;

    li {
        background: @checkoutHeaderBackgroundInactive;
        padding: 2px 5px 3px;
        width: 160px;
        height: @checkoutHeaderHeight;
        border-radius: @border-radius-large @border-radius-large 0 0;
        vertical-align: bottom;
        display: inline-block;

        @media (max-width: @screen-sm-max) 
        {
            display:none;
        }

        &.onepage {
            width: 300px;
            padding: 10px 5px 3px;
            @media (max-width: @screen-sm-max) 
                {
                    width: 215px;
                }
            @media (max-width: @screen-xs-max) 
                {
                    width: 48%;
                }
            .title-container {width: 100%;}
        }

        &.onepage-selected {
            background: @checkoutHeaderBackgroundActive;
            color: @color-white;
            width: 300px;
            @media (max-width: @screen-sm-max) 
                {
                    width: 215px;
                }
            @media (max-width: @screen-xs-max) 
                {
                    width: 48%;
                }
            .title-container {width: 100%;}/*DVPEP20140530 - NTN7.2 - Changed to 100% from 200px;*/
            span.badge {
                background: none; // @checkoutHeaderBadgeBackgroundActive;
                color: @checkoutHeaderBadgeColorInactive;
            }
        }

        &.selected {
            background: @checkoutHeaderBackgroundActive;
            color: @color-white;
            
            @media (max-width: @screen-sm-max) 
            {
                width: 100%;
                display:inline-block;
            }
            span.badge {
                background: none; // @checkoutHeaderBadgeBackgroundActive;
                color: @checkoutHeaderBadgeColorInactive;
            }
        }



        span {
            display: block;
            margin-right: 5px;

            &.badge {
                text-align: center;
                background: none; // @checkoutHeaderBadgeBackgroundInactive;
                color: @color-grey-dark; // @checkoutHeaderBadgeColorInactive;
                font-size: 18px;
                line-height: 25px;
            }
        }

        div.badge-container {
            display: inline-block;
            height: 100%;
            float: left;
            width: 30px;
            margin-right: 5px;
        }

        div.title-container {
            display: inline-block;
            float: left;
            width: 110px;
            font-size: 11px;
            line-height:1.4;
        }
    }
}

/* for each collapsible section */
div.section-wrapper {
    border: 2px solid @checkoutSectionBackground;
    border-radius: 5px 5px 0 0;
    background-color: @background-default;
    margin: 1px 0 15px 0;

    .section-header {
        background-color: @checkoutSectionBackground;
        padding: 5px 10px;
    }

    .section-body {
    background-color: @background-default;
        padding: 10px;
    }

    .section-body-comment {
    background-color: @background-default;
        padding: 10px;
        
        .form-control {
        height:120px;
        }
    }

    .shipping_payment_container {
        background-color: @background-default;
        padding: 10px;
        position:relative; //DVPEP20141030 - NTN7.2 - Added so spinner can be positioned absolute with shipping_payment_container
        /*DVPEP20141030 - NTN7.2 - Below classes added to display progress indicator when shipping method and options are changed. - BEGIN*/
        div.shipping-spinner-parent{
            position: absolute;
            text-align: center;
            vertical-align: middle;
            z-index: 99999;
            overflow: auto;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            opacity: .5; 
            background-color:@background-default;
            div.shipping-spinner-image{
                display: inline-block;
                width: 50px;    
                height: 50px;
                margin: auto;
                position: absolute;
                top: 0; left: 0; bottom: 0; right: 0;
            }
        }
        /*DVPEP20141030 - NTN7.2 - Below classes added to display progress indicator when shipping method and options are changed. - END*/
    }
}
div[toggle-group$="information-group"] {
    margin-bottom: 20px;
}

div.coupon-info-wrapper{
    padding:5px;
    width:100%;
    .couponitemlist{
          width: 100%;
            white-space: normal;
    }
}

div.coupon-wrapper, div.coupon-info-wrapper {
    //width:90%; - DVPDS - removed width
    /*float:left;*/ //DVPDS - this keeps the width dynamic based on the child elements /*DVPEP20140530 - NTN7.2 - Removed float.*/
    white-space:nowrap;
    margin:10px 0 10px 0; // DVPDS - added to space out this control without having to use <br/>s
    background-color:@background-lightest; // DVPDS - moved from control (used to be an inline style)
    border:1px solid @default-border-color; 
    display:inline-block; /*DVPEP20140530 - NTN7.2 - Set display to inline block.*/

    @media (max-width :@screen-sm-max){
        width:100%; //DVPAL20140612 - responsive behaviour for coupon box
    }
}

// DVPDS - moved from coupon.less (no reason to have just a single LESS for one entry)
.couponcode {
	padding:4px 2px 4px 2px;
}

// DVPAL20140612 - added to give coupon error messages a bit of spacing
div.couponError{
padding: 5px 0px 0px 5px;
}

// DVPDS - added to keep the elements inline
.coupon-entry {
    div {
        float: left;
        padding: 5px;
        line-height: 32px;
    }

    @media (max-width: @screen-sm-max){
        width:100%;
    }
    // DVPAL20140612 - coupon button and field responsive
    .couponButton, .couponButton, .couponField, .couponField, .couponLabel, .couponLabel {
        @media (max-width: @screen-sm-max) {
            text-align:center; 
            width:100%;
        }

        .couponLabel {
            @media (max-width: @screen-sm-max) {
                line-height:15px;
            }
        }
    }
}

/* for the order totals on the checkout page (totalsummary) */
div.order-total  {
    margin:0 0 0 5px;
    div {
       float: left;
       margin:0;
    }

    .items-label {
        padding-right:2px;
    }
    .items-value {
        font-weight:bold;
    }
    .total-label {
        padding:0 2px 0 10px;
    }
    .total-value {
        font-weight:bold;
    }
}

/* for the order total section (ordertotal) on the checkout page */
.ordertotal {
    width: 100%;

    tr td {
        text-align:right;
        white-space: nowrap;
    }
    tr td.shader {
    border-top: 1px solid @default-border-color;
    padding:5px;
    margin-top:4px;
    }
}



/*for the ship to or pickup option */
div.shipment-or-pickup {
    width:100%; //DVPAL20141009 added to set width
    clear: both;
    float:right;
    margin: 5px 0 20px 0;
    @media (max-width: @screen-sm-max){
        float:none;
    }

    div.shipmentcontainer {
        float: right;
        @media (max-width: @screen-sm-max){
            float:none;
        }
        div.shippick {
            margin-right: 10px;

            @media (max-width: @screen-sm-max) {
                width:100%;
            }

            table {
                @media (max-width: @screen-sm-max) {
                    width:100%;
                }

                tr td {
                    width: 50%;
                }
            }
        }

        div.selectdd {
            @media (max-width: @screen-sm-max) {
                margin-top:10px; width:100%;
            }
        }
    }

    div div {
        float: left;
    }
}

div.shipment-or-pickup-search {
    float: left;
    margin: 5px 0 20px 0;
    @media (max-width: @screen-md-max) {
        margin: 5px 0 5px 0;
    }
    @media (max-width: @screen-sm-max){
        width:100%;
    }
    div {
        float:left;
        height: 32px;
        line-height: 32px;
        padding:0 5px 0 0;
    }
}

div.ocp-input{
    @media (max-width:@screen-sm-max){
        margin-bottom:10px;
        width:100%;
    }
}

div.shipment-or-pickup-search-keyword, div.shipment-or-pickup-search-store {
    float: left;
    margin: 5px 0 20px 0;

    .opc-btn{
        @media (max-width:@screen-sm-max){
            width:100%;
            //margin-top:10px;
        }
    }
    div {
        float:left;
        height: 32px;
        line-height: 32px;
        padding:0 5px 0 0;
    }
    @media (max-width:@screen-sm-max){
        width:100%;
    }
}

/* used for the payment options section */
div.payment-options {
    .options {
        float:left;
        margin-right:50px;
    }
    .credit-card {
        float:left;
        width:100%; /*DVPEP20150604 - NTN7.2 - Added width 100%*/
        padding:15px; /*DVPEP20150604 - NTN7.2 - Added padding to container so input boxes will not overflow border.*/
    }
    /*DVPEP20140604 - NTN7.2 - On small viewports remove float. - BEGIN */
    @media (max-width: @screen-sm-max) {
        .options
        {
            float:none;
        }
        .credit-card
        {
            float:none;
        }
    }
    /*DVPEP20140604 - NTN7.2 - On small viewports remove float. - END */
}

/* for the credit card in the standard checkout */
div.credit-card-select {
    > div{
        display:inline-block;
        width:100%;
    }
    h3 {
        font-weight:bold;
    }

    .credit-card-type, .credit-card-holder-name, .credit-card-cvv-number {
        div {
            width:38%;
            float: left;
            line-height: 32px;
            vertical-align: middle;
            margin: 2px 0 0 0;
            white-space:nowrap;

            @media (max-width: @screen-sm-max){
                width:100% !important;
            }
        }
        div.input{
            width:60%;
        }
    }

    .credit-card-number, .credit-card-cvv-number {
        div {
            width:38%;
            float: left;
            line-height: 32px;
            vertical-align: middle;
            margin: 2px 0 0 0;
            white-space:nowrap;
            @media (max-width: @screen-sm-max){
                width:100% !important;
            }
        }
        // DVPDS - this applies to the second div under ccnumber
        div:nth-child(2) {
            p {
                margin:1px 0 0 0;
                span {
                    font-size:11px;
                }
            }
        }
    }
    
    .credit-card-expiration {
        div.expiration-month-year {
            width:60%;
            float: left;
            line-height: 32px;
            vertical-align: middle;
            white-space:nowrap;
            @media (max-width: @screen-sm-max){
                width:100% !important;
            }
            div {
                float:left;
                padding: 0 5px 0 0;
            }
        }
        div.expiration {
            width:38%;
            float: left;
            line-height: 32px;
            vertical-align: middle;
            white-space:nowrap;
            @media (max-width: @screen-sm-max){
                width:100% !important;
            }
        }
    }
}

div.credit-card-expiration{
    .form-group{
        padding-left:0;
    }
}

/*DVPEP20140604 - NTN7.2 - Asterisk placement for credit card fields. - BEGIN*/
/*div.credit-card-type.form-group div:first-of-type:before{
    content:"\f069" !important; //DVPAL20140612 - changed to use font awesome
    font-family:FontAwesome !important; 
    font-weight:bold !important;
    white-space:nowrap !important;
    font-size:7px!important;
    color:@state-danger-text !important;
    position:absolute !important;
    top:20px !important;
    left:20px !important;
}*/

div.credit-card-holder-name div:first-of-type:before
{
    content: attr(data-required) !important;
    font-weight:bold !important;
    white-space:nowrap !important;
    color:@state-danger-text !important;
    vertical-align:middle !important;
    position:absolute;
    top:13px;
    left:-7px;
}
div.credit-card-holder-name div:first-of-type{
    position:relative;
}

div.credit-card-number div:first-of-type{
    position:relative;
}

/*div.credit-card-number div:first-of-type:before
{
    content: attr(data-required) !important;
    font-weight:bold !important;
    white-space:nowrap !important;
    color:@state-danger-text !important;
    vertical-align:middle !important;
    position:absolute;
    top:13px;
    left:-7px;
}*/

/*div.credit-card-expiration div.row div:before
{
    content:"\f069" !important; //DVPAL20140612 - changed to use font awesome
    font-weight:bold !important;
    white-space:nowrap !important;
    color:@state-danger-text !important;
    font-size:7px!important;
    font-family:FontAwesome;
    position:absolute;
    top:0px;
    left:5px;
}*/
/*DVPEP20140604 - NTN7.2 - Asterisk placement for credit card fields. - END*/
/*DVPDQ20140922-NTN7 - Add CVV number-Begin*/
/*DVPAL20140616 - Asterisk placement for credit card fields (updated) - START*/
/*div.credit-card-cvv-number.form-group .asterisk, div.credit-card-holder-name.form-group .asterisk, div.credit-card-number.form-group .asterisk{
    position:relative;
    top:-32px;
    vertical-align:top;
    left:-17px;
}*/

/* for the credit card in the one page checkout */




/*DVPDQ20140922-NTN7 - Add CVV number-end*/


div.creditcardselect {
    div {
        margin: 2px 0 0 0;
    }
        div div {
            float: left;
            line-height: 32px;
            vertical-align: middle;
        }
    div.expiration-header{
        line-height: 28px;
    }

    div.credit-card-expiration div div {
        padding: 0 5px 0 0;
    }
}

div.shipto-calendar {
    div {
        float:left;
        line-height:32px;
        vertical-align:middle;
        margin:4px 5px 0 0;
    }
}

// DVPDS - added for the shipment method in the payment section of the checkout
div.shipmentmethod {
    float:left;
    margin:5px 0 0 0;

    /*div { // DVPAL20140722 - commented out since unused
        float:left;
        margin:0 5px 0 0;
        line-height:32px;
    }*/

    @media (max-width: @screen-sm-max){
        width:100%;
    }

    div.sm-container{
        display:inline-block;
        float: left;
        width:200px;

        @media (max-width: @screen-sm-max){
            width:100%;
            margin-bottom: 15px;

            div.input-group{
                width:100%;
                input{
                    width:75% !important;
                }
                a.btn{
                    width:25% !important;
                }
            }
        }
    }
}

/* for the PO number section */
div.ponumber div {
    float:left;
    line-height:32px;
    vertical-align:middle;
    margin:2px 5px 0 0;
}

/* DVPTP20130812 -- Added the toggle style to make radio look like a toggle button */
.radio-toggle {
    /*DVPAL20140616 - added transition effects and standardized colours*/
    -webkit-transition: all 0.3s ease-out;  /* Chrome 1-25, Safari 3.2+ */
    -moz-transition: all 0.3s ease-out;  /* Firefox 4-15 */
    -o-transition: all 0.3s ease-out;  /* Opera 10.50�12.00 */
    transition: all 0.3s ease-out;  /* Chrome 26, Firefox 16+, IE 10+, Opera 12.50+ */
	border: @simpleSearchBorder;
	background-color: @background-lighter;
	border-collapse: separate !important;
	border-radius: @border-radius-base;

	//width: 120px;
}
    .radio-toggle input[type="radio"]{
	    /*display: none;*/
        display:block;
        position:absolute;
        opacity:0;
    }

    .radio-toggle input:checked + label {
	    background: @background-light;
	    color: @color-black;
	    display: block;
    }

    .radio-toggle input:focus + label{
        outline:2px solid @themeColor;
    }

    .radio-toggle input:active + label{
        outline:none !important;
    }


    .radio-toggle label 
    {
        cursor: pointer;
        display: block;
        padding: 7px;
        color: @color-grey-darker;
        font-size: @font-size-small;
        white-space:nowrap;
        margin:0;
    }

    .radio-toggle label:hover
    {
        background: @default-border-color-lighter;
    }



div#addresslist {
	margin: 10px auto 0;
    border: solid 2px @default-border-color;
    background-color: @background-lightest;
    padding: 5px;
    width: 100%; //DVPAL20140711 - changed to 100%, let container determine size
    height: auto;
    vertical-align: middle;
    overflow: auto;
    z-index: 200;
    clear: both;
    text-align: left;

}

// DVPDS - no references exist to this - commented out below
// Use for country and state dropdowns on the Address control. The address control is used on the checkout page, for example.
//select.solidform {
//    width: 126px; // Taken from span2
//}

// Affects Ship/Billing control in Checkout.aspx
.checkout-info-table-wrapper {
    margin-top: 15px;
    width: 100%;

    & > div.checkout-info-table {

        div.saveaddressfuture{
            span, input{
                vertical-align:middle;
                margin-top:0;
            }
            
        }

        @media (max-width: @screen-md-max) {
                    width:99%;
        }

        display: inline-block;
        vertical-align: top;
        width: 49%;
        margin-bottom:15px; // helps keep the billing/shipping sections separate

        & div.address {
            width: 100%;            
        }

        // DVPDS - added to make the address section title more visible
        H3 {
            font-size:16px;
            font-weight:bold;
            color:@color-grey-dark;
            background-color:@background-lightest;
            padding:5px 0 5px 1px;
        }
    }

    & > div.checkout-info-table:first-child {
        margin-right: 1.5%;
    }
}


/*DVPDS20140501 - added for the address control during checkout */
div.address div.address-company, div.address-first-name, div.address-last-name, div.address-address-1, div.address-address-2, 
div.address-city, div.address-postal-code, div.address-phone, div.address-email, div.address-country, div.address-states {
    float: none;
    width:100%;
    clear:both;

    & div {
        float:left;
        width:48%;
        margin: 2px 0;
    }
}

// The address selection control for Ship/Pickup
div#address-select-control {
    margin: 10px 3px;
    text-align: right;
}

div#locationlist {
    //DVPAL20140829 - changed to be the same format as address list
    margin: 10px auto 0;
    border: solid 2px @default-border-color;
    background-color: @background-lightest;
    padding: 5px;
    width: 100%; //DVPAL20140711 - changed to 100%, let container determine size
    height: auto;
    vertical-align: middle;
    overflow: auto;
    z-index: 200;
    clear: both;
    text-align: left;

	/*position:relative;
	left:0;
	top:0;
	border:solid 2px @default-border-color;
	background-color:@background-lightest;
	padding:0;
	width:100%; // DVPAL20140711 - changed to 100% from 300px
	height:100px;
	vertical-align:middle;
	overflow:auto;
    z-index: 201; // DVPDS - moved from ascx (pulled inline style out of page)
    text-align: left; // DVPDS - moved from ascx (pulled inline style out of page)*/
}

// DVPDS - Added for the shipping/billing section on the standard checkout
div#shipBillAddress {
    position: relative; 
    top: 0px; 
    z-index: 100;
}

input.surchAddLink {
    white-space: normal; //DVPAL20140903 - added to enable word-wrap in locations box
	background-color:@background-lightest;
	border:0;
	color:navy;
    vertical-align:top;
	text-align:left;
}


//--------------------
// LIGHTBOX for VAT Checkout
//-------------------------
 .lightbox-overlay {
	 position: fixed; // take whole browser
	 top: 0;
	 left: 0;
	 background: rgba(0,0,0,0.65);
	 width: 100%;
	 height: 100%;
	 z-index: 10000;
 }
 
 .vat-message {
	position: absolute; // absolute to mainsection
	top: 60%;
	left: 380px;
	margin: 0 auto;
	width: 400px;
	height: auto;
	z-index: 10001;
	background-color:@background-default;
	padding: 10px;
	border-radius: 0px;
	border: 1px solid @color-black;
	box-shadow: 0 0 10px rgba(0,0,0,0.5);
 }


/* used on the payment page of the standard checkout */
div.paymentoptions {
    width: 100%;
    float: left;
    border: 2px solid @default-border-color-light;
	background-color:@background-default;
    padding:0;  
    margin:20px 0 20px 0;

    .paymentoptions-header{
        background-color: @background-lighter;
        padding: 5px;
        H3 {
            margin:0;
        }
    }
    
    .paymentmethod {
        float:left;
        margin:0;
        padding:10px;
        width:33%;
        @media (max-width: @screen-sm-max) 
        {
            width:100%;
        }
    }
    .paybycreditcard {
        float:left;
        padding:10px;
        width:66%;
        @media (max-width: @screen-sm-max) 
        {
            width:95%;
        }
    }
    
}

// DVPDS - moved from shopping cart LESS
div.checkoutsec {
    float: left;
    margin: 0 0 10px 0;

    div {
        float:left;
        line-height:28px;
        padding-right:3px;
    }
    div.ponumber {
        input {
        width:110px;
        }
    }
}


//DVPDS for the order comments
div.order-comments {
    width:100%;
    float:left;
}

/* standard checkout (also used on order history details) */
div.billingandshippingaddresses {
    //clear:both;
    font-size:0; /*DVPAL20140716 - added to prevent white space between child divs*/
    width:100%;
    display:inline-block;
    .borderStyle-base;
    
    .shipping{
        @media (max-width: @screen-sm-max){
            border-top:1px solid @default-border-color;
        }
    }

    div.billing, div.shipping {
        background-color:@background-default;
        
        width:50%;
        float:left;

        @media (max-width: @screen-sm-max){
            width:100%;
        }

        div.address-header {
            background-color:@checkoutSectionBackground;
            border-bottom:1px solid @default-border-color;
        }
    }

}


/* one page checkout */

div.bill-and-ship-addresses {
    margin-bottom:20px;
}

/* added to give bill-and-ship-addresses a height, otherwise, this div appears outside of the wrapper  */
div.bill-and-ship-addresses:after { content: " "; display: block; clear: both;} 

div.bill-and-ship-addresses div.billing {
    background-color:@background-default;
    float:left;
    width:47%;
    margin-right:10px;
    @media (max-width: @screen-xs-max)
        {
            width:98%;
        }

    @media (max-width: @screen-sm-max) 
        {
            width:98%;
        }
}

div.bill-and-ship-addresses div.shipping {
    background-color:@background-default;
    float:left;
    width:47%;
    @media (max-width: @screen-xs-max)
        {
            width:98%;
        }

    @media (max-width: @screen-sm-max) 
        {
            width:98%;
        }
}



div.shipping-payment-header {
    clear:both;
}

span.addressTitle {
    font-size:18px;
    font-weight:bold;
}


/*DVPAL20140716 - standardized container and label class form all rows*/ 
/*div.DisplayAddress-company, div.DisplayAddress-first-name, div.DisplayAddress-last-name,
div.DisplayAddress-address1, div.DisplayAddress-address2, div.DisplayAddress-city, div.DisplayAddress-country,
div.DisplayAddress-state, div.DisplayAddress-postal, div.DisplayAddress-phone, div.DisplayAddress-email,
div.DisplayAddress-save-for-future*/  



div.DisplayAddress .da-container
{
    /*& div {
    float:left;
    width:59%;
    margin: 1px 1px 0 0;
    padding:2px;
    @media (max-width: @screen-sm-max){ //DVPAL20140708 - added for responsive behaviour
        margin-left:10px;
        width:100%;
        }
    }*/
    font-size:@font-size-small;
    display:inline-block;
    width:100%;

    @media (max-width: @screen-sm-max){
        &:nth-child(odd){
            background:@background-lightest;
        }
        &:nth-child(even){
            background:@background-light;

        }
    }

    .da-label, .da-value{
        display:inline-block;
        padding:2px 2px 2px 10px;
        float:left;

        @media (max-width: @screen-sm-max){ //DVPAL20140708 - added for responsive behaviour
            margin:0;
            padding:2px;
            width:100% !important;
            background:none !important; //DVPAL20140716 - in XS/SM viewport, the background is determined by da-container instead
        }
    }
    .da-label{
        width:30%;
        background:@background-lighter;
    }
    .da-value{
        width:70%;
        background:@background-lightest;


        @media (max-width:@screen-sm-max){
           padding-left:15px;
        }
    }
}

div.ccdetails {
    clear:both;

    div.paymentinfoelement{
        div.tyrow{
            width:100%;
            margin:5px;
        }
        div.tyvalue{
            margin-left:5px;
        }
        div.tylabel, div.tyvalue{
            display:inline-block;
        }
    }

}


// DVPTP20130812 -- Added styles for the chevron toggles
.icon-chevron-up 
{
    background: url(../images/chevrons.png) 0 -18px no-repeat;
}
.icon-chevron-down 
{
    background: url(../images/chevrons.png) 0 0 no-repeat;
}

.expand-toggle  //DVPAL2014702 - added transitions and color changes
{
    padding: 3px;
    border-radius: @border-radius-base;
    .transition(all .15s ease-in-out); 
    .borderStyle-base;
    background: @background-lightest;
    cursor:pointer;
    text-decoration:none;
}
.expand-toggle:hover 
{
    background: @background-light;
    border: 1px solid @default-border-color-dark;

}

.expand-toggle:active 
{
    border: 1px solid @default-border-color-darker;
    background:@background-grey;
    outline:0 !important;
}
    
.expand-toggle span 
{
    display: inline-block;
    *display: inline;
    zoom: 1;
    width: 18px;
    height: 18px;
}

// DVPTP20130812 -- Added styles for the chevron toggles - -END

//DVPAL20140616 - chevron toggles on comments (uses font awesome)

textarea.commentBox {
    display:block;

    @media (max-width: @screen-sm-max){
        display:none;
    }
}

.comment-toggle{
    display:none;
    cursor:pointer;
    margin-left:5px;

    @media (max-width: @screen-sm-max){
        display:inline-block;
    }
}

/* used to center the submit button during checkout */
div.submit {
    text-align:center;
    width:100%;
    input
        {
        @media (max-width:@screen-xs-max){
            width:100%;
            margin-top:10px;
        }
    }
}

// DVPAL20140610 - Thank You page styles

.thankyouText{
    font-size:20px;
    font-weight:bold;
}

div.shipping_payment{
    div.shipment-method-dd{
        display:inline-block;
        width:auto;
        @media (max-width:@screen-sm-max){
            width:100%;
        }
    }
}

//DVPAL20150716 - WCAG order confirmation checkbox

.chkContainer{
    text-align:center;
    label, input{
        vertical-align:middle;
        margin:0;
    }
    label{
        font-weight:600;
    }
}

/* END checkout.less */