@import "../../bootstrap/variables.less";
@import "../variables.less";
/* START productimagelist.less */
// used on the Product_ImageList control only


/*DVPJL20140425*/
.primaryimage {
    display: block;
    width: 100%;
    //DVPAL20150508 - added to center font awesome icons that appear when image is not found
    text-align:center;

    #image_enlarge_300 {
        #enlarge {
            text-align:center;
        }
    }
}

div#large_image {
	// TODO: remove this in layout.less
	width: auto;
    // DVPDS - START - added these from the large_image section below that was commented out
    display:none;
    position:absolute;
 	left:173px;
 	top:116px;
 	visibility:hidden;
 	padding:0;
 	vertical-align:middle;
    // DVPDS - END

	.enlargedImage {
		background: @background-default;
		text-align: center;
		padding: 10px 0 0 0;
		border-radius: 10px;
		border: 1px solid @color-black;
        min-width:440px;
		
		-webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
		-moz-box-shadow:    0px 0px 10px 0px rgba(0, 0, 0, 0.75);
		box-shadow:         0px 0px 10px 0px rgba(0, 0, 0, 0.75);

		& > a {
			display: block;
			
			#image_enlarge_300_image {
				border-radius: 8px 8px 0 0;
			}
			
			&.others {
			padding: 10px;
				&:hover {
					color: @color-black;
				}
			}
		}
	}
}


#enlarge a.others:hover {
	color:@color-grey-darker;
}

.zoomerimg {
	.borderStyle-base;
}



// DVPDS - Duplicate, exists in the productlightbox.less file
//div#dhtmlgoodies_slideshow {
//	width: 100%;
//}


// DVPDS - replaced with the section above
//div#large_image {
//	position:absolute;
//	left:173px;
//	top:116px;
//	visibility:hidden;
//	padding:0;
//	width:400px;  // set the width here to cover hidden My Favorites dropdown
//	height:350px; // set the height here to cover hidden My Favorites dropdown
//	vertical-align:middle;
//
//    a {
//        text-decoration: none;
//    }
//}

// used in the product_imagelist control
//DVPDS - legacy, replaced with another style
//table.enlargedImage {
//	background-color:#fff;
//	border:solid 1px #000;
//	-moz-border-radius:10px;
//	-webkit-border-radius:10px;
//	border-radius:10px;
//	padding:10px;
//	width:100%;
//	height:100%;
//}

/* END productimagelist.less */