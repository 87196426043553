@import "../bootstrap/variables.less";
@import "../bootstrap/grid.less";
@import "../bootstrap/mixins.less";
@import "../bootstrap/buttons.less";
@import "../bootstrap/utilities.less";
@import "../bootstrap/tables.less";
@import "../base/controls/misc.less";
@import "variables.less";


/* START layout.less */
//=====================================================
// - - - BODY variants - START - - -
// sets basic style on the body */
html{
    /*background:@footerBackground;*/
    /*background:@body-bg;*/
}

form{
    background:@body-bg;
}

body.default
{
    background:@footerBackground;
    /*background: @body-bg;*/
    /*background: url(../images/full_background_sample.jpg) 50% top repeat-y fixed;*/
}
// use for basic style on Printer Friendly pages */
body.clear
{
    margin: 3px 3px 10px 4px;
}
// used for iframes
body.defaultframe
{
    margin: 10px 0;
    background-color: @background-default;
    overflow-y: hidden;
    padding:5px;
}

// used for the confirmation email body
body.email td
{
    color: @color-grey-darker;
}
// - - - BODY variants - END - - -

 
.header-banner {
    /*background: url(../images/background_sample.jpg) 50% top no-repeat fixed;*/
    height: 150px;
    width: 100%;
}



/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */
@media (max-width: @screen-xs-max) {
    .ntn-navbar {
        float: right;
        margin-right: 20px;
        margin-top: 0px;

        #langselector {
            display: inline-block;
            margin-bottom: -10px;
            width: 40px;

            .marquee,
            marquee {
                min-width: 40px;
                width: 40px;
            }
        }

        li,
        li > a {
            display: inline;
        }
    }

    .FeatItemTable {
        .FeatItemColumn {
             .FeatItemBlock {
                 margin: 0 0 -1px 0;
                 min-height: 120px;

                 .FeatItemBlockImage {
                     width: 110px;
                     height: 110px;
                     display: inline-block;
                     float: left;
                     margin-right: 1%;
                 }

                 .FeatItemInfoBlock {
                     display: inline-block;
                     text-align: left;
                     min-width: 56%;
                 }
             }
        }
    }
}

@media (max-width: 991px) {
    .navbar-default .navbar-toggle {

    }
}


@media (max-width: @screen-sm-max){
    #Navigation{
        width:100% !important;
    }
}


/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .header-banner {
        height: 200px;
    }

    #Header {
        #Navigation {

                @media (min-width: @screen-md-min) {
                    display: block !important;
     
                }
                @media (max-width: @screen-md-max){
                    width:@footerMediumSize;
                }
                @media (max-width: @screen-sm-max){
                    width:100%;
                }
               
            #category {
                width: 100%;

                border-top: 0px solid @color-white; /*DVPDQ20140512border*/

                a.categorytext {
                    font-size: 14px;
                    font-weight: 600;
                    height: 34px;
                    line-height: 20px;
                    text-align: left !important;
                    padding: 7px 10px;

                    background: @themeColor-light;//DVPDS - old blue: #3399cc

                    &:focus{
                        background:@themeColor-dark;
                    }
                }

                @media (min-width: @screen-md-min) {
                    width: 17%;
                }
            }

            #TopCategory {
                @media (min-width: @screen-md-min) {
                    width: 83%;
                }
                .navmenu {
                    .Menu {
                        & > li {
                            display: inline;
                        }
                    }
                }
            }
        }
    }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .header-banner {
        height: 300px;
    }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}


//=====================================================
// Defines the top level DIV
#Container
{
    .container; // inherit

    text-align: left;
    background:@mainSectionBackground;
    //box-shadow: @topTopAreaBoxShadow; //DVPAL20140729 - removed to disable drop shadow
    //position:absolute; /*DVPDQ20140513-add it to avoid the whole thing pushing down*/
}


#Header
{
    background:@themeColor;
    position: relative;
    height: auto;
    width: auto;
}

    div#TopSection
    {
        position: relative;
        left: 0;
        top: 0;
        width: 100%;
        height: @topSectionHeight;
        background: @topSectionBackground;
        border: 0;
        margin-bottom: 0;
        text-align: left;
    }

    div#Navigation {
        z-index: 200;
        width:@layout-container-width;
        /*width: @navbarWidth;*/
        margin: @navbarMargins;
       // border: @navbarBorders;
        //border-width:0 0 1px 0;
           @media (max-width: @screen-sm-max) {
                    display: none;
                }
          
       // #gradient > .vertical(@categoryBackgroundHighlightColor, @categoryBackgroundBaseColor); //DVPAL20140602 removed gradient background
       background-color: @themeColor;
        .clearfix;
    }

// Defines 'left product info'  section within the LeftSection DIV
#LeftContentInfo {
    float: none !important; //DVPJL20140514 - Fix Parametric_Item layout.
    @media (min-width: @screen-sm-min) {
        padding:10px 20px 30px 0px
    }
    //background-color: #f4f7f8; - no color should be used for the background
    
    @media (max-width: @screen-xs-max) {
        width:100%;
        float: none;
}
}

// Defines 'left category' section within the LeftSection DIV
#LeftCategories
{
    margin-bottom:10px;
}

// Defines 'recently viewed' section within the LeftSection DIV
div#RecentlyViewed
{
    margin-bottom: 10px;
}

// used for container for content in the left section
div#LeftContent
{
    margin-bottom: 10px;
}

// Defines 'Product Compare' section within the LeftSection DIV
div#ProductCompareList
{
    //padding-left: 5px;
    margin-bottom: 10px;
}

// Defines 'left cart summary' section within the LeftSection DIV
div#LeftCartSummary,
div.LeftCartSummary
{
    //padding-left: 5px;
    margin-bottom: 10px;
}

/*DVPEP20140606 - NTN7.2 - Container for social media control in DVPNTNStore.Master->LeftContentInfo - BEGIN*/
div#LeftSocialMedia{
    margin:10px 0;
    div.site-socialdiv{
        div.site-socialtitle{
            .ctrltitle;
        }
        div.site-socialitems{
            padding-left:5px;
            border:1px solid @default-border-color;
            border-top:none;
            font-size:30px;
            line-height: 1.2;
        }
    }
}
/*DVPEP20140606 - NTN7.2 - Container for gift registry control in DVPNTNStore.Master->LeftContentInfo - END*/

// Defines 'promo' section within the rightsection DIV
div#promosection
{
    margin-bottom: 10px;
}

#Header,
#Content {    
    //background: @topAreaBackground;
    width:100%; //DVPDS - added to maintain the child widths when the content within the children doesn't fill the min width
}

// the full middle wrapper
#Content {
    .row;
    clear:both;

    @media (max-width: @screen-sm-max) {
        margin-left:0px;
    }

    // We collapse on xs
    @media (min-width: @screen-sm-min) {
        /*display: table;*/ 
        table-layout: fixed; 
        padding-top: 5px;
    }
}

section#MainSection,
aside#LeftSection,
div#RightSection {
    background:@background-default;
    float: none; // allow the sections to take up the whole height of #Content

    // We collapse on xs
    @media (min-width: @screen-sm-min) {
        display: table-cell;
        vertical-align: top;
    }

    @media (max-width: @screen-sm-max){
        min-height:0 !important; //DVPAL20140602 - remove min height requirement when in S and XS viewport
    }
}

// Defines main section within the MainArea
section#MainSection
{
    &:active, &:focus{
        outline:none;
    }
    min-height: 500px;
    text-align: left;
    background: @mainSectionBackground;

    @media (min-width: @screen-xs-min) {
        //padding: 0 10px 30px 10px; //DVPAL20140612 - Padding removed to maximize layout screen estate in very small screens
    padding:10px;
        }

    @media (max-width:@screen-xs-min){
        padding:10px;
    }
    
    @media (min-width: @screen-sm-min) {
        //padding: 0 5px 50px;
        padding: 10px 0px 30px 0px;
        float: right;
    }

    @media (max-width: @screen-sm-max){
        padding-right:10px;
    }
}

// Defines left section within the MainArea DIV
aside#LeftSection {
    height: 100%;
    z-index: 200;
    text-align: left;
    margin-top: 0;
    /*margin-bottom:10px;*/
    padding:10px 10px 10px 10px;
    /*padding-bottom:0;*/
    //background-color: #f4f7f8; //DVPJL20140514 - Delete bg color.

    &:active, &:focus{
        outline:none;
    }
    @media (min-width: @screen-sm-min) {
        padding: 10px 20px 0px 0px;
        float: left;
    }

    @media (max-width: @screen-xs-max) {
        //background-color: @mainSectionBackground;
        .pull-right;
        width:100%; /* DVPAL20140527 - added for Firefox support in responsive search bar mode*/
    }
}

// defines right section (column) of page
div#RightSection
{
    text-align: left;
    height: 100%;
    padding: 0 5px 50px;
}

/* For the main section of the admin pages */
div#AdminBody {
    padding:0 10px 20px 15px;
    margin-bottom:50px;
}

//DVPAL20150603 - changed from div#Footer to footer for better HTML5 semantics
footer {
    //.row DVPAL20140729 - commented out to remove margins
    padding: @footerSectionPadding;
    margin-top:10px;
    text-align: center;
    border-top:5px solid @footerBackground2;
    background: @footerBackground;
    color: @footerColor;

    .btmfootercontainer{
        width:100%;
        background:@footerBackground2;
    }
}

#NavButtons .sitelinks {
    margin-top:15px;
}

.sitelinks {
        list-style: none;
        color:@color-white;
        background:@themeColor;
        border-color:rgba(0, 0, 0, 0.1);
        border-width:1px 1px 3px 1px;
        border-style:solid;
        padding:2px 20px;
        text-decoration: none;
        @media (max-width:@screen-xs-max){
            background:none;
            padding:2px 10px;
        }

        li {
            display: inline-block;
            padding:0 15px;
            border-right:1px solid @themeColor-dark;
            @media (max-width:@screen-xs-max){
                border:none;
                padding:0 10px;
            }
            &:last-child{
                border-right:none;
            }

            a {
                color:@color-white;
                @media (max-width:@screen-xs-max){
                    color:@themeColor;
                }
            }

            &:after {

            }

            &:last-child {
                margin-right:0;
                &:after {
                    content: "";
                }
            }
        }
    }

/* added to display white links in the footer */
.footer-sitelinks {
    ul li a {
        color: @color-white;
        text-decoration: none;
    }
}

// Defines 'loginsummary viewed' section within the LeftSection DIV
div.Summary
{
    margin-bottom:10px;
}

// FOOTER
//------------------------------
div.dvptag
{
    min-height: 30px;
    width:@layout-container-width;
    padding: 5px 5px 10px;
    margin:0 auto;
    color: @footerColor;

    @media (max-width: @screen-md-max){
        width:@footerMediumSize;
    }
    @media (max-width: @screen-sm-max){
        width:100%;
    }

    span
    {
        color: @footerColor;
    }
    a{color:@color-white;}

    .dvpwm{
        float:right;
    }
    .fcopyright{
        float:left;
    }
    @media (max-width: @screen-sm-max){
        .dvpwm, .fcopyright{
            float:none;
        }
    }
}

.dright .pull-left
{
    text-align: left !important;
}

.inline-radios
{
    margin-top: 5px;

    label,
    input
    {
        margin: 0;
        padding: 5px 0 0 5px;
    }

    label
    {
        padding: 0 10px 0 5px;
    }

    input
    {
        margin-top: -5px;
    }
}

// sets style on tables that display various fields i.e. User account info
// column colours determined by td.dleft and td.dright

// DVPDS following are set elsewhere
//table.details,
//.shipto-calendar
//{
//}

.button-container
{
    margin-bottom:40px;
    padding: 0 5px;
    //DVPDS - media entry below added to position the container in small and x-small view
    @media (max-width: @screen-sm-max) {
        //margin: 0 0 20px 10px;// DVPAL20140603 - commented out to remove margins in XS/S
        margin:0;
    }
}

.button-container-inline
{
    margin-top:10px;

    .btn-item
    {
        display:inline-block !important;
        width:auto;
    }

    /*DVPEP20140604 - NTN7.2 - Added so extra small viewports will display buttons on item details as block level so they will expand the length of the viewport. - BEGIN*/
    @media (max-width:@screen-sm-max){
        .btn-item
        {
            display: block !important;
            width:100%;
        }
    }

    div select {
        @media (max-width:@screen-sm-max){
            width:100%;
        }
    }
    /*DVPEP20140604 - NTN7.2 - Added so extra small viewports will display buttons on item details as block level so they will expand the length of the viewport. - END*/

}

/*DVPAL20140611 - IE-specific select box arrow hiding*/
select.btn-avail-small::-ms-expand{
    display:none;
}

/*DVPAL20140609 - parametric search location select box*/
.parametricButtons .locSelect {
    height:36px;
    display:inline-block; //DVPAL20140611 - style specific to the grouped buttons in parametric search
    width:auto;
    select{
        height:100%; //DVPAL20140611 - select box spans the entire height of its container
    }

    @media (max-width: @screen-sm-max){
        width:100%; //DVPAL20140611 - responsive behaviour: 100% width across container
    }
}


.locSelect select{
/* hide select box arrow*/
    -webkit-appearance: none; // Chrome
    -moz-appearance: none; // Firefox
    appearance: none; // IE
    text-indent: 1px;
    text-overflow: '';
    padding-left:5px;

    @media (max-width:@screen-sm-max){
        width:80% !important;
    }
}

//.fuvb //DVPDS - legacy - not needed
//{
//}


p {
    padding: 0;
    margin: 0 0 15px 0;
    color: @text-normal;
    font-family: @font-family-sans-serif;
    font-size: @font-size-small;
    line-height: 1.5;
}

    p.end
    {
        margin: 0;
    }

    p.headir
    {
        padding: 0;
        margin: 0;
        font-size: @font-size-small;
    }

// sets certain defaults, but all inputs will need to have a class defined (default is "solid"), with the exception of checkboxes and radio buttons
// DVPDS - duplicate
//input
//{
//    /*font-size: 11px;
//    color: #333;
//    margin-bottom: 0;
//    margin-top: 0;*/
//}

select
{
    /*border: 1px solid #999;
    font-size: 11px;
    color: #333;
    margin-bottom: 0;
    margin-top: 0;
    background-color: #fff;*/
    height: 34px;
}

textarea
{
    border: 1px solid @default-border-color-dark;
    font-size: 11px;
    color: @text-dark;
    margin-bottom: 0;
    margin-top: 0;
    background-color: @background-default;
}

// sets the style of various headers
H1
{
    font-size: @text-h1-size;
    font-weight:600;
    color: @text-dark;
    margin-top:0; // DVPDS - added to align the page title
}

H2
{
    font-size: @text-h2-size;
    color: @text-dark;
}

    H2.untabbed
    {
        margin-top: 10px;
        margin-bottom: 8px;
    }

H3
{
    font-weight:600;
    font-size: @text-h3-size;
    color: @text-dark;
    padding-top: 0;
    margin-top: 0;
    margin-bottom: 8px;
}

    H3.Alt
    {
        margin-bottom: 0;
    }

    H3.altwhite
    {
        color: @color-white;
        margin-bottom: 0;
    }

H4
{
    font-size: @font-size-small;
    color: @text-dark;
    padding-top: 0;
    margin-top: 0;
    margin-bottom: 8px;
}

    H4.alt
    {
        margin-bottom: 0;
    }

// END - BASIC TAG DEFAULTS




// - - - - - - - -  END OF MASTER PAGES styles - - - - - - - -

// sets common styles for multiple controls (RecentlyViewed.ascx, LeftCartSummary.ascx, productConparelist.ascx)
// to set differing styles for the any of the controls, create new entries using these styles as a base
div.recentlyviewedmenu, div.leftcartsummenu, div.prodcomparemenu
{
    //background-color: #fffff5;
    //padding-left: 0;
    //border: solid 1px #c8c8b8;
    .borderStyle-base;
    border-top:none;
    padding: 1px; // DVPDS - reduced "wrapper" padding, specify padding on children instead
    //border-width: 1px 1px 0 1px;

    .end {
        text-align: right;
        margin-top:5px; //DVPDS to add some more vertical spacing after listing above
        margin-right:15px; //DVPAL20140616 - spacing added
    }

}

// used where to show link text
.link
{
    color: blue;
}


// used in various product templates
table.primaryimage
{
    width: 214px;
    .borderStyle-base;
    padding: 2px;
}

// used in various product templates
table.primaryimage2
{
    width: 150px;
    .borderStyle-base;
    padding: 5px;
}

// sets alternate DIV for the B2B login page
div#TopParentLogin
{
    position: relative;
    height: 65px;
    margin: 0 0 5px 0;
    //background-image:url(../images/bodybg_gradient.gif);
}



// Used in:OrderTotal.ascx
td.totaltaxs
{
    width: 10%;
    padding: 1px 0;
}


// sets style on footer control
div.footer
{
    margin:0 auto;
    width:@layout-container-width;
    height:auto;
	font-size:0;
	color:@color-white;
	min-height:100px;    
	padding-top:10px;
    //width: 960px;
    text-align: left;
    padding: 5px 0;
    background: @footerBackground;
    //background: @footerlinkSectionBackground;
    //background-color: @navbarBackgroundBaseColor;

    @media (max-width: @screen-md-max){
        width:@footerMediumSize;
    }

    @media (max-width: @screen-sm-max){
        text-align:center;
        width:100%;
    }

    .paymenticon{
        text-align: right;
        margin-right: 15px;
        @media (max-width:@screen-xs-max){
            text-align:center;
        }
        /*margin-bottom: -10px;*/
        div.securepayment{
            display:inline-block;
            font-size:12px;
            padding-right:15px;
            margin-bottom:5px;
            width:100%;
            margin-top:0;
            @media (max-width:@screen-xs-max){
                padding:0;
            }
        }
        div.iconcontainer{
            display:inline-block;
        }
    }

    .column{
        width:23.75%;
        padding:10px;
        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
        -moz-box-sizing: border-box;    /* Firefox, other Gecko */
        box-sizing: border-box;    
        vertical-align:top;
        min-height:100px;
        height:auto;
        display:inline-block;
        margin: 0 0 0px 1%;

        @media (max-width: @screen-sm-max){
            width:50%;
            margin:0 0 15px 0;
        }

        @media (max-width: @screen-xs-max){
            width:100%;
            margin:0 0 15px 0;
        }

        h4{
            margin:0 0 10px 0;
            padding-bottom:5px;
            color:@color-white;
            border-bottom:1px solid @default-border-color-darker;
            font-size:@font-size-large;
            font-weight:normal;
        }
        ul.footerll{
            list-style:none;
            margin:0;
            padding:0;

            img{
                margin-top:0;
                margin-right:10px;
                .transition(all 200ms ease-in-out);
            &:hover{
                margin-top:-10px;
                }
            }
            
            li{
                display:inline-block;
                width:100%;
                a{
                    font-size:@font-size-base;
                    color:@color-grey-light2;
                    text-decoration:none;

                    &:hover{
                        color:@color-white;
                    }
                    &:focus{
                        text-decoration:underline;
                    }
                }
            }
        }
    }
}

// sets style on footer links
a.footerLinks
{
    font-weight: 700;
    color: @text-dark;
    text-decoration: none;
}

//DVPAL20140721 - commented out because no longer used
// sets style on lower div lines in Footer.ascx
/*div.middivlines
{
    display: none;
    width: 960px;
    height: 1px;
    background-color: #676869;
}*/

 // sets style on LeftCartSummary.ascx
div.leftcartsummenu {
    .qtyCol, .descCol {
        display: inline-block;
        vertical-align: top;
        width:85%;
    }
   
    .qtyCol {
        width: 13%;
        text-align: center;
    }
     
    .descCol {
    }

    .showmoretext{
        text-align:right;
        padding-right:15px;
    }
}

// sets style on ProductCompareList.ascx
div.prodcomparemenu {
    span.clear-compare{
        font-size:11px; //DVPAL20140711 - changed to 11px from 12px
    }
    .iconCol, .itemCol {
        display: inline-block;
        vertical-align: middle;
    }
    
    .iconCol {
        width: 13%;
        text-align: center;
    }
     
    .itemCol {
    }

    .showmoretext{
        text-align:right;
        padding-right:15px;
    }

}
// ### END of styles used to set the various widths of main elements of the site ###

// Used in:AdvancedSearchResults, ApproveOrders, ContactList.ascx, OrderDetails.ascx
// CustomerApprover, OrderHistory, reports_listing.ascx, Reports_Navigate.ascx
table.axt
{
    border: none 1px @color-black;
}


// sets style on the search bar table
table.search
{
    width: 960px;
    background-color: @background-default;
}

// used where specific padding is used, in dynamic tables.
// i.e. All recently viewed items, my favorites, search category results,
// various category controls, shopping cart and order confirmation page 
/*td.advancedResults //DVPAL20140721 - commented out since we no longer use tables on advancedsearch
{
    background-color: #dedede;
    padding: 0;
}*/

// sets column header in AdvancedSearchResultDetails, AdvancedSearchResults, AllrecentlyViewProducts
// ApproveOrders, CartInfo.ascx, CartInfoDisplay.ascx, ContactList.ascx, OrderDetails.ascx
// CustomerApprover, CustomerInfo, ManageAddress, ManageCustomer, ManagerAgents, OrderHistory
// OrderHistorySummary, SavedCarts, SelectCustomer, AddFavoriteGroup.ascx, ViewCustomer
// account_payable.xsl
td.tdhdr,
th.tdhdr
{
    font-weight: 700;
    background-color: @background-lightest;
    padding: 2px 3px;
}

// used where specific padding is used, in dynamic tables.
// AdvancedSearchResultDetails, AdvancedSearchResults, AllrecentlyViewProducts, CartInfo.ascx, CustomerApprover
// CustomerInfo, ManageAddress, Myfavoritedetails, Report_Print, account_payable.xsl, report_topcustomers.xsl
// report_totalcommission.xsl, report_totalcommissioncust.xsl, report_totalsalescust.xsl
td.tdpad
{
    background-color: @background-lighter;
    padding: 5px 3px 5px 4px;
}

td.tdpadalt
{
    background-color: #d3d3d3;
    padding: 5px 3px 5px 4px;
}

td.tdpadh
{
    background-color: #f2e7de;
    padding: 5px 3px 5px 4px;
}

// used for the section below the shopping cart
td.tdtpad
{
    background-color: @background-default;
    padding: 5px 2px 5px 4px;
}

// used where no padding is needed i.e. AdvancedSearchResultDetails, AdvancedSearchResults, Myfavoritedetails
td.tdnopad
{
    background-color: @background-lighter;
    padding: 1px;
}

// used in the cartInfo.ascx control
td.pricepad
{
    background-color: @background-lighter;
    padding: 2px;
    text-align: right;
}

// Sets style on the top categories control. Note:this control is only displayed when the dropdown menu is not used.
td.categories
{
    font-size: 12px;
    color: #474765;
    font-weight: 700;
}

// sets style on the left part of the Details section(Manufacturer SKU, Description, Web Price etc.)
// used throughout the site where a listing is displayed, such such as user info, where the left column and right column
// are shown with different colors
td.dleft
{
    color: @color-grey-dark;
    background-color: @background-lighter;
    font-weight: 700;
    padding: 3px 3px 3px 5px;
}

td.dleftalt
{
    color: @color-grey-darker;
    background-color: @background-light2;
    font-weight: 700;
    padding: 5px 3px 5px 5px;
}

// sets style on the right part of the Details section used throughout the site where a listing is displayed, such
// such as user info, where the left column and right column are shown with different colors
td.dright
{
    color: @color-grey-dark;
    background-color: @background-lightest;
    padding: 2px 3px 2px 5px;
}

// Used in the Top Customers report XSL
td.drightshort
{
    color: @color-grey;
    background-color: @background-lightest;
    padding: 2px 3px 2px 5px;
}

// used in the footer control
span.dvp
{
    color: @color-grey-darker;
    font-size: 12px;
}

// Used for images with a border
img.thumbnail
{
    //border: solid 1px @link-color; DVPAL20140731 - removed thumbnail border
}

img.thumbnail.rvthumb{
    border:none; //DVPAL20140715 - added to remove border on recently viewed thumbs
}

// to be used for images without a border
img.nobordr
{
    border-width: 0;
}

// sets style on links in various pages such as:my favorites, customer order history, manage address, order history, reports etc.
a.lnk
{
    padding: 3px;
    display: block;
    position: relative;
    text-decoration: none;
}

    a.lnk:hover
    {
        background-color: #dedede;
        text-decoration: none;
    }

a.nolnk
{
    padding: 3px;
    display: block;
    position: relative;
    text-decoration: none;
    color: @color-grey-darker;
}

// sets style on Left Cart Summary, recently viewed and what's hot control links
// DVPDS - moved commented below to showlore.less
//a.moreinfo
//{
//    font-size: 10px;
//    color: #303031;
//    padding: 3px;
//    display: block;
//    position: relative;
//    text-decoration: none;
//}

//    a.moreinfo:hover
//    {
//        color: #381f19;
//        text-decoration: none;
//    }


// sets style on 1st level Left Category links
a.dvpsubcat
{
    color: #471f16;
    display: block;
    position: relative;
    padding: 2px;
    text-decoration: none;
}

.search-form
{
    padding: 20px;
    .borderStyle-base;
    background: @background-lightest;
    width: auto;
    display: inline-block;

    select.as-cat, a.as-sub{
        width:100%;
    }

    .form-control-focus{
        margin-bottom:5px;
    }


    //DVPAL20140602 - responsive behaviour for search form
    @media (max-width: @screen-sm-max)
    {
        width: 100%;
        background: none;
            
         div.control-group div.controls input,div.control-group div.controls select{
            width:100%;
            margin-top:5px;
        } 
    }
}

.control-group { //DVPJL20140625 - ntn7 - advanced search
    display: flex;
    margin-bottom: 2.5px;

    label.control-label {
        float: left;
        display: inline-flex;
        width: 40%;
    }
    .controls {
        float: right;
        display: inline-flex;
        width: 60%;
    }
}

.advanced-filtered-search
{
}

// sets style on Category links on the Advanced Search Results page
a.advancedResults
{
    font-weight:600;
    font-size: 12px;
    color: @themeColor;
    display: block;
    position: relative;
    padding: 2px;
    text-decoration: none;
}

    a.advancedResults:hover
    {
        color: @themeColor-dark;
        text-decoration: none;
    }

// sets style on Category links on the Advanced Search Results page
a.advancedResultsSmall
{
    color: @themeColor;
    display: block;
    position: relative;
    padding: 3px 2px 3px 2px;
    text-decoration: none;
}

    a.advancedResultsSmall:hover
    {
        color: @themeColor-dark;
        text-decoration: none;
    }


// Used where gray links are needed, For example:Footer, Searchbar, CartSummary
a.gray
{
    font-weight: 600;
    color: @link-color;
    text-decoration: none;
}

    a.gray:hover
    {
        color: @link-hover-color;
        text-decoration: none;
    }

// sets style on navigation links such as  A-C D-F
A.more
{
    font-size: 10px;
    color: @text-link-blue;
    padding: 2px 2px 2px 0;
    text-decoration: none;
}

    A.more:hover
    {
        padding: 2px 2px 2px 0;
        text-decoration: none;
    }

// Used where white links are needed, for example:accountlinks and pagelinks controls
a.navwhite
{
    color: #4B4B76;
    text-decoration: none;
}

// Sets style on links that do not require special attributes
a.grid
{
    color: @text-link-blue;
    padding-left: 5px;
    text-decoration: none;
}

// Sets the link in the tabs of the product details page
a.productdetails
{
    color: #464646;
    position: relative;
    font-weight: 700;
    text-decoration: none;
}

// Sets link to be same color as body text
a.textlnk
{
    color: @color-grey-darker;
    text-decoration: none;
}

// Use for input text on the default Search Bar
input.searchinput
{
    width: 130px;
    border: 1px solid #123579;
    background-color: @background-default;
}

// default for textboxes
input.solid
{
    .borderStyle-base;
    background-color: @background-default;
}

// Use for input fields on the Address control. The address control is used on the checkout page, for example.
input.solidform
{
    .borderStyle-base;
    background-color: @background-default;

    padding: 1px 1px 1px 3px;
    width: 159px;
}

// Use for input buttons
input.solidbtn
{
    border: 1px solid #79614b;
    color: @color-black;
    background-color: @background-default;
    padding: 1px 1px 1px 3px;
}

// Sets style on the Credit Card control select form elements
select.cc
{
    padding: 1px;
}

// this is for the ItemUnitofMeasure control
// DVPTF20120814 change size of UOM DDL from width:50px to auto
select.wide
{
    max-width: 120px;
    width: auto; // Or whatever width you want.
}

select.expand
{
    width: auto;
}

select.expanded
{
    width: auto;
    background-color: @background-default;
}

// used for the category extended description on category templates
td.product_text
{
    color: @color-grey-dark;
    font-size: 12px;
    vertical-align: top;
    padding: 5px;
}

// used for the category extended description on category_image_bg template
td.product_text_alt
{
    font-size: 12px;
    vertical-align: top;
    padding: 5px;
}

// sets table for Product Compare list
table.comparetbl
{
    background-color: @background-light;
    .borderStyle-base;
}

// sets left header for comparetbl table
td.comparethd
{
    padding: 4px;
    background-color: @background-lightest;
    width: 116px; // 124 - 8(left and right padding) = 144 
    .borderStyle-base;
}

// sets left TD for comparetbl table
td.comparetd
{
    padding: 4px;
    background-color: @background-lightest;
    width: 116px; // 124 - 8(left and right padding) = 144
    .borderStyle-base;
}

// sets right TD header for comparetbl table
td.comparethdr
{
    padding: 4px;
    background-color: @background-lightest;
    width: 117px; // 125 - 8(left and right padding) = 110
    vertical-align: top;
    text-align: center;
    .borderStyle-base;

}

// sets right TD for comparetbl table
td.comparetdr
{
    padding: 4px;
    background-color: @background-default;
    width: 117px; // 125 - 8(left and right padding) = 110 
    vertical-align: top;
    text-align: right;
    .borderStyle-base;

    p {
        text-align: left;
    }
}

// sets row TD for comparetbl table (subsection heading)
td.comparerow
{
    padding: 4px;
    vertical-align: middle;
    font-weight: 700;
    .borderStyle-base;
}

// used in placed where an image is used as a button
img.imgbutton
{
    cursor: pointer;
}

table.hidecells
{
    empty-cells: hide; // this will hide empty cells 
    border-collapse: separate; // this is required to hide empty cells (along with the line above) 
    border: 0;
    padding: 0;
}

div.parametricdiv
{
    padding-left: 0;
    border:1px solid @themeColor;

}

/*DVPJL20140514 - NavToNet7 -*/
div.parametricfilters {
    .input, .filtername, .numbers, .cancel {
        display: inline-block;
    }
    .input {
        padding-left: 5px;
        vertical-align: middle;
    }
}

// sets style on parametric_item.ascx - Start // DVPJL20140516
.descTop {
    display: inline-block;
    @media (max-width: @screen-xs-max) {
        padding: 0px;
        margin-bottom: 6px;
        }
}

.printBtnTop {
    @media (min-width: @screen-sm-min) {
        display: inline-block;
        float: right;
        }
}

.pItemRow {
    .row;
    display: inline-block;
    margin-top: 15px;
    margin-bottom: 15px;
}
// sets style on parametric_item.ascx - End // DVPJL20140516

// sets style on parametric_search_items.ascx - Start // DVPJL20140516
.btnGroupBottom {
    display: block;
    
    @media (min-width: @screen-sm-min) {
        text-align: center;
        }
    
    div {
        display:inline-block;
        vertical-align: top;
    }
}

.parametric .CompareCB {
     @media (max-width: @screen-sm-max) {
        display: none !important; //DVPAL20140630 - commented out to prevent cb being hidden in sm and xs viewport
        }
}

.CompareCBDup {
     @media (min-width: @screen-sm-max) {
        display: none !important;
        }
}
// sets style on parametric_search_items.ascx - End // DVPJL20140516

// sets style on left nav group heading
div.groupheading
{
    border: solid 1px @themeColor-dark;
    background-color: @themeColor;
    font-weight: 600;
    padding: 3px 5px 3px 3px;
    color: @color-white;
}

// sets style on Left Parametric Search // DVPJL20140515
a.clsp_groupheading
{
    /*border: solid 1px @themeColor-dark;*/
    background-color: @themeColor;
    display:block;
    font-weight: 600;
    padding: 2px 5px 2px 2px;
    color: @color-white;
    cursor: pointer;
    margin-top: -1px;
    margin-bottom: -1px;
    .transition;
    
    &:hover {
        background: @themeColor-light;
        }
    
    span.chevron {
        float: right;
        display: block;
        .transition;
        }
}

// sets style on TD containing left nav links
div.leftnavtd
{
    padding: 1px 0 1px 2px;
}

// sets style on left category links 
.leftnavlnk
{
    color: #354a5c;
    text-decoration: none;
}

// sets style on left category links 
.leftnavlnkSelected
{
    color: #cc3300;
    font-weight: bold;
    margin-left: 0;
    text-decoration: none;
}

// sets style on left category links 
.leftnavlnkDisabled
{
    color: @color-grey-dark;
    text-decoration: none;
}

// sets style on left category link numbers 
a.leftnavnumlnk
{
    color: @color-grey-dark;
    text-decoration: none;
}

div#ParametricSearchd
{
    margin-bottom:10px;
    //margin-left: 5px; //DVPJL20140515 - Comment out to unify style with left content.
    /*margin-top: 7px;*/
    /*.ctrltitle{
        color:@text-color;
    }*/
}

table.autolocated
{
    border: 1px solid #a9a9a9;
    border-color: #e3e3e3 #a9a9a9 #a9a9a9 #a9a9a9;
    background-color: #fffdd4;
    width: 107px;
}

// - END - Dynamic Forms - 

// used for the location links
a.loclink
{
    font-size: @font-size-small;
    text-decoration: none;
}

// used for the filter section in the dropdown parametric search template
div.breadcrumbps
{
    .borderStyle-base;
    background-color:@background-lightest;
    padding: 2px;
    width: 100%;
    div {
        display: inline-block;
    }
}

table.textontop
{
    width: 575px;
    padding: 5px 0 0 5px;
    height: 350px;
}

// set the opacity of the table BG, allows background image for the textontop section to show through this table
table.product_text_trans
{
    background-color: @background-default;
    float: left;
    opacity: 0.8;
    -moz-opacity: 0.8;
    filter: alpha(opacity=80);
}

td.product_text_transtd
{
    // sets the first cell
    opacity: 1.0;
    -moz-opacity: 1.0;
    filter: alpha(opacity=100);
    font-size: 11px;
    color: @color-black;
    background-color: @background-default;
    padding: 0 3px 0 3px;
    text-align: left;
}

table.cat_alt
{
    background-color: #50607e;
    margin: -5px -4px 0 -4px;
    width: 638px;
}

// used in the product details for line discounts
td.tdsml
{
    font-size: 9px;
}

// used for quote list
input.tdhdr
{
    border: 0;
    background-color: #e0dbd7;
    cursor: pointer;
}

//===============================
// NTN6.0 SP2-begin
//===============================
.SAH3
{
    font-weight: bold;
    font-size: 13px;
}

.instoremsg
{
    border: solid 1px @state-danger-text;
    background-color: @background-default;
    padding: 6px;
}
//===============================
// NTN6.0 SP2-END
//===============================

img.recommendthumb
{
    width: 80px;
    height: 80px;
    border: 0;
}

table.radiobutts tr td
{
    vertical-align: top;
}

// used in the gift registry management area
table.section1
{
    background-color: @background-default;
    border: solid 1px #e2e2d6;
}

table.section0
{
    background-color: #e9f6ec;
    border: solid 1px #c6d1c9;
}

.ajax__calendar_inactive
{
    color: @color-grey-light2;
}

.ajax__calendar_container{
    width:auto !important;
}

table.orderinfo
{
    width: 100%;
    background-color: #abbdc5;
}

    table.orderinfo td
    {
        background-color: #ddedf5;
    }

td.orderinfo
{
    background-color: #c3d7e1;
}

// used for terms and conditions DVPCD20110823-SP3 VENDOR PORTAL
div#termsandconditions
{
    height: 250px;
    position: relative;
    overflow: scroll;
    background-color: @background-lightest;

}

.ModalBackground
{
    background-color: @color-black;
    filter: alpha(opacity=40);
    opacity: 0.4;
}

// Position should be -1000px, so during loading you not gonna see popup initialization
.ModalWindow
{
    .borderStyle-base(@color-black, 1px, 0px, solid);
    position: relative;
    padding: 4px;
    top: -1000px;
    empty-cells: hide;
    background-color: @background-light;

}

IFRAME.viewgrants
{
    width: 400px;
    height: 300px;
    border: 0;
    border: solid;
}

// added to hide empty table row in the address control
tr.hidden
{
    display: none;
}

#simplesearchpnl {
    margin-bottom: 40px;

    @media (max-width: @screen-sm-max) {
    width:100%;
    background:none;
    input, select, a{
        width:100%;
        margin:5px;

        }
    }
}

/*DVPAL20140602 - added for search buttons in advanced search*/
.controls a.btn.as-sub{
    width:100%;
    margin:5px 0 0 0;
}
.control-group-error
{
    width: 100% !important;
    font-size: 0.8em;
    line-height: 1em;
}

    .control-group-error span
    {
        text-align: left;
    }

.control-label
{
    font-size: 12px; //DVPAL20140630 - changed to font size 12px from 11px
    font-weight: bold;
}

.col-item-qty .input-append,
.itemqty .input-append
{
    margin: 5px;
}

.cItemCntL .form-inline label
{
    padding-top: 5px;
}

.cItemCntL .form-inline .btn-group
{
    vertical-align: top;
}

.cItemCart .input-append
{
    margin-top: 1px;
}

.solidshort
{
    width: 85px;
}

.favorite-group-dropdown
{
}

.cItemCntL
{
    .form-inline
    {
        label
        {
            padding-top: 5px;
        }

        .btn-group
        {
            vertical-align: top;
        }
    }
}

.cItemCart
{
    .input-append
    {
        margin-top: 5px;
    }
}



/* DVPDS - START - Following were moved from default.less */
/* will need to figure out where the following should actually reside */



.topcartsummary {
    padding: 10px;

    .cartsumtd{
        text-align:center; //DVPAL20140604 - center text in the price preview
    }
    a {
        .btn;
        .btn-primary;
        .btn-block;
        margin-bottom: 5px;
    }
}

#dropdown-extra-toggle {
    display: block;

    .icon-bar {
        background-color: @background-default;
    }
}


div.admin-container{
    width:100%;
    display: inline-block;
    margin-bottom:20px;

    div.admin-col-text{
        max-width: 33%;
        margin-right: 40px;
        width:100%;
        padding:0;
        float:left;
        @media (max-width:@screen-sm-max){             
            max-width:100%;
   
        }
    }

    div.admin-col{
        max-width: 33%;
        width: auto;
        padding: 5px;
        float: left;
        margin: 5px;


        @media (max-width:@screen-sm-max){
            width:100%;
            max-width:100%;
            margin:0;
            padding:0;
        }

        ul{
            list-style:none;
            padding-left:0;

            li {
                list-style-type:none;
                padding-left:5px;    
            }
            li:before{
                content:" - ";
            }
        }
    }
}

/* DVPJL20140318 - Start - */
div.adminMenuDetails {
    width:99%;
    border: 1px solid @default-border-color-lighter;
    background-color: @background-default;
}

div.adminMenuDetails dl {
    background-color: @background-light;
}  

/*DVPDQ20140401 - START - Below styles applies to the div container for cookie policy*/

.overlaydiv 
{
	width:95%;
	height:100%;
	top:0px;
	left:0px;
	position:fixed;
	opacity:0.90;
	background-color: rgba(0,0,0,0.5);
	z-index:20000;
}

.innerdiv
{
    top:0px;
	left:0px;
	//width: 99%; /*DVPEP20140527 - NTN7.2 - Changed width to 99% for now as the container is flowing underneath the scrollbar in IE.*/
	width: 100%; /* DVPAL20140604 - set width back to 100% and fixed IE scrollbar overlap by offsetting close button*/
    background-color: @background-default;
	border-top-color: #888;
	border-left-color: #888;
	position:relative; 
	z-index:20001;
    overflow-y: hidden;
    -moz-box-shadow: 0px 0px 13px 1px rgba(50, 50, 50, 0.2);
    -webkit-box-shadow: 0px 0px 13px 1px rgba(50, 50, 50, 0.2);
    box-shadow: 0px 0px 13px 1px rgba(50, 50, 50, 0.2);
	border-top-style:solid;
	border-top-width:1px;
	border-left-width: 1px;
	border-left-style: solid;
	border-radius:0px 0px 5px 5px;
    -moz-border-radius:0px 0px 5px 5px;
	-webkit-border-radius:0px 0px 5px 5px;
	text-align:center;
	padding-bottom: 10px;
	display:none;
}

.cookiepolicyImgbtn
{
    margin-right:15px; //DVPAL20140604 - added to prevent IE scrollbar from overlapping close button
    position:absolute;
    right:0;
	margin-top: 5px;
    border-radius:@border-radius-base;
	width: 26px;
	height: 25px;
    
    i.fa-times{
        font-size:18px;
        line-height:1.4;
    }
}

.cookiepanel {
	text-align: left;
    background:@background-default;
	padding: 10px 10px 10px 10px;
	border-radius:5px 5px 5px 5px;
    -moz-border-radius:5px 5px 5px 5px;
	-webkit-border-radius:5px 5px 5px 5px;
	 width:100%;
    
     p{
         line-height: 1.2;
     }

    @media (max-width: @screen-md-max) {
        &.high-md-3 {
        height: auto;
        max-height: 110px;
        }
    }

    @media (max-width: @screen-sm-max) {
        &.high-sm-5 {
        height:auto;
        max-height:120px;
        }
    }

    @media (min-width: @screen-lg-min) {
        &.high-lg-3 {
        height: auto;
        max-height: 90px;
        }
    }
    @media (max-width: @screen-xs-max) {
        &.high-xs-3 {
        height:auto;
        max-height:190px;
        overflow-x: auto;
        }
    }
}

.cookietextlbl {
    line-height:1;
    font-size:12px;
    font-family:@font-family-sans-serif;
}


/*DVPDQ20140401 - END - Below styles applies to the div container for cookie policy*/


// TODO: Move this into buttons.less
.btn-item {
    .btn;
    .btn-default;
    .btn-block;
    
    display: block !important;
    margin-bottom: 5px;
    text-align: left;
    padding: 7px 10px;

    //DVPDS - added to apply the defaults for the btn-primary to this btn-item class
    .button-variant(@btn-primary-color; @btn-primary-bg; @btn-primary-border);
    //
}

// Make the buttons shrink to fit
.btn-avail-big,
.btn-item, .btn-group {
	// we are going reverse here since the 
	@media (max-width: @screen-sm-max) {
		display: inline-block !important;
		width: 100%;// changed to 100% for buttons to span in SM and XS viewports
	}
    /*DVPEP20140528 - NTN7.2 - Added so extra small viewports will display buttons on item details as block level so they will expand the length of the viewport. - BEGIN*/
    @media (max-width:@screen-xs-max){
        display:block !important;
    }
    /*DVPEP20140528 - NTN7.2 - Added so extra small viewports will display buttons on item details as block level so they will expand the length of the viewport. - END*/
}

.btn-alert {
    .btn-sm;
}

table.item-details {
    .table;
    .borderStyle-base;

    td {
        border-bottom: 1px solid @default-border-color;
    }

    td:first-child {
        background:@background-lighter;
        font-weight: bold;
        vertical-align: middle; //DVPJL20141006
    }

    td:last-child {
        background:@background-lightest;

    }
}

// TODO: create generics for all tables
table {
    // Used for columns that are labels
    td.col-label {
        background: @background-lighter;
        font-weight: bold;
    }
}

/*//DVPAL20140603 - added for tighter text in tables
table.table tbody tr td
{
    padding:0px;
}*/



// TODO: Move this into animations.less
// Refs: http://stackoverflow.com/questions/15517966/jquery-rotate-a-div
.rotate_0 {
  -webkit-transform: rotate(0deg);  /* Chrome, Safari 3.1+ */
    -moz-transform: rotate(0deg);  /* Firefox 3.5-15 */
      -ms-transform: rotate(0deg);  /* IE 9 */
        -o-transform: rotate(0deg);  /* Opera 10.50-12.00 */
         transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera 12.50+ */
}
.rotate_180 {
  -webkit-transform: rotate(180deg);  /* Chrome, Safari 3.1+ */
    -moz-transform: rotate(180deg);  /* Firefox 3.5-15 */
      -ms-transform: rotate(180deg);  /* IE 9 */
        -o-transform: rotate(180deg);  /* Opera 10.50-12.00 */
         transform: rotate(180deg);  /* Firefox 16+, IE 10+, Opera 12.50+ */
}

span.chevron.rotate_180 {
      -webkit-transform: rotate(180deg);  /* Chrome, Safari 3.1+ */
    -moz-transform: rotate(180deg);  /* Firefox 3.5-15 */
      -ms-transform: rotate(180deg);  /* IE 9 */
        -o-transform: rotate(180deg);  /* Opera 10.50-12.00 */
         transform: rotate(180deg);  /* Firefox 16+, IE 10+, Opera 12.50+ */
         text-shadow:none !important; /*DVPAL20140521 - text shadow is removed upon rotation*/
}

.transition {
  -webkit-transition: all 0.3s ease-out;  /* Chrome 1-25, Safari 3.2+ */
     -moz-transition: all 0.3s ease-out;  /* Firefox 4-15 */
       -o-transition: all 0.3s ease-out;  /* Opera 10.50�12.00 */
          transition: all 0.3s ease-out;  /* Chrome 26, Firefox 16+, IE 10+, Opera 12.50+ */
}

// TODO: Create new file for tabbed sections (or just reuse layout.less)
// APPLIES TO: untabbed
section.untabbed,
section.tabbed  {
    margin: 0 0 20px;

    article {
          &:last-child{
              a.accordian{
                border-bottom:none;

              }
            }
            &:first-child{
                a.accordian{
                border-top:none;

                }
            }
        a.accordian {
            //background: #3399cc; //DVPDS - reversed default color and hover color
            background: @themeColor;
            padding: 5px 20px;
            color: @color-white;
            font-weight: 600;
            display:block;
            margin: -10px 0 10px;
            font-size:15px;
            cursor: pointer;
            //border-bottom: 1px solid #07435d;
            border-bottom: 1px solid darken(@themeColor,7%); //DVPDS - changed color shade
            border-top:1px solid lighten(@themeColor,7%);

          
            &:hover {
                background: darken(@themeColor,10%); // DVPDS - used to be: #07435d
                .transition;
                text-decoration:none;
            }

            span.chevron {
                float: right;
                display: block;
                .transition;
            }
        }

        & > div {
            margin: -10px 0 30px;
            padding: 10px;
            background: @background-default;
        }
    }
}

table.specifications {
    .table;
    .borderStyle-base;
    
    th {
        padding: 10px;
        /*background: #888;*/
        &.spec-table-header{
            background:@background-light;
            padding:2px 8px;
        }

    }

    span.spec-header {
        font-weight: 600;
        padding: 0;
        margin: 0;
        font-size:@font-size-base;
        color: @color-black;
    }

    .spec-label {
        background: @background-lighter;
        font-weight: 600;
    }

    .spec-label,
    .spec-item {
        border-top: 1px solid @default-border-color;
    }
}

div.accessories {
    .row;
    margin: 0;

    .item {
        //border: @tableBorder;
        height:auto;
        min-height: 100px;
        margin: 5px 10px;
        padding:5px;
        /*.hoverShadow;*/

        @media (max-width: @screen-xs-max){
            margin:5 auto;
        }


        img{
            margin:0 auto;
        }

        div{
            height:70px;
            width:70px;
            margin:0 auto;
        }
    }
}

// TODO: make this more generic since this is shared with other tabbed sections too
h2.predictor {
    //background: #07435d;
    background:@themeColor; // DVPDS - changed background to gray (blue should be generally reserved for actual links)
    padding: 5px 10px;
    color: @color-white;
    font-weight: 600;
    margin: 0 0 0 0; // DVPDS - updated to position better
    //cursor: pointer; // DVPJL20140508 - Comment out to constantly display predictor tab
    //border-bottom: 1px solid #3399cc; //DVPDS - replaced with below
    border: 1px solid @themeColor;
    border-width:1px 1px 0 1px;

    // DVPJL20140508 - Comment out to constantly display predictor tab
    /*&:hover {
        background: #3399cc;
        .transition;
    }*/

    span {
        //float: right; // DVPJL20140508 - Comment out to constantly display predictor tab
        display: block;
        .transition;
    }
}


// Used in:predictor.ascx, product_control_alt.ascx

div#predictor {

    text-align: left;
    padding-left: 0;
    background-color: @background-default;
    border: 1px solid @themeColor;

    .row;
    margin: 0 0 20px;
    padding: 10px 20px;

    .item {
        /*border: @tableBorder; DVPAL20140715 - commented out to remove border*/
        /*height: 150px;*/
        height:auto;
        min-height:100px;
        margin: 5px 10px;
        padding:5px;
        /*.hoverShadow; //DVPAL20140715 - hover shadow effect mixin*/


        img {
            margin:0 auto;
        }

        .thumbnailcontainer{
            height:70px;
            width:70px;
            margin:0 auto;
        }
    }

    // DVPJL20140508 - Center predicted items
    @media (max-width: @screen-xs-max) {
        .item {
            margin:5px 0px !important;
        }
    }


}

// Extends form-groups
.form-control-inline {
    display: inline-block;
}

.form-add-to-cart {
    margin-right: 0;
    margin-left: auto;
    width:177px;
    @media (max-width:@screen-sm-max){
        width:100%; /* DVPAL20140529 - width set to 100% in small viewport*/
    }

}


// TP: ADDED FOR MERGING ALL BELOW
// Item Details Wrapper
.item-details-wrapper {
	//.row;
	/*margin-left:-15px; // DVPDS - Added to align image
    margin-right:-5px;// DVPDS - Added to align image*/
	//margin: 0;  - DVPDS - edited to better align the image
	
    @media (max-width:@screen-sm-max){
        margin:0 auto; // DVPAL20140602 - remove margins in XS/S viewport 
    }

	.primary-image-wrapper {
		
	}
	
	.item-table-wrapper {
        .table{
            margin-bottom:10px; // DVPAL20140626 - added to reduce the margin between line discount and sales price
        }
        .SaleslineDiscountwrapper tr td
        {
            padding:0; //DVPAL20140626 - added to reduce the padding for line discount and sales price tables
        }
	
	}
}

div.notable {
	dl {
		background: @background-lighter;
		.borderStyle-base;
		
		dt {
			background:@background-light2;
			float:left; 
			font-weight:bold; 
			margin-right:10px; 
			padding:5px;  
			width:100px; 
		}
		
		dd {
			margin:0; 
			padding:5px 0;
			border-bottom: 1px solid @default-border-color;
			
			&:last-child {
				border: none;
			}
		}
	}
}

// DVPDS - moved the div#large_image from here into the productimagelist.less file

.salespricesAndDiscounts {
	.salesprices {
		& > div > b { // TODO: change this to a header
			width: 100%;
			font-weight: bold;
			padding: 0;
			margin: 0;
			border-bottom: 1px solid @color-grey-darker;
			display: block;
		}
		
		// TODO: fix structure in control
		& > div > table {
			.table;
		}
	}
}


div.untabbed-wrapper {
    clear:both;
    margin-top:25px;
}

/*DVPEP20140605 - NTN7.2 - Added so login tab sections will have a border. - BEGIN*/
#tabbedsections{
    .borderStyle-base;
    border-color:@themeColor;
    position:relative;
    top:-1px;
    z-index:298;
    padding:10px;
    overflow:hidden;
}
/*DVPEP20140605 - NTN7.2 - Added so login tab sections will have a border. - END */

// TODO: merge this all into gallery control
// DVPDS - moved the section below (enlarge, zoomerimg and dhtmlgoodies_slideshow) into productimagelist.less



// added for the tabbed sections on the product details page 
//.idTabs {margin:10px 0 0 0; clear:both; float:none; padding-left:0px;}

//DVPAL20150603 - login page tabs: removed top margin to prevent blank spacing
#MainSection > ul.idTabs{
    margin-top:0;
}

.idTabs {
	//padding: 0 0 0 5px; // fix the box-model
	//margin-bottom: -1px; // overlap the borders
    margin:10px 0 0 0; 
    clear:both; 
    float:none; 
    padding-left:0px;
    position:relative; // DVPDS - added to position this relative to the section below
    z-index:299; // DVPDS - added to position this relative to the section below
	&.logintabs{

	}
	li {
		display: inline-block;
		margin: 0 0 0 -5px;
        list-style:none;
        border: 1px solid @themeColor;
        border-bottom:none;

        @media (max-width:@screen-xs-max){ //DVPAL20140623 - added to stretch login tabs in XS viewport
        width:100%;
        margin:0;
        }

		& > a {
			.transition;
			
			display: block;
			padding: 5px 10px;
			.borderStyle-base;
            /*border-color:@themeColor;*/
            border:none;
			//background: #3399cc; // DVPDS - reversed the hover/default colors
            background:@themeColor; // DVPDS changed to use variable
			color: @color-white;
			font-weight: 600;
		
			text-decoration: none;
		
            @media (max-width:@screen-xs-max){ //DVPAL20140623 - added to adjust colours in XS viewport
                background:@background-default;
                color:@themeColor-dark;
                /*margin-top:-1px;*/
            }

			&:hover {
				//background: #07435d; // DVPDS - reversed the hover/default colors
                background:@themeColor-light; //DVPDS - changed to use variable
                color:@color-white;
			}
			
			&.selected {
                background:@background-default;
                /*.gradientSelectedTab;*/
				color: @themeColor-dark;
				/*border-bottom: 1px solid @background-default;*/
				
                @media (max-width:@screen-xs-max){ //DVPAL20140623 - added to adjust colours in XS viewport
                background:@themeColor; 
                color:@color-white;
                }
				//border-top: 3px solid #3399cc;
				//margin-top: -2px;
			}
}
}

    li:first-child {
        margin-left:0px !important;
    }

    a {
	padding:10px 10px;
	display:inline-block;
	background:@background-light;
	color:@color-black;
	text-decoration:none;
    .borderStyle-base;
    border-bottom-width:0;
    font-size:1.1em;
    font-weight:700;
}
        a.selected {
	    background:@background-lighter;
	    color:@color-black;
    }
}


.tabbed {
    .borderStyle-base;
    border-color:@themeColor;
    padding:10px;
    min-height:150px;
    clear:both;
    margin-top: 0 0 20px;
    position:relative; //DVPDS - added to position the section up
    top:-1px; //DVPDS - added to position the section up
    z-index:298; //DVPDS - added to position the section up
}

 
/*DVPJL20140425*/
// DVPDS - below moved to the productimagelist.less

//.primaryimage
//{
//    display: block;
//    width: 100%;
//    /*#normal_image {
//        
//        margin-left: auto;
//        margin-right: auto;
//    }*/
//    #dhtmlgoodies_slideshow {
//        width: 70%;
//        margin: 0 auto;
//    }
//}

// DVPJL20140502 - SelectMyLocation.aspx - Start
// DVPAL20140620 - editRole.aspx classes added
.avaiLoc, .exisLoc, .permissions-list, .permissions-role {
            display:table-cell;
            }
.arrow {
    display:table-cell;
    vertical-align: bottom;
            }
.admin-arrow {
    display:table-cell;
    vertical-align:middle;
}
.SMLRow {
    //display:table-row; // DVPAL20140522 - removed to allow for responsive behaviour
    display:block;
    margin-top: 15px;
}

.avaiLoc select, .exisLoc select{
        min-width:170px; // DVPAL20140522 - added to specify a minimum width for select boxes
    }

.permissions-list select, .permissions-role select{
    min-width: 275px;
}


@media (max-width:@screen-sm-max){
    .permissions-list select, .permissions-role select{
    min-width: 210px;
}
}


div.form-inline-SML{
    .form-control{
        display:inline-block;
        max-width:170px;
        &:first-child{
            max-width:140px;
                @media (max-width: @screen-sm-max){
                max-width:none;
            }
        }
        @media (max-width: @screen-sm-max){
            max-width:none;
        }
    }
}

// DVPAL20140521 - Location search responsive behaviour - START
@media (max-width: @screen-sm-max) {
 
    // DVPAL20140521 - Added for the SML-specific dropdown select boxes and buttons
    .form-inline-SML select, .form-inline-SML .btn, .SMLRow .btn {
        width: 100% !important;
        margin-bottom: 5px; // small spacing between dropdown select boxes
    }   

     // DVPAL20140521 - Added for responsive behaviour of location boxes
    .SMLRow {
        width:100%;
    }

    // DVPAL20140522 - Added to have location boxes and arrow container span across the screen in XS viewport
    .avaiLoc, .exisLoc, .SMLRow .arrow, .SMLRow .admin-arrow, .permissions-list, .permissions-role{
        display:block;
        width:100%;
    }

    .avaiLoc select, .exisLoc select, .permissions-role select, .permissions-list select{
        width:100%;
    }


    // DVPAL20140522 - Added to rotate arrow icons 90 degrees (left -> down, right -> up) //
    .SMLRow .arrow i.fa-arrow-right, .SMLRow .arrow i.fa-arrow-left, .SMLRow .admin-arrow i.fa-arrow-right, .SMLRow .admin-arrow i.fa-arrow-left{
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    // DVPAL20140522 - Added to disable the line break in XS viewport
    .SMLRow .arrow > br, .SMLRow .admin-arrow > br{
        display:none;
    }

    // DVPAL20140522 - Center arrow buttons in the arrow container
    .SMLRow .arrow, .SMLRow .admin-arrow{
        text-align:center;
    }

    // DVPAL20140522 - Set a fixed width for the icons container to prevent next-line wrapping
    .SMLRow .arrow a.btn, .SMLRow .admin-arrow a.btn{
        width:20px !important;
    }
//DVPAL - Location search responsive behaviour END
}

// DVPJL20140502 - SelectMyLocation.aspx - End

// DVPAL20140529 - welcome page block styles - START
    div.welcomecontents{
        display:inline-block;
        margin-bottom:10px;
        img{
            max-width:100%;
            @media (max-width: @screen-xs-max){
                width:100%;
            }
        }
    }
// DVPAL - welcome page block styles - END

// DVPAL20140630 - order history details - START

div.oh-container{
    //width:100%;
    display:inline-block;
    padding-right:35px;

    @media (max-width: @screen-sm-max){
        width: 100%;
        padding:0;
    }

    .oh-label, .oh-value{
        display:inline-block;
    }
    .oh-label{
        margin-right:5px;
    }

}

div.oi-container{
    width:100%;


    @media (max-width: @screen-sm-max){
        width: 100%;
        padding:0;
    }

    .oh-label, .oh-value{
        display:inline-block;
    }
    .oh-label{
        margin-right:5px;
    }

}

.vc-header {
    display: inline-block;
    width:70%;
    border-right:1px solid @default-border-color;
    @media (max-width: @screen-sm-max){
        border:none;
        width:100%;
    }
}
.vc-header.cno{
    width:28%;
    @media (max-width: @screen-sm-max){
        width:100%;
    }
}

.vc-header:last-child{
    border-right:none;    
}
.vc-row,.vc-table-header {
    width: 100%;
    border-bottom:1px solid @default-border-color;
}

.vc-table-header {
    @media (max-width: @screen-sm-max) {
        display:none;
    }
}

.vc-row:last-child {
    border-bottom:none;
}
.vc-table {
    width:100%;
    background:@background-lighter; 
    .borderStyle-base;
}

// DVPAL20140630 - order history details - END

// DVPAL20140703 - CustomerApprover.aspx - START

div.ca-container{
    width: 230px;
    margin-bottom: 10px;
    margin-right: 20px;
    display: inline-block;
    vertical-align: top; //DVPAL20140710 - added for firefox support

    @media (max-width:@screen-sm-max){
        width:100%;
        margin-right:0;
    }
}

div.ca-details{
    padding:10px
}
// DVPAL20140703 - CustomerApprover.aspx - END

// DVPAL20140703 - GiftRegistryList.aspx - START
.gr-table-btn{
    display:inline-block;
    @media (max-width: @screen-sm-max){
        width:100%;
        margin-bottom:5px;
    }

    a.btn{
        @media (max-width: @screen-sm-max){
        width:100%;
        }
    }
}
// DVPAL20140703 - GiftRegistryList.aspx - END

// DVPAL20140703 - billpaymentonlineaspx - START
div.remainingamount{
    width: 350px;
    @media (max-width:@screen-sm-max){
        width:100%;
    } 
}

div.paymentamount{
    margin-bottom:10px;
    .paymentcontainer{
        vertical-align: top;
        margin-right:10px;
        display:inline-block;
        @media (max-width: @screen-sm-max){
            margin-bottom:10px;
            width:100%;
        }
    }
}

div.cc-address-container{
    width:100%;
    div.cc-sec{
        width:100%;
        display: inline-block;
        .cclabel,.ccvalue{
            padding:2px;
            display:inline-block;
            @media (max-width: @screen-sm-max){
                width:100% !important;
                margin:0!important;
            }
        }
        .cclabel{
            width:35%;
        }
        .ccvalue{
            padding-left:0;
            margin:-1px; //DVPAL20140708 - added to line up with other input boxes
            width:30%;
        }

        .input input{
            width:100% !important;
        }
        div.input{
            white-space: normal;
            line-height: 2;
            width:60% !important;

            @media (max-width: @screen-sm-max){
                width:100% !important;
            }
            span{
                display:block;
                line-height:2;
                white-space:normal;
            }
        }
    }
}

// DVPAL20140703 - billpaymentonlineaspx - END

// DVPAL20140711 - sales agent toolbar - START

div.btn-group.salesagent-btn{ //added specifically for the sales agent buttons on the left side bar
    width:100%;
    display:inline-block !important;
    a.btn{
        width:50%;
    }
}

// DVPAL20140711 - sales agent toolbar - END

// DVPAL20140725 - hero carousel styles; additional properties set in controls.js - START

div.heroCarousel{
    margin-top:-5px;
    @media (max-width:@screen-md-max){
        margin-top:0;
    }
    //commented out to remove drop shadows
    /*box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 8px 0px;*/
    img{
        width:100%;
    }
      margin-bottom:10px;

}

// DVPAL20140725 - hero carousel - END

// DVPAL20140808
div.VendorPortalMenu{

    div{
        display:inline-block;

        @media (max-width: @screen-sm-max){
            display:block;
        }
    }
}

.alertreportcontainer .fieldcontainer {
    vertical-align: top;
    width: 200px;

    @media (max-width: @screen-sm-max) {
        width:100%;
    }
}


div.vendorimg{
    margin-bottom:10px;
    div{
        display:inline-block;
        margin-right:10px;
    }
    div:first-child{
        width:100px;
    }
}
div.vendorprodupdate.vendorprice{
    input,select
    {
        display:inline-block;
        vertical-align:top;
        }
    input{
        width:auto;
        @media (max-width: @screen-sm-max){
            margin-bottom:5px;
        }
    }

}

div.vendorprodupdate.currency{
    a.btn, select{
        display:inline-block;
    }
    a.btn{
        margin-left:10px;
        @media (max-width: @screen-sm-max){
            width:100%;
            margin:10px 0 10px 0;

        }
    }
}

div.vendorprodupdate, div.fieldcontainer{
    display:inline-block;
    margin-bottom:15px;
    margin-right:5px;
    vertical-align:top;

    input, label{
        margin:0px;
        vertical-align:middle;
    }

    @media (max-width: @screen-sm-max){
        width:100%;

            input, select{
        width:100% !important;
        }
        input[type="checkbox"]{
            width:auto !important;
        }
    }
}

div.displayresultsby{
    width:auto;
    float:right;

    @media (max-width: @screen-sm-max){
        float:left;
    }
}

div.sendfileInput{
    input{
        display: inline-block;
        @media (max-width: @screen-sm-max){
            margin-bottom:10px;
        }
    }

}

div.resendtransactioncontainer {
    width:50%;
    @media (max-width: @screen-sm-max){
        width:100%;
    }
    div.rt-container {
        div.rt-label, div.rt-value {
            display: inline-block;
        }
        div.rt-label{
            width: 30%;
            label{
                vertical-align:middle;
            }
        }
        div.rt-value{
            width: 65%;
        }
        div.rt-date1, div.rt-date2{
            width:30%;
            display:inline-block;
            margin-right: 10px;
        }
    }
}


//DVPAL20150309 - webpromo sections for homepage - START
section.webpromo-container{
    width:100%;
    padding:0;
    font-size:0; //DVPAL20150309 - to prevent blank space between divs
    margin-bottom:10px;
    @media (max-width: @screen-xs-max){
        padding:0px 15px;
    }
    div.webpromo-content{
        width:49%;
        vertical-align: top;
        display:inline-block;
        margin:0 auto;
        float:left;
        /*padding:5px;*/
        padding:0;
        font-size:@font-size-base;
        &:nth-child(odd){
            margin-right:2%;
        }
        @media (max-width:@screen-sm-max){
            float:none;
            width:100%;
            padding:0;
            height:auto !important;
            margin:10px 0 10px 0 !important;
        }
        &.full{
            width:100%;
        }
        p{
            line-height:1.2 !important;
        }
        img{
            max-width:100%;
        }
    }
}

div.promo-4-container{
    width:100%;
    font-size:0;
    min-height:100px;
    margin-bottom:15px;

    div.promo-4{
        font-size:@font-size-base;
        float:left;
        width:24%;
        min-height:100px;
        height:auto;
        border:2px solid @themeColor;
        padding:5px 15px;
        border-radius:10px;
        -moz-border-radius:10px;
        -webkit-border-radius:10px;
        margin:0 0.5% 0 0.5%;
        color:@themeColor;

        @media (max-width:@screen-sm-max){
            width:49%;
            margin-bottom:5px;
        }

        @media (max-width:@screen-xs-max){
            width:100%;
            margin:0 0 10px 0;
            height:auto !important;
        }
        div.icon-container{
            text-align:center;
            font-size:30px;
            color:@themeColor;
            span.fa-stack i{
                left:5px;
            }
        }
        span.promotitle{
            padding-bottom: 5px;
            text-align:center;
            width:100%;
            line-height:1.2;
            display:block;
            text-transform: uppercase;
            font-weight: bold;
        }
        div.desc-container{
            width:100%;
            padding:5px;
            border-top:1px solid @themeColor;
        }
    }
}

div.promo-full{
    width:100%;
    background:@themeColor-dark;
    /*.gradientLightBlueToDark;*/
    height:auto;
    border-radius:10px;
    -moz-border-radius:10px;
    -webkit-border-radius:10px;
    padding:15px 15px 25px 15px;
    text-align:center;

    span.headline{
        display: block;
        text-align: center;
        font-size:30px;
        color:@color-white;
        @media (max-width: @screen-sm-max){
            font-size:20px;
        }
        @media (max-width: @screen-xs-max){
            font-size:15px;
        }
    }

    a.btn.btn-primary{
        background: none;
        border: 2px solid @color-white;
        font-size: 20px;
        padding: 5px 20px;
        color:@color-white;
        margin: 10px 0 0 0;

        &:hover{
            background:@color-white;
            color:@themeColor-dark;
        }
    }
}

//DVPAL20150309 - webpromo divs for homepage - END

//DVPAL20150309 - Sitemap.aspx layout - START

div.sitemapcontainer {
    font-size: 0;
    div.subcategory, div.subcategory-2{
        font-weight:600;
        width: 100%;
        display: inline-block;
        .item{
            padding-left:10px;
        }
    }
    div.subcategory-2{
        padding-left:10px;
        a{
            &:before{
                content:'- ';
            }
        }

        .item{
            padding-left:10px;
        }
    }


    div.item{
        font-weight: normal;
        font-size: @font-size-base;

        a {
            width: 50%;
            display: inline-block;
            padding: 3px 10px 3px 0;
            float: left;
            &:before{
                content:'- ';
            }
            @media (max-width:@screen-xs-max) {
                width:100%; float:none; padding:0;
            }
        }
    }

    span.categoryhead {
        width: 100%;
        display: block;
        border-bottom: 1px solid @themeColor;
        color: @themeColor;
    }

    div.staticpage, div.category {
        width: 50%;
        display: inline-block;
        vertical-align: top;
        font-weight: bold;
        font-size: 17px;
        float: left;
        padding: 10px 10px 10px 0;

        @media (max-width:@screen-sm-max) {
            width:100%; float:none; padding:0; margin-bottom:10px;
        }

        div.toplevel, div.secondlevel {
            font-weight: normal;
            font-size: @font-size-base;

            a {
                width: 50%;
                display: inline-block;
                padding: 3px 10px 3px 0;
                vertical-align:top;
                float: none;
                &:before{
                    content:'- ';
                }
                @media (max-width:@screen-xs-max) {
                    width:100%; float:none; padding:0;
                }
            }
        }
    }

    div.staticpage {
        width: 100%;

        div.toplevel a{
            width: 25%;

            @media (max-width:@screen-sm-max) {
                width:50%;
            }

            @media (max-width:@screen-xs-max) {
                width:100%; float:none; padding:0;
            }
        }
    }
}

//DVPAL20150309 - Sitemap.aspx layout - END

div.navbuttoncontainer{
    display: block;
    float: none;
    position:relative;
    clear: both;
    width: 100%;
}


div.toplinkscontainer{
    /*padding:5px 0px;*/
    color:@themeColor;
    top:-101px;
    right:0;
    position:absolute;


    @media (max-width:@screen-sm-max){
        padding:2px 5px;
        top:-103px;

    }
    @media (max-width:@screen-xs-max){
        background: #F4F4F4;
        border-radius: 0;
        line-height: 2;
        position:relative;
        background:none;
        top:auto; //DVPAL20150305 - set to auto for IE compatibility
        right:0;
        width:100%;
        float:none;
        text-align:center;
        ul{
            display:inline-block;
            width:100%;
            /*li{
                a{
                    color:@themeColor;
                    :hover{
                        color:@themeColor;
                    }
                }
            }*/
        }
    }
    ul{
        /*padding:0;*/
        margin:0;
    }
}

//DVPAL20150513 - motto container - START
div.mottocontainer{
    float:left;
    line-height:1.2;
    @media (max-width: @screen-xs-max){
        float:none;
        margin-bottom:30px;
        clear:both;
    }
    span.motto{
        /*font-variant:small-caps;*/
        font-weight:500;
        font-size:18px;
        display: block;
        height: 25px;
        color:@themeColor;
        @media (max-width: @screen-xs-max){
            float:none;
            display:block;
            text-align:center;
        }
    }
}
//DVPAL20150513 - motto container - END

//DVPAL20150513 - Search bar on navigation - START
li.searchmodule{
    @media (max-width:@screen-xs-max){
        width:100%;
        float:none;
        padding: 0 15px;
    }
    #searchNavBar{
        float:right;
        padding-top:15px;
        width:auto;
        max-width: 250px;
        min-width: 225px;
        @media (max-width:@screen-xs-max){
            max-width:100%;
            width:100%;
            padding-top:10px;
        }
        #SimpleSearch{
            background:none;
            padding:0;
            border:none;
            margin:0;
            @media (max-width:@screen-sm-max){
                margin-top:-5px;
            }

            div.searchbarerror span.text-danger{
                position:absolute;
                padding:5px;
                background:@background-default;
                -webkit-box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.3);
                -moz-box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.3);
                box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.3);
                z-index:9999;
                border:1px solid @color-grey-lightest;
            }

            div.input-group input.simplesearch{
                float:right;
                height:22px;
                box-shadow:none;
                -moz-box-shadow:none;
                -webkit-box-shadow:none;
                border:1px solid @themeColor;
                color:@themeColor;
                /*background:lighten(@themeColor, 20%);*/
                background:@background-default;
                /*display:none;*/
                padding:0 7px;
                .transition(all 300ms ease-in-out);
                &:focus{
                    background:lighten(@themeColor, 50%);
                }
                @media (max-width:@screen-xs-max){
                    display:block !important;
                    height:34px;
                }
            }
            .input-group{
                span.input-group-btn{
                    a.btn{
                        height:22px;
                        padding: 1px 10px;
                        font-size: 13px;
                        border-radius:0;
                        -moz-border-radius:0;
                        -webkit-border-radius:0;

                        &:focus{
                            background:@themeColor-dark;
                        }

                        @media (max-width: @screen-xs-max){
                          height: auto;
                          padding: 6px 12px;
                          font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}


//DVPAL20150513 - Search bar on navigation - END


//DVPAL20150526 - Page content container for embedded content - START
div.page-content{
    width:100%;
    border-bottom:1px solid @color-grey-light;
    margin-bottom:10px;
}
//DVPAL20150526 - Page content container for embedded content - END

//DVPAL20150528 - captcha for login page - START
#catptchadiv{
    div.Captcha{
        text-align:left;
        width:75%;
        margin-left:25%;
        margin-top:10px;
        margin-bottom:10px;
        padding-left:10px;
        @media (max-width:@screen-xs-max){
            width:100%;
            margin:10px 0;
        }
        div.captchadesc{
            margin-top:10px;
            padding-left: 5px;
        }
        div.captchainput{
            padding-left: 5px;
            .form-control{
                width: 120px;
            }
        }
    }
}
//DVPAL20150528 - captcha for login page - END


//DVPAL20150610 - login tabs are initially hidden and then shown on tab click through javascript
#tabbed_newacct,#tabbed_forgotpwd{
    display:none;
}

//DVPAL20150610 - sales line discount
div.lineDiscountTitle{
    margin:5px 0;
    font-size:@font-size-base;
    font-weight:600;
    color:@color-grey-dark;
    border-bottom:1px solid @color-grey-lighter;
}

//DVPAL20150625 - google maps iframe on contact-us page
div#contact-gmaps{
    width:100%;
    font-size:0;
    display:block;
    iframe{
        border:1px solid @default-border-color;
        width:100% !important;

    }
}

//DVPAL20150710 - parent child div customer info
.pCMsg{
    padding: 3px 8px;
    border: 1px solid @state-danger-text;
    display: inline-block;
    margin: 10px 0;
    color:@state-danger-text;
    font-weight:600;
}

//login_as.aspx table style
table.selectcustomer{
    width:100%;
}

// DVPJL20140502 - Alert.aspx - Start

// - moved style below to body.defaultframe
//defaultframe {
//    overflow-y: hidden;
//}


//moved to the popups.less 
//div.pricealert {
//    width: 100%;
//    height: 100%;
//}
//iframe.pricealertframe {
//    width: 100%;
//    height: 100%;
//}


// DVPJL20140502 - Alert.aspx - End
/*DVPJL20140425*/

/* END layout.less */