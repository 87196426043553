﻿@import "../variables.less";
@import "../../bootstrap/variables.less";

/* START webpagesize.less */
.page-size{
    width:auto;
    display:inline-block; // DVPJL20140516 - NavToNet7 - Adjust to fit parametric_search_item.ascx

    @media (max-width: @screen-sm-max) 
    {
        display: block; // DVPJL20140516 - NavToNet7 - Adjust to fit parametric_search_item.ascx
    }
}
.sortcontrol
{
    .row;
    .sortdropdown
    {
       width: 50%;
       margin:0; // DVPDS - tweaked to position the dropdown left properly
       //margin:0px 0px 0px 7px
    }
    .sortpaging{
        width:50%
    }

}
.sortdropdown
{
          display:inline-block;
          @media (max-width: @screen-md-max) {
            &.col-md-3 {
            }
        }

        @media (max-width: @screen-sm-max) {
            &.col-sm-5 {
            }
        }

       @media (max-width: @screen-lg-min) {
               &.col-lg-6 {
            }
            .clearfix;
            }

        @media (max-width: @screen-xs-max)
        {
           &.col-xs-12 {
                width: 100%;

              
            }
            .clearfix;
        }
}
.sortpaging
{
         display:inline-block;
           @media (max-width: @screen-md-max) {
         
        }

        @media (max-width: @screen-sm-max) {
            &.col-sm-5 {
            }
        }

        @media (max-width: @screen-lg-min) {
               &.col-lg-6 {
            }
            .clearfix;
            }

        @media (max-width: @screen-xs-max)
        {
           &.col-xs-12 {
                width: 100%;
            }

            .clearfix;
        }
}

/* END webpagesize.less */