@import "variables.less";
@import "mixins.less";

/* START FA fixed-width.less */
// Fixed Width Icons
// -------------------------
.@{fa-css-prefix}-fw {
  width: (18em / 14);
  text-align: center;
}

/* END FA fixed-width.less */