@import "../../bootstrap/variables.less";
@import "../variables.less";

/* START search.less */
// defines the Search bar div
//======================================

div.navsearchbar{
    width:200px;
    margin-top:15px;
    @media (max-width: @screen-sm-max){
        display:none;
    }

    .input-group{
        input{
            border: 1px solid @themeColor;
        }
        a.btn, input{
            height:22px;
        }
        a.btn{
            padding-top:0px;
        }

    }
}

div#SearchBar {
	z-index:8900;
    width: 100%;

    .error {
        background:@background-light;
        padding: 0 5px;
        width: 100%;
    }
}

// Used in:Searchbar.ascx 
div#SimpleSearch {
	text-align:left;
	padding:5px;
	background: @simpleSearchBackground;
    border: @simpleSearchBorder;
    margin-bottom: 7px;
}

a.advsearch {
    font-size: @font-size-small;
    color: @themeColor;
    text-decoration: none;
    display: inline-block;
    text-align: right;
    padding: 0;

    &:hover {
        color: @themeColor-dark;
    }
}

/* used on the order history page */
.Orderhistory-Search, .selectcustomer-search {
    width: 100%;
    margin: 5px 0 0 0;
    float: left;

    > div {
        float: left;
        margin-right: 5px;

        @media (max-width:@screen-sm-max) {
            width:100%; 
            margin: 0 0 5px 0;
        }

        a.btn {
            @media (max-width:@screen-sm-max) {
            width:100%; 
            margin: 0 0 5px 0;
            }
        }
    }
}


//================================
// Search Results Page
//================================
table.search-results {
    border: 1px solid @default-border-color-light;
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;

    td {
        border: 1px solid @color-white;
    }
}

h3.search-results {
    font-size: 12px;
    text-align: left;
    margin: 15px 0 0;
}

div.results-top-items {
    margin-top: 30px;
    margin-bottom: 30px;
}

div.results-table {
    margin-bottom: 30px;
}

/* END search.less */