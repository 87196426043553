@import "../../bootstrap/variables.less";
@import "../variables.less";

/* START login.less */
// Used in LoginSummary.aspx
.salesAgentmenu
{
    padding: 5px;
}

// Used for defining DIV in LoginSummary.ascx
div.SalesAgentMenuTop
{
    background:@background-default;
    border:1px solid @default-border-color;
    border-top:none !important;
}

// Used for the Sales Agent title in LoginSummary.ascx
span.SActrltitle
{
    font-size: 18px;
    color: @color-grey-darker;
    font-weight: normal;
    text-align: left;
}

// sets style on the Login table
/*table.login //DVPAL20140723 - commented out, no longer used
{
    .borderStyle-base;
    padding: 0;
    background-color: @color-grey-light;
}*/
dl {
    width:100%;
    overflow:hidden;
    padding: 0.5em;
    // display:inline-block;
}
dt {
    float:left;
    //width:20%; 
    text-align:right;
  
}
dd {
 //float:left;
 //width:80%; 
    text-align:left;
    margin: 0 0 0 110px;
    padding: 0 0 0.5em 0;
    width: 130px; 
    z-index: 999;

}

.logintext {
    width: 65%;  //130px; 
    z-index: 999;
    .borderStyle-base(@default-border-color, 1px, @border-radius-base, solid);
    background: @background-default;
    margin-bottom:2px;
  
  @media (max-width: @screen-sm-max){
      width:100%; //DVPAL20140603 - text field width set to 100% 
  }

}

.loginpassword {
    width: 65%; //130px; 
    z-index: 999;
    .borderStyle-base(@default-border-color, 1px, @border-radius-base, solid);
    background: @background-default;
    height:34px;
    margin-bottom:4px;
      @media (max-width: @screen-sm-max){
      width:100%; //DVPAL20140603 - text field width set to 100% 
  }
}
.loginform{
    display: block;
    /*padding: 6px 12px;*/
    font-size: @font-size-base;
    line-height: 1.42857143;
    color: @color-grey-dark;
}

// DVPAL20140603 - added to provide easy way for the login button to be lined up with input fields without messing up margins
.loginButtonOffset {
    width: 75%;
    margin-left: 25%;

    @media (max-width: @screen-xs-max) {
        width:100%; //DVPAL20140612 - offset is cleared in XS viewport
        margin:0;
    }

    a.btn {
        margin-right:5px;
        @media (max-width: @screen-sm-max) {
            width:100%; //DVPAL20140612 - button width set to 100%
            margin-right:0;
            margin-bottom: 5px;
        }
    }
}

.captch{
    align-items:center;
    float:left;
}

@media (max-width: @screen-xs-max) {
    //.col-xs-12
    .loginxsmall {
        float: left;
        margin-right: 20px;
        text-align: left;
    }
}

@media (min-width: @screen-xs-max) {
    .loginsmall{
        
        text-align:right;
        padding-right:0px;
        vertical-align:middle;

    }
}


.logintab{
    background: @background-light;
}
/* END login.less */