@import "../../bootstrap/variables.less";
@import "../variables.less";

/* START whatshot.less */
// Used in WhatsHot.ascx
div.whatshotmenu {
    margin-bottom:10px;
	text-align:center;
	padding: 1px;
	border: 1px solid @default-border-color;
    border-top:none;
	background-color:@background-default;
    
    .hotitem {
        margin:0;
        padding: 10px;
        width: auto;
        display: inline-block;
        /*min-width:140px;*/
        width:100%;
        font-size:0;
        /*.hoverShadow;*/

        #showmore{
            color:@themeColor;
            width:70%;
            display:inline-block;
            vertical-align:top;
            a.moreinfo{
                text-align:left;
                font-size:@font-size-base;
            }
        }

        .hotitem-image {
            margin:0 0 10px 0;
            width:30%;
            display:inline-block;
        }

        .hotitem-image a img {
            width:60px;
            height:auto;
        }
    }
}
/* END whatshot.less */