@import "../variables.less";

/* START showmore.less */
// used for More Info link on:
//  WhatsHot.ascx, 
//  RecentlyViewed.ascx, 
//  LeftCartSummary.ascx
// DVPDS- changed showmore from ID to Class
div.showmore, div#parametricclear {
	background-color:@themeColor;
	//border:solid 1px #c8c8b8;
    text-align: center;
    margin-top:5px;
    .transition(all 0.15s ease-in-out);

    &:hover{
        background-color:@themeColor-dark;
    }
}
div#parametricclear{
    margin:1px;
}

// DVPDS - no longer used
//div#showmorepm {
//	background-color:#3399cc;
//	//border:solid 1px #123579;
//	border-width:1px 0 1px 0;
//    text-align: center;
//    margin-top:5px;
//}


// sets style on Left Cart Summary, recently viewed and what's hot control links
// DVPDS moved from layout.less
a.moreinfo
{
    font-size: 11px;
    color: @leftCategoryBodyLinkColor;
    padding: 3px;
    display: block;
    position: relative;
    text-decoration: none;
}

a.moreinfo.whlink{
    color:@themeColor;
    &:hover{
        color:@themeColor-dark;
    }
}
/* END showmore.less */