﻿@import "../../bootstrap/variables.less";
@import "../../bootstrap/mixins.less";
@import "../../bootstrap/buttons.less";
@import "../../bootstrap/forms.less";
@import "../../base/forms.less";

@import "../variables.less";

.cfg-focused{
        .option{
            border:1px solid @themeColor !important;
            border-color:@themeColor !important;
        }
}

.option{
    -webkit-transition:all 200ms ease-in-out;
    transition:all 200ms ease-in-out;
    -moz-transition:all 200ms ease-in-out;
}

.dropShadow(){        
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);
}

/* START configurator.less */
div.option-error, .choice-required,.attribute-qty-error{
    color:@state-danger-text;
    font-weight:600;
    font-size:@font-size-small;
}

div.configurator {
    div.attribute-header-row{
        background:none !important;
    }
}

//DVPAL20150511 - exclusion reason display
div.option-exclusion{
    width:100%;
    text-align:right;
    color:@state-danger-text;
    font-size:@font-size-small;
    .option-exclusion-season{
        &:before{
            font-family: FontAwesome;
            content: "\f06a\0020";
            font-size:14px;
        }
    }
}

div.choice-exclusion{
    width:100%;
    text-align:right;
    margin-bottom:10px;
    color:@state-danger-text;
    font-size:@font-size-small;
    div.choice-exclusion-season{
        &:before{
            font-family: FontAwesome;
            content: "\f06a\0020";
            font-size:14px;
        }
    }
}

//DVPAL20150114 - cart review
div.cart-cfg-review{
    div.section-wrapper{
        border:none;
        background:none;
        margin:0;
        border-radius:0;

        div.section-header{
            background:none;
            padding:2px 0px;
            a.expand-toggle{
               float:right;
               margin-top:-40px;
               height:15px;

               @media (max-width:@screen-sm-max){
                    width: 100%;
                    display: block;
                    text-align: center;
                    float:none;
                    height:auto;
                    margin:0;
               }
            
            span{
                margin-top:-9px;
                display:block;
                    @media (max-width:@screen-sm-max){
                    display:inline-block;
                    }
                }   
            }
        }
    }
    div.section-body-comment{
        display:none;
        padding:0;

        div.configurator-review{
            margin-bottom:10px;
            div.review-total{
                display:none;
            }

            div.review-step-container{
                padding:2px;
                div.review-choice-container{
                    border:none;
                    margin:0;
                    padding:0px 0px 0px 10px;
                    width:100%;
                    div.review-choice, div.review-value{
                        font-size:@font-size-small;
                        vertical-align:top;
                        margin:0 0 0 10px;
                        display:inline-block;
                    }
                    div.review-option-container{
                        margin:0;
                        display:inline-block;
                        width:auto;
                        div.review-option{
                            width:auto;
                            display:inline-block;
                            margin:0 0 0 5px;
                            vertical-align: top;
                            &:before{
                                vertical-align: top;
                                line-height: 1;
                            }
                        }
                        div.review-option-image, div.review-option-extended{
                            display:none;
                        }
                    }
                }
                div.review-step{
                    font-weight:700;
                    font-size:@font-size-small;
                    margin-bottom:0;
                }
            }
            div.header-review{
                display:none;
                div.header-description-review-wrapper,div.header-review-image{
                    display:none;
                }
            }
        }
    }
    
}

//DVPAL20150113 - tooltip div
div.configurator-tool-tip{
    vertical-align:top;
    margin-left:10px;
    line-height:18px;
    display:inline-block;

    .popover-content{
        font-weight:normal;
    }
}

div.header-description, div.step-description, div.choice-description{
    display:inline-block;
}

div.step-extended, div.choice-extended{
    margin-bottom:15px;
}

div.configurator-total{
    margin-top:10px;
}

//DVPAL20150109 - breadcrumbs

 div.breadcrumb-side {
     div.configurator-total-label{
         float:left;
     }
    div.configurator-total{
        margin:0;
        font-weight:600;
        float:right;
        text-align:right;
        @media (max-width:@screen-sm-max){
            float:none;
        }
        div.configurator-vattag{
            display:inline-block;
            margin-left:10px;
            font-size:@font-size-small;
            font-weight:300;
        }
     }
    #configsummary{
        font-weight:600;
        font-size:15px;
        text-align:center;
        margin-bottom:10px;
        border-bottom:1px solid @default-border-color;
        }
    div.breadcrumb-side-step-container{
    .breakword;
    border-bottom: 1px solid @default-border-color;
    margin-bottom: 10px;
    }
    border: 1px solid @default-border-color;
    padding: 10px;
    width: 20%;
    display: inline-block;
    margin-top: -1px;
    vertical-align: top;

    div.breadcrumb-side-step {
        /*display: none;*/
        font-weight:bold;
        text-transform:uppercase;
    }

    div.breadcrumb-side-choice {
        font-weight: bold;
    }

     div.breadcrumb-side-value{
         padding:0px 0px 0px 5px; 
         vertical-align:text-bottom;
         display:inline-block;
     }
    div.breadcrumb-side-option {
        padding:0px 0px 0px 5px;
        vertical-align:text-bottom;
         display:inline-block;

        &:before{
            content:"\00BB\0020";
            vertical-align:text-bottom;
        }
        &:last-child{

        }

        div.breadcrumb-side-attributes{
            &:before{
            content:"\00BB\0020\00BB\0020";
            vertical-align:text-bottom;
            }
        }
    }
}

div.breadcrumb-top{
        padding:10px;
        border:1px solid @default-border-color;
        margin-bottom:15px;
        .Step, .top-configurator-name, .top-step{
            .breakword;
            display:inline-block;
            margin: 0 3px;
        }
        .top-step{
            font-weight:bold;
        }
        .Step, .top-step{
            &:before{
                content:"\003e\003e\00a0";
            }
        }
    }

//DVPAL20150109 - review page
div.configurator-review{
    div.header, div.step-header-container, div.breadcrumb-top, div.header-review{
    box-shadow: none;
    -moz-box-shadow:none;
    -webkit-box-shadow:none;
}
    div.review-total{
            div.configurator-vattag{
        font-weight:normal;
        font-size:@font-size-base;
        display:inline-block;
        margin-left:10px;
        }
    }
    div.review-total-label{
        display:none;
    }
    div.review-step-container,div.review-baseitem-container{
        /*padding:0 0 0 5px;*/
        padding:10px;
        border:1px solid @default-border-color;
        margin-bottom:-1px;
    }
    div.review-step{
        text-transform:uppercase;
    }
    div.review-choice-container{
        .breakword;
        vertical-align:top;
        display:inline-block;
        margin-left:-1px;
        width:25%;
        border:1px solid @default-border-color;
        padding:10px;
        margin-bottom:-1px;

        @media (max-width:@screen-sm-max){
            width:100% !important;
        }
        div.review-choice{
            margin-bottom:5px;
            font-weight:600;
            font-size:@font-size-h5;

        }
        div.review-option-container{
            width:100%;
            
            div.review-option{
                width:100%;
                margin-bottom:10px;
                &:before{
                content:"\00BB\0020";
                vertical-align:text-bottom;
                }
            }
            div.review-value{
                margin-left:20px;
            }
            div.review-option-image{
                width:20%;
                display:inline-block;
                vertical-align:top;
                padding-right:10px;
                img{
                    border:1px solid @default-border-color;
                    width:100%;
                }
            }
            div.review-option-extended{
                width:80%;
                display:inline-block;
                vertical-align:top;
            }
        }
    }

}

/* DVPAL20150106 - color swatch class, insert "colorpicker" into the choice*/
div.choice.colorpicker div.option-wrapper div.option{
    width: 33%; /*DVPAL20150106 - 3 per row*/
    display: inline-block;
    vertical-align: top;
    border:0;
    margin:0;
    @media (max-width:@screen-md-max){
        width:25%;
    }
    @media (max-width:@screen-sm-max){
        width:50%;
    }
    @media (max-width:@screen-xs-max){
        width:100%;
    }

    div.option-extended{
        display:none;
    }

    span[disabled=disabled]{
        opacity:0.25;
        filter: alpha(opacity=25);
        zoom:1;
    }
    /*DVPAL20150106 - hide image and radio button*/
    div.option-image{
        display:none;
    }

    input[type=radio] {
        /*display: none;*/
        margin: 10px;
    }

    input[type=radio]:disabled + label{
        text-decoration:line-through;
        cursor:no-drop;
    }

    input[type=radio] + label {
        display: inline-block;
        &:hover{
        color:@link-hover-color;

        }
    }

    input[type=radio]:checked + label {
        color:@color-blue;
        text-decoration:underline;
    }

        input[type=radio]:checked + label img{
            border:2px solid @color-blue;
        }
    label {
        font-size:@font-size-small !important;
        cursor:pointer;
        img {
            .transition(all 150ms ease-in-out);
            border:2px solid @default-border-color;
            margin-right:10px;
        }
    }
}


/*Default Configurator stylesheet*/

/*Item by attribute - START*/



div.attribute-filter{
    width:24%;
    display:inline-block;
    margin-right:1%;
    @media (max-width:@screen-xs-max){
        width:100%;
    }
}

div.responsive-attribute{
    overflow:auto;
    width:100%;
}

div.attribute-table{
    margin-top:15px;
    display:table;
    border-collapse:collapse;
    width:100%;
    border:1px solid @default-border-color;

    div.attribute-header-row{
        display:table-header-group;
        font-weight:bold;
        height:auto;
        color:@color-black;
        text-align:center;
        background:@color-grey-lighter;
        border:1px solid @default-border-color;
        div.col-attribute-header{
            border-right:1px solid @default-border-color;
            display:table-cell;
            padding:3px;
            vertical-align:top;
            @media (max-width:@screen-sm-max){
                min-width:100px;
            }
        }
    }
    div.attribute-row{
        display:table-row;
        padding:3px;
        text-align:center;
        overflow:auto;
        div.col-attribute, div.col-item{
            display:table-cell;
            padding:3px 3px 10px 3px;
            vertical-align:middle;
            border:1px solid @default-border-color;
        }
        div.col-attribute{
            background:@color-grey-lighter;
            font-weight:bold;
            height:auto;
            color:@color-black;
            text-align:center;
            &.fixedscroll{
                position: absolute;
                /*margin-top: -1px;*/
                margin-left: -1px;
                padding-top: 30px;
            }
        }
        div.col-item{
            vertical-align:bottom;
            span{
                color:@state-danger-text !important;
            }
            input[type="text"]{
                max-width:50px;
                margin:0 auto;
                border-radius:0;
                -webkit-border-radius:0;
                -moz-border-radius:0;
                box-shadow:none;
                -webkit-box-shadow:none;
                -moz-box-shadow:none;
            }
            div.col-item-id{
                display:none;
            }
            div.col-item-price{
                div.configurator-price{
                    display:inline-block;   
                    width:100%;
                    div.configurator-vattag{
                      display: inline-block;
                      font-size: 10px;
                      font-weight: 300;
                      margin-left:5px;
                    }
                }
            }
        }
    }

}

/*Item by attribute - END*/

/*Buttons*/
div.linkbutton{
    margin-top:15px;
    width:80%;
    @media (max-width:@screen-sm-max){
        width:100%;
    }
    a{
        @media (max-width:@screen-xs-max){
            width:100% !important;
            margin-bottom:5px !important;
            float:none !important;
        }
    }
    a.Next, a.Back, a.add{
            .btn-primary;
            .btn;
            &:hover, &:focus{
                color:@color-white;
        }
    }

    a.Next{
        float:right;
    }
    a.Back{
        float:left;
    }
}

div.configurator-review-button{
    width:100%;
    a{
        @media (max-width:@screen-xs-max){
            width:100% !important;
            margin-bottom:5px !important;
            float:none !important;
        }
    }
    div.configurator-addtocart{
        display:inline-block;
        margin-right:15px;
        @media (max-width:@screen-xs-max){
            width:100% !important;
        }
        a{
            .btn-success;
            .btn;
            &:hover, &:focus{
                color:@color-white;
            }
        }
    }

    div.configurator-back, div.configurator-cancel{
        display:inline-block;
        margin-right:15px;
        @media (max-width:@screen-xs-max){
            width:100% !important;
        }
        a{
            .btn-primary;
            .btn;
            &:hover, &:focus{
                color:@color-white;
            }
        }
    }
}

div.configurator, div.configurator-review{
    width:100%;
    p{
        font-size:@font-size-base;
        line-height:1.2;
        margin:0;
    }
    div.step-header-container{
        div.step-image{
            img{
                width:100%;
            }
        }
    }
    div.review-baseitem-container{
        div.review-baseitem-label{
            font-weight:600;
        }
        div.review-baseitem-label, div.review-baseitem-description, div.review-baseitem-id{
            display:inline-block;
            margin-right:10px;
        } 
    }
    div.header, div.step-header-container, div.choice, div.header-review, div.review-total{
        padding:10px;
        border:1px solid @default-border-color;
        margin-bottom:15px;
    }
    div.step-header-container{
        width:80%;
        @media (max-width:@screen-sm-max){
            width:100%;
        }
    }
    div.step-description, div.review-step, div.review-total{
        font-size:@font-size-h4;
        font-weight:600;
        margin-bottom:15px;
        .breakword;
    }
    div.header-description, div.header-review-description{
        h3{
            .breakword;
            font-size:@font-size-h4;
            font-weight:bold;
            margin-bottom:15px;
        }
    }
    div.choice-header{
        font-size:@font-size-h5;
        font-weight:bold;
        margin-bottom:15px;
    }

    div.header-image, div.Step-image, div.choice-image, div.header-review-image{
        margin-bottom:10px;
        img{
            width:100%;
        }
    }

    div.choice{
        width:80%;
        display: inline-block;
        vertical-align: top;
        margin:-1px 0 15px 0;
        @media (max-width:@screen-sm-max){
            width:100%;
        }
            h4{
                .breakword;
                font-size:16px;
                font-weight:600;
            }
        div.option{
            /*width: 50%;*/
            display: inline-block;
            border: 1px solid @default-border-color;
            vertical-align: top;
            margin: -1px 0 0 -1px;
            padding:10px;
            &.Information{
                .option-type-information{
                    float:right;
                }
            }
        }
    }
    
    div.option-wrapper{
        select, input[type="text"]{
            height:auto !important;
            .form-control;
        }
    }
    div.review-total{

    }
}

div.header, div.step-header-container, div.breadcrumb-top, div.header-review{
    .dropShadow();
}


div.breadcrumb-side{
    .dropShadow();
    border: 1px solid @default-border-color;
    padding: 10px;
    width: 19%;
    display: inline-block;
    margin-top: -1px;
    vertical-align: top;
    float: right;
    @media (max-width:@screen-sm-max){
        width:100%;
        margin-bottom: 15px;
        float:none;
    }
}

/*DVPAL20150109 - default list view*/
div.choice{
    width:80%;
    display:inline-block;
    margin:-1px 0 15px 0;
    .dropShadow();

    /*DVPAL20150109 - choice dropdown*/
    &.DropDown{
        div.option-wrapper{
            div.option{
                padding:0;
                border:0;
            }
        }
    }


    div.option-wrapper{
        div.option-input-error{
            margin-top:10px;
        }
        div.option{
            /*DVPAL20150105 - offsetting margins to even out borders, this is for 2 options per row*/
            /*&:nth-child(odd){
                margin-left:0;
            }*/
            margin-left:1px;
            width:100%;
            div.configurator-price-container{
                float:right;
                /*@media (max-width: @screen-xs-max){
                    float:none;
                }*/
                .configurator-price{
                    /*display: inline-block;*/
                    font-weight: bold;
                    font-size: @font-size-base;
                    .configurator-vattag{
                        display: inline-block;
                        margin-left: 5px;
                        font-weight:300;
                    }
                }
                .configurator-uom{
                    float:right;
                    display:inline-block;
                    margin-left:10px;
                    font-size: @font-size-small;
                }
            }


            &.None{
                    
            }

            &.ItemsbyAttributes{
                /*border:0;*/
            }

            &.Attributes{
                div.option-type-attribute{
                    margin-top:15px;

                }
                
                    table{
                        width:100%;
                        tr{
                            display:inline-block;
                            width:100%; 
                            label{
                                padding-left:5px;
                                vertical-align:top;
                            }
                            input{
                                vertical-align:top;
                                margin-top: 0;
                            }
                        }
                    }
            }

                span[disabled=disabled]{
                    label{
                        text-decoration:line-through;
                        color:@gray-light;
                    }
                }

            div.option-select{
                width:100%;
                .configurator-price{
                    /*display:inline-block;*/
                    padding-left:15px;
                    vertical-align:text-bottom;
                }
                label{
                    font-size:@font-size-base;
                    font-weight:600;
                    vertical-align:top;
                    padding-left:5px;
                    .breakword;
                }
            }
            div.option-image{
                width:10%;
                display: inline-block;
                vertical-align: top;
                padding-right:10px;
                margin-bottom:10px;
                @media (max-width:@screen-xs-max){
                    width:20%;
                }
                img{
                    width:100%;
                }
            }
            div.option-extended{
                width:90%;
                display: inline-block;
                vertical-align: top;
                @media (max-width:@screen-xs-max){
                    width:80%;
                }
                &.no-img{
                    padding-left:0;
                    width:100%;
                }
            }
        }
    }
}




/* STACKED LIST LAYOUT - BEGIN */


//CHOICE-ONLY
div.configurator div.choice.stacklist{
    div.choice-header-container{
            width:30%;
            display:inline-block;
            vertical-align:top;
            padding-right:10px;
            @media (max-width:@screen-sm-max){
                width:100%;
            }
        }

        &.DropDown{
            div.option-wrapper{
                width:70%;
                div.option.dropdown{
                    width:100%;
                }
                @media (max-width:@screen-sm-max){
                    width:100%;
                }
            }
        }
        div.option-wrapper{
            vertical-align:top;
            width:70%;
            display:inline-block;
            @media (max-width:@screen-sm-max){
                width:100%;
            }
            div.option{
                margin: -1px 0 0 1px;
                width:100%;
                height:auto !important;
                div.option-image{
                width:20%;
                display:inline-block;
                vertical-align:top;

                    img{
                        width:100%;
                        vertical-align:top;
                    }
                }
                div.option-extended{
                width:80%;
                display:inline-block;
                padding-left:15px;
                &.no-img{
                    padding-left:0;
                    width:100%;
                }
                    p{
                        line-height:1.3;
                    }
                }

                @media (max-width:@screen-sm-max){
                    width:100%;
                }
            }

        }
        &.colorpicker{
            div.option-wrapper{
                div.option{
                    border: 1px solid @default-border-color;
                    width:50%;
                    margin:-1px 0 0 -1px;
                    @media (max-width:@screen-md-max){
                        width:50%;
                    }
                    @media (max-width:@screen-sm-max){
                        width:100%;
                    }

                    div.option-extended{
                        display:none;
                    }
                }
            }

        }
}



div.stacklist{
    div.choice{
        div.choice-header-container{
            width:30%;
            display:inline-block;
            vertical-align:top;
            padding-right:10px;
            @media (max-width:@screen-sm-max){
                width:100%;
            }
        }

        &.DropDown{
            div.option-wrapper{
                width:70%;
                div.option.dropdown{
                    width:100%;
                }
                @media (max-width:@screen-sm-max){
                    width:100%;
                }
            }
        }
        div.option-wrapper{
            vertical-align:top;
            width:70%;
            display:inline-block;
            @media (max-width:@screen-sm-max){
                width:100%;
            }
            div.option{
                margin: -1px 0 0 1px;
                width:100%;
                height:auto;
                div.option-image{
                width:20%;
                display:inline-block;
                vertical-align:top;

                    img{
                        width:100%;
                        vertical-align:top;
                    }
                }
                div.option-extended{
                width:80%;
                display:inline-block;
                padding-left:15px;
                &.no-img{
                    padding-left:0;
                    width:100%;
                }
                    p{
                        line-height:1.3;
                    }
                }

                @media (max-width:@screen-sm-max){
                    width:100%;
                }
            }

        }
        &.colorpicker{
            div.option-wrapper{
                div.option{
                    border: 1px solid @default-border-color;
                    width:50%;
                    margin:-1px 0 0 -1px;
                    @media (max-width:@screen-md-max){
                        width:50%;
                    }
                    @media (max-width:@screen-sm-max){
                        width:100%;
                    }

                    div.option-extended{
                        display:none;
                    }
                }
            }

        }
    }
}
/* STACKED LIST LAYOUT - END */

/* STACKED TILE-GRID LAYOUT - BEGIN */

//CHOICE ONLY

div.configurator div.choice.stacktile{
    div.choice-header-container{
            width:30%;
            display:inline-block;
            vertical-align:top;
            padding-right:10px;
            @media (max-width:@screen-sm-max){
                width:100%;
            }
        }

        &.DropDown{
            div.option-wrapper{
                width:70%;
                div.option.dropdown{
                    width:100%;
                }
                @media (max-width:@screen-sm-max){
                    width:100%;
                }
            }
        }
        div.option-wrapper{
            vertical-align:top;
            width:70%;
            display:inline-block;
            @media (max-width:@screen-sm-max){
                width:100%;
            }
            div.option{
                margin: -1px 0 0 -1px;
                width:50%;
                display:inline-block;
                height:auto;
                div.option-image{
                width:20%;
                display:inline-block;
                vertical-align:top;

                    img{
                        width:100%;
                        vertical-align:top;
                    }
                }
                div.option-extended{
                width:80%;
                display:inline-block;
                padding-left:15px;
                &.no-img{
                    padding-left:0;
                    width:100%;
                }
                    p{
                        line-height:1.3;
                    }
                }

                @media (max-width:@screen-sm-max){
                    width:100%;
                }
            }

        }
        &.colorpicker{
            div.option-wrapper{
                div.option{
                    border: 1px solid @default-border-color;
                    width:50%;
                    margin:-1px 0 0 -1px;
                    @media (max-width:@screen-md-max){
                        width:50%;
                    }
                    @media (max-width:@screen-sm-max){
                        width:100%;
                    }

                    div.option-extended{
                        display:none;
                    }
                }
            }

        }
}

div.stacktile{
    div.choice{
        div.choice-header-container{
            width:30%;
            display:inline-block;
            vertical-align:top;
            padding-right:10px;
            @media (max-width:@screen-sm-max){
                width:100%;
            }
        }

        &.DropDown{
            div.option-wrapper{
                width:70%;
                div.option.dropdown{
                    width:100%;
                }
                @media (max-width:@screen-sm-max){
                    width:100%;
                }
            }
        }
        div.option-wrapper{
            vertical-align:top;
            width:70%;
            display:inline-block;
            @media (max-width:@screen-sm-max){
                width:100%;
            }
            div.option{
                margin: -1px 0 0 -1px;
                width:50%;
                display:inline-block;
                height:auto;
                div.option-image{
                width:20%;
                display:inline-block;
                vertical-align:top;

                    img{
                        width:100%;
                        vertical-align:top;
                    }
                }
                div.option-extended{
                width:80%;
                display:inline-block;
                padding-left:15px;
                &.no-img{
                    padding-left:0;
                    width:100%;
                }
                    p{
                        line-height:1.3;
                    }
                }

                @media (max-width:@screen-sm-max){
                    width:100%;
                }
            }

        }
        &.colorpicker{
            div.option-wrapper{
                div.option{
                    border: 1px solid @default-border-color;
                    width:50%;
                    margin:-1px 0 0 -1px;
                    @media (max-width:@screen-md-max){
                        width:50%;
                    }
                    @media (max-width:@screen-sm-max){
                        width:100%;
                    }

                    div.option-extended{
                        display:none;
                    }
                }
            }

        }
    }
}
/* STACKED TILE-GRID LAYOUT - END */

/* TILE-GRID LAYOUT - BEGIN */

//CHOICE ONLY
div.choice.tile {
&.DropDown{
            div.option-wrapper, div.option.dropdown{
                width:100%;
            }
        }
        div.option-wrapper{
            padding-left:2px;
            div.option{
                margin: -1px 0 0 -1px;
                width:50%; //2 tiles per row

                div.option-image{
                width:30%;
                display:inline-block;
                vertical-align:top;

                    img{
                        width:100%;
                        vertical-align:top;
                    }
                }
                div.option-extended{
                width:70%;
                display:inline-block;
                padding-left:15px;
                &.no-img{
                    padding-left:0;
                    width:100%;
                }
                    p{
                        line-height:1.3;
                    }
                }

                @media (max-width:@screen-sm-max){
                    width:50%;
                }
                @media (max-width:@screen-xs-max){
                    width:100%;
                }
            }

        }
        &.colorpicker{
            div.option-wrapper{
                div.option{
                    width:33%;
                    @media (max-width:@screen-md-max){
                        width:25%;
                    }
                    @media (max-width:@screen-sm-max){
                        width:50%;
                    }
                    @media (max-width:@screen-xs-max){
                        width:100%;
                    }
                    div.option-extended{
                        display:none;
                    }
                }
            }

        }
}

div.tile{
    div.choice{
        &.DropDown{
            div.option-wrapper, div.option.dropdown{
                width:100%;
            }
        }
        div.option-wrapper{
            padding-left:2px;
            div.option{
                margin: -1px 0 0 -1px;
                width:50%; //2 tiles per row

                div.option-image{
                width:30%;
                display:inline-block;
                vertical-align:top;

                    img{
                        width:100%;
                        vertical-align:top;
                    }
                }
                div.option-extended{
                width:70%;
                display:inline-block;
                padding-left:15px;
                &.no-img{
                    padding-left:0;
                    width:100%;
                }
                    p{
                        line-height:1.3;
                    }
                }

                @media (max-width:@screen-sm-max){
                    width:50%;
                }
                @media (max-width:@screen-xs-max){
                    width:100%;
                }
            }

        }
        &.colorpicker{
            div.option-wrapper{
                div.option{
                    width:33%;
                    @media (max-width:@screen-md-max){
                        width:25%;
                    }
                    @media (max-width:@screen-sm-max){
                        width:50%;
                    }
                    @media (max-width:@screen-xs-max){
                        width:100%;
                    }
                    div.option-extended{
                        display:none;
                    }
                }
            }

        }
    }
}
/* TILE-GRID LAYOUT - END */



/*DVPAL20150303 - choice-only list view*/
div.configurator div.choice{
    &.list{
    &.DropDown{
        div.option-wrapper{
            div.option{
                padding:0;
                border:0;
            }
        }
    }

    div.option-wrapper{
        div.option-input-error{
            margin-top:10px;
        }
        div.option{
            height:auto !important;
            margin-left:1px;
            width:100%;
            &.None{
                    
            }

            &.ItemsbyAttributes{
                /*border:0;*/
            }

            &.Attributes{
                div.option-type-attribute{
                    margin-top:15px;

                    table{
                        width:100%;
                        tr{
                            display:inline-block;
                            width:100%; 
                            label{
                                padding-left:5px;
                                vertical-align:top;
                            }
                            input{
                                vertical-align:top;
                                margin-top: 0;
                            }
                        }
                    }
                }
            }

                span[disabled=disabled]{
                    label{
                        text-decoration:line-through;
                        color:@gray-light;
                    }
                }

            div.option-select{
                width:100%;
                .configurator-price{
                    /*display:inline-block;*/
                    padding-left:15px;
                    vertical-align:text-bottom;
                }
                label{
                    font-size:@font-size-base;
                    font-weight:bold;
                    vertical-align:top;
                    padding-left:5px;
                }
            }
            div.option-image{
                width:10%;
                display: inline-block;
                vertical-align: top;
                padding-right:10px;
                margin-bottom:10px;
                @media (max-width:@screen-xs-max){
                    width:20%;
                }
                img{
                    width:100%;
                }
            }
            div.option-extended{
                width:90%;
                display: inline-block;
                vertical-align: top;
                @media (max-width:@screen-xs-max){
                    width:80%;
                }
                &.no-img{
                    padding-left:0;
                    width:100%;
                }
            }
        }
    }
}
    }



/*DAVID PHILLIPS CUSTOM STYLES - BEGIN*/


div.davidphillips {
    div.Step-header{
        border:1px solid @default-border-color;
        padding:10px;
        font-size:14px;
        text-transform:uppercase;
    }
    div.choice{
        padding:10px;
        border:none;
        width:80%;
        margin:0 0 15px 0;
        div.choice-header{
            display:none;
        }
        div.option-wrapper{
            padding-left:2px;
        }
        div.option{
            width:50%;
            margin:-1px 0 0 -1px;
            display:inline-block;
            border:1px solid @default-border-color;
            padding:10px;
            min-height: 150px;
            vertical-align:top;
            div.option-image{
                width:30%;
                display:inline-block;
                vertical-align:top;

                img{
                    width:100%;
                    vertical-align:top;
                }
            }
            div.option-extended{
                width:70%;
                display:inline-block;
                padding-left:15px;
                p{
                    line-height:1.3;
                }
            }

            div.option-select{
                width:100%;
                label{
                    text-transform:uppercase;
                    font-weight:bold;
                }
                input{
                    margin-right:10px;
                    margin-top: 0;
                    vertical-align: top;
                }
            }
        }
    }
    div.header-description-wrapper {
        width:100%;
        padding:10px;
        .dropShadow();
        margin-bottom:15px;
    }
    div.header-description{
        h3{
            font-weight:bold;
            text-transform:uppercase;
        }
    }
}



/*DAVID PHILLIPS CUSTOM STYLES - END*/



/*DVPAL20150331 - CONFIGURATOR LAYOUT STYLES - BEGIN*/
#MainSection > div.configurator-review{
    div.review-step-container, div.review-baseitem-container{
        margin-bottom:15px;
    }
}


div.configurator, #MainSection > div > div.configurator-review {
    // THEME PRESETS
    &.stitch{
        div.review-baseitem-container, div.review-total, div.review-step-container, div.breadcrumb-side, div.header, div.step-header-container, div.breadcrumb-top, div.header-review,div.choice {
            background:none !important;
            border:2px dashed @color-grey-light;
            box-shadow: none;
            padding:10px;
        }
        div.breadcrumb-side-step-container{
            border-bottom-style:dashed!important;
        }
        div.option, select, div.review-choice-container{
            border-style:dashed!important;
        }
        #configsummary{
            border-bottom-style:dashed!important;
            border-bottom-width:2px;
        }
        select{
            border-radius:0 !important;
            box-shadow:none;
        }
        div.col-attribute{
            background:@color-white !important;
        }
    }
    &.ntn7{
        div.review-baseitem-container, div.review-total, div.review-step-container, div.breadcrumb-side, div.header, div.step-header-container, div.breadcrumb-top, div.header-review,div.choice {
            background:#EEF7FF;
            border-width:4px 1px 1px 1px !important;
            border-color:@themeColor-light;
            border-radius:0;
            box-shadow:none;
            border-top-color:@themeColor!important;
            padding:10px;
        }
        div.col-attribute{
            background:#EEF7FF !important;
        }
        div.option, div.review-choice-container, div#configsummary, div.responsive-attribute div, div.breadcrumb-side-step-container{
            border-color: lighten(@themeColor-light,10%)!important;
        }
        select, input[type="text"]{
            border-radius:0 !important;
            border-color: lighten(@themeColor-light,10%)!important;
            color:@themeColor!important;
            box-shadow:none;
        }
    }

    &.modern{
        div.review-baseitem-container, div.review-total, div.review-step-container, div.breadcrumb-side, div.header, div.step-header-container, div.breadcrumb-top, div.header-review,div.choice {
            background:@color-grey-lighter;
            border:1px solid @color-grey-light;
            box-shadow: inset 0 1px 0 rgba(255,255,255,0.7), 0 1px 1px rgba(0,0,0,0.1), 0 0 6px 0px rgba(0,0,0,0.1);
            text-decoration: none;
            text-shadow: 0 1px rgba(255,255,255,0.7);
        }
        select{
            border-radius:0 !important;
        }
    }
    &.elegance{
        div.review-baseitem-container, div.review-total, div.review-step-container, div.breadcrumb-side, div.header, div.step-header-container, div.breadcrumb-top, div.header-review,div.choice {
            box-shadow:none;
            -moz-box-shadow:none;
            -webkit-box-shadow:none;
            background:@color-grey-lightest;
            border-width:1px 1px 4px 1px !important;
            border-color:@color-grey-light2 !important;
            border-radius:10px;
            -moz-border-radius:10px;
            -webkit-border-radius:10px;
            padding:10px;
        }
        div.review-step-container, div.review-total{
            margin-top:15px;
        }
        
    }
    // OPTIONS
    // BORDERS
    &.noshadow{
        div.review-baseitem-container, div.review-total, div.review-step-container, div.breadcrumb-side, div.header, div.step-header-container, div.breadcrumb-top, div.header-review,div.choice {
            box-shadow:none;
        }
    }
    &.borderaccent{
        div.review-baseitem-container, div.review-total, div.review-step-container, div.breadcrumb-side, div.header, div.step-header-container, div.breadcrumb-top, div.header-review,div.choice {
            border-width:1px 1px 4px 1px !important;
            border-color:@color-grey-light !important;
        }
    }
    &.noborder{
        div.review-baseitem-container, div.review-total, div.review-step-container, div.breadcrumb-side, div.header, div.step-header-container, div.breadcrumb-top, div.header-review,div.choice {
            border-width:0px !important;
            box-shadow:none;
            -moz-box-shadow:none;
            -webkit-box-shadow:none;
        }
    }
    &.borderdashed{
        div.review-baseitem-container, div.review-total, div.review-step-container, div.breadcrumb-side, div.header, div.step-header-container, div.breadcrumb-top, div.header-review,div.choice {
            border-width:1px !important;
            box-shadow:none;
            -moz-box-shadow:none;
            -webkit-box-shadow:none;
            border:1px dashed @color-grey !important;
        }
    }

    &.roundedright{
        div.review-baseitem-container, div.review-total, div.review-step-container, div.breadcrumb-side, div.header, div.step-header-container, div.breadcrumb-top, div.header-review,div.choice {
            border-radius:0 20px 0 20px;
            -moz-border-radius:0 20px 0 20px;
            -webkit-border-radius:0 20px 0 20px;
        }
    }
    &.roundedleft{
        div.review-baseitem-container, div.review-total, div.review-step-container, div.breadcrumb-side, div.header, div.step-header-container, div.breadcrumb-top, div.header-review,div.choice {
            border-radius:20px 0 20px 0;
            -moz-border-radius:20px 0 20px 0;
            -webkit-border-radius:20px 0 20px 0;
        }
    }
    &.rounded{
        div.review-baseitem-container, div.review-total, div.review-step-container, div.breadcrumb-side, div.header, div.step-header-container, div.breadcrumb-top, div.header-review,div.choice {
            border-radius:10px;
            -moz-border-radius:10px;
            -webkit-border-radius:10px;
        }
        img{
            border-radius:5px;
            -moz-border-radius:5px;
            -webkit-border-radius:5px;
        }
    }
    // BACKGROUNDS
    &.flat {
        div.review-baseitem-container, div.review-total, div.review-step-container, div.breadcrumb-side, div.header, div.step-header-container, div.breadcrumb-top, div.header-review,div.choice {
            background:@color-grey-lightest;
        }
    }
    &.gradient{
        div.review-baseitem-container, div.review-total, div.review-step-container, div.breadcrumb-side, div.header, div.step-header-container, div.breadcrumb-top, div.header-review,div.choice {
        .gradientWhitetoGrey;
        }
    }
    &.nobg{
        div.review-baseitem-container, div.review-total, div.review-step-container, div.breadcrumb-side, div.header, div.step-header-container, div.breadcrumb-top, div.header-review,div.choice {
            background:none !important;
        }
    }
}
/*DVPAL20150331 - CONFIGURATOR LAYOUT STYLES - END*/



/* END configurator.less */