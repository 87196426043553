@import "../../bootstrap/variables.less";
@import "../variables.less";    


/* START addtocart.less */
// DVPDS - No references exist to this entry
// DVPAL20140623 - added to style add to cart button in multi-dim
div.addtocart
{
text-align: right;
margin-top: -25px;
margin-bottom: 10px;
&.nomargin{
    margin-top:0;
    margin-bottom:0;
}

    @media (max-width: @screen-xs-max){
        width:100%;
        margin:0;
        input.btn{
            width: 100%;
            margin: 10px 0 5px 0;
        }
    }
}

div.atcQTY {
	float:right;
	vertical-align:middle;
	padding:0 0 0 4px;
}

// added margin for top spacing in productcompare.aspx
.comparetdr .input-append {
    margin: 5px;
}
/* END addtocart.less */