﻿@import "../../bootstrap/variables.less";
@import "../variables.less";

/* START category.less */
.category-standard-wrapper
{
     position:relative;
     @media (max-width:@screen-xs-max){
         text-align:center;
     }
}
.category-standard-image-col
{
      display: inline-block;
      margin-right:15px; //DVPDS - added to provide margin between image and title/extended description
          /*width: 50px;
          height: 20px;
          background-color: #f00;*/
}

.category-standard-conten-col
{
    display: inline-block;
    width: 70%;
    vertical-align: top;

     @media (max-width: @screen-md-max)
    {
         width:65%;
    }
    @media (max-width: @screen-sm-max)
    {
         width:48%; // DVPAL20140616 - changed from 50% to 48% to allow for margins and padding (side-by-side)
    }

    @media (max-width: @screen-xs-max)
    {
        margin-top:10px; //DVPAL20140616 - added margin in XS viewport
        display:block; 
        width:100%;
    }
}

.category-main-wrapper
{
     position:relative;
}
.category-main-image-col
{
      display: inline-block;
          /*width: 50px;
          height: 20px;
          background-color: #f00;*/
}

.category-main-conten-col
{
    display: inline-block;
    width: 70%;
    vertical-align: top;

     @media (max-width: @screen-md-max)
    {
         width:65%;
    }
    @media (max-width: @screen-sm-max)
    {
         width:50%;
    }

    @media (max-width: @screen-xs-max)
    {
        display:block; 
        width:100%;
    }
}

/* title section of the category page*/
div.categorytitle {
    margin: 20px 0 0 0;
    padding:5px;
    background:@background-lighter;
}

div.midtitle {
    margin: 20px 0 10px 0;
    padding:5px;
    background:@background-lighter;
}

/* END category.less */