@import "../../bootstrap/variables.less";
@import "../../bootstrap/mixins.less";
@import "../variables.less";

/* START navbar.less */
// navmenu defines styles for the top dropdown category menu


#NavBar
{
    #NavButtons
    {
        //DVPAL20150513 - pushed to be closer to navigation bar
        margin:-20px 0 0 0;
        // at the smallest view just push it up absolute
        position: relative; //DVPJL20140501 DVPDQ20140513 Change it to absolute
        top: 0;
        //right: 80px; //DVPJL20140501 absolute*/
        @media (min-width: @screen-sm-min)
        {
            //position: relative; //DVPJL20140501
            right: inherit; 
            top: inherit;
        }

        @media (max-width: @screen-md-max){
            margin-top:-10px;
            margin-bottom:10px;

        }
        @media (max-width: @screen-sm-max){
            margin-top:-5px;
        }

        @media (max-width: @screen-xs-max )
        {
             margin: 5px 0 5px 0;
             float:none;
             text-align:right;
             width:100%;
        }

        & > li
        {
            display: block;
            float: left;
            @media (max-width: @screen-xs-max){
                margin: 10px 0;
                display:inline-block;
                float:none;
                  height: 30px;
                  vertical-align: middle;
                            }
        }

            & > li > a
            {
                font-size: 1.5em;
                color: @themeColor; //DVPDS - changed from white to blue
            }

                & > li > a:hover
                {
                    color: @themeColor-dark; //DVPDS - changed from white to dark blue                  
                }

        .dropdown
        {
            .open
            {
                .dropdown-toggle
                {
                    .fa
                    {
                        color: @color-grey-darker;
                    }
                }
            }
        }
    }

    #NavDropDown
    {
        .navbar-right
        {
        }
    }
}

// @Override
.navbar-default {
    border-radius: 0px;
    //background-color: @navbarBackgroundBaseColor; // DVPDS - old color: #3399cc
    /*background:none; // DVPAL20140730 - transparent background*/
    background:@body-bg; //DVPAL20150309 - defaults to the same body bg colour 
    border: none;

    z-index: 9000; // TODO: Fix z-indices
    margin-bottom: 0;

    .navbar-nav>li>a {
        color: @color-white;
    }

    .navbar-brand {
        color: @brand-primary; // DVPAL - reference to primary brand variable
        font-size: 2.5em;
        padding: 0; //DVPJL20140501
        @media (max-width: @screen-xs-max){
            width:100%;
        }
        //DVPAL20140902 - added to allow branding logo to scale when in XS viewport
        img{
            width:auto;
            height:auto;
            max-width: 100%;
            max-height: 100%;
            @media (max-width: @screen-xs-max){
                display:block;
                padding:5px;
                margin:0 auto;
            }
        }
       /* 
        @media (max-width: @screen-xs-max) {
             //display: none;   
        }
           */ 
    } 
}

/* DVPAL20140527 - responsive behaviour for navbar cart preview and dropdown*/

.nav-cart-preview {
            margin-left:10px;
    div.nav-cart-summary{
        cursor:pointer;
        /*border-radius:@border-radius-base;*/
        border-radius:0;
        min-width:160px;
        width:auto;
        color:@themeColor-dark;
        //background: @marqueeBackground;
        background:@background-default;
        border:1px solid @themeColor;
        margin-top:15px;

        padding:1px 10px 0px 10px;
        height:22px;

        @media (max-width: @screen-sm-max){
            display: none;
        }
    }

    a.dropdown-toggle{
        @media (min-width: @screen-sm-max){
            display:none;
        }
    }
}

/* DVPAL20140522 - Added to override default bootstrap navbar styles*/
.navbar-toggle {
    background-color: @navbar-default-toggle-bg;
    border: 1px solid @navbar-default-toggle-border-color !important; // DVPAL20140522 required to override bootstrap's default border
    /* DVPAL20140522 - box shadows added to three dimensional effect*/
    -webkit-box-shadow: inset 0px -3px 0px rgba(0, 0, 0, 0.2), 0px 1px 6px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: inset 0px -3px 1px rgba(0, 0, 0, 0.35), 0px 1px px rgba(0, 0, 0, 0.25);
    box-shadow: inset 0px -3px 0px rgba(0, 0, 0, 0.2), 0px 1px 6px rgba(0, 0, 0, 0.25);

    @media (max-width: screen-sm-max){
        margin-top:8px;
    }
    &:active {
        /* DVPAL20140522 - top offset and box shadow adjustment on click/tap*/
        top: 2px;
        background-color: #377ba8;
        -webkit-box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.2), 0px 1px 6px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.35), 0px 1px px rgba(0, 0, 0, 0.25);
        box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.2), 0px 1px 6px rgba(0, 0, 0, 0.25);
        }
    }

.dropdown-search {
    width: 220px;
    padding: 5px;

    .form-group {
        margin: 0;
    }

    input {
        width: 63%;
        float: left;
    }

    button {
        float: right;
    }
}

.navbar-brand {
    padding-top: 8px;
}


// TODO: Clean this all up!

/* DVPAL20140617 - added for CSS3 animation effects on nav menu - START*/

.navmenu ul ul li, .navmenu ul li{ // added for background color fades only
    -webkit-transition: background-color 0.2s ease;
    -moz-transition: background-color 0.2s ease;
    -o-transition: background-color 0.2s ease;
    -ms-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease;
}

.navmenu ul li:hover>ul, .navmenu ul li:focus>ul {
    opacity: 1; // when hovering, margin offset is removed and opacity is set to 100%
    margin-top: 0;
}
.navmenu ul ul {
    opacity: 0; // passive state: opacity of 0% and margin offset, both transitioned
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease;
    margin-top: -10px;

    @media (max-width: @screen-sm-max){
        opacity: 1 !important; // mobile nav menu does not have the effects
        margin-top: 0 !important;
    }
}
/* DVPAL20140617 - added for CSS3 animation effects on nav menu - END*/

.navmenu
{
    white-space: nowrap; 
    font-size: @font-size-base;
    font-weight: normal;
    z-index: 8500;
    width: @topCategoryWidth;

    .Menu-Vertical
    {
        position: relative;
        top: 3em;
        left: 0;
        z-index: 8500;

        // Top tier
        ul.Menu
        {
            width: 9em;
            height: 22px;
        }
    }

    // The menu adapter renders an unordered list (ul) in HTML for each tier in the menu. So, effectively says:style all tiers in the menu this way...
    ul
    { 
        // DVPDS - menu inherits existing bg color
    }
        // This rule effectively says:style all tiers EXCEPT THE TOP TIER in the menu this way...
        // In other words, this rule can be used to style the second and third tiers of the menu without impacting the topmost tier's appearance.
        ul.Menu
        {
            .clearfix;

            ul
            {
                width: auto;
                left: 8.9em;
                top: -1.1em;
                z-index: 8500;
                background-color: @themeColor;
                 @media (max-width: @screen-sm-max) {
                       background:none; /*DVPDQ20140512-Change hover color*/
                  }
                ul
                {
                    left: 10.4em;
                }
            }
            // The menu adapter generates a list item (li) in HTML for each menu item. Use this rule create the common appearance of each menu item.
            li
            {
                // Within each menu item is a link or a span, depending on whether or not the MenuItem has defined it's
                // NavigateUrl property. By setting a transparent background image here you can effectively layer two images
                // in each menu item.  One comes from the CSS rule (above) governing the li tag that each menu item has.
                // The second image comes from this rule (below).
                .menu-chevron{
                    a, span{
                        &:focus{
                            background-color: @themeColor;
                        }
                    }
                }
                a, span
                {
                    outline:none; // DVPAL20140522 - added to prevent dotted outline on click for FireFox and IE
                    color: @color-white;
                    padding: 7px 10px;
                    cursor: pointer;
                    white-space: nowrap;
                    position:relative;

                    &:focus{
                        background:@themeColor-dark;
                        text-decoration:underline;
                    }
                    &:active{
                        text-decoration:none;
                    }

                }
                    // Not used presently.  This is here if you modify the menu adapter so it renders img tags, too.
                    a img
                    {
                        border-style: none;
                        vertical-align: middle;
                    }

                /*DVPDQ20140513-Comment out to remove after*/
                /*
                & > a:after,
                & > span:after
                {
                    content: "";
                }*/
            }
            // When a menu item contains no submenu items it is marked as a "leaf" and can be styled specially by this rule.
            li.Menu-Leaf
            {
                a, span
                {
                    background-image: none;
                    cursor: pointer;
                }
                /*DVPEP20140521 - NTN7.2 - Hide div when menu is a leaf.*/
                div.menu-chevron{
                display:none !important;
                }
            }
        }
}

    // When you hover over a menu item, this rule comes into play.
    // Browsers that do not support the CSS hover pseudo-class, use JavaScript to dynamically change the
    // menu item's li tag so it has the Menu-Hover class when the cursor is over that li tag.
    // See MenuAdapter.js (in the JavaScript folder).
    .navmenu ul.Menu li:hover,
    .navmenu ul.Menu li.Menu-Hover,
    .navmenu ul.Menu li.Menu-Selected
    {
        background: @navbarHoverColor;
            @media (max-width: @screen-sm-max) {
             background: none;
             /*DVPEP20140516 - NTN7.2 - Removed background color on hover, not required on smaller viewports as hover is not applicable. */
             /*background:red;*/ /*DVPDQ20140512-Change hover color*/
        }

    }

    .navmenu ul.Menu li:hover a,
    .navmenu ul.Menu li:hover span,
    .navmenu ul.Menu li.Menu-Hover a,
    .navmenu ul.Menu li.Menu-Hover span,
    .navmenu ul.Menu li:hover li:hover a,
    .navmenu ul.Menu li:hover li:hover span,
    .navmenu ul.Menu li.Menu-Hover li.Menu-Hover a,
    .navmenu ul.Menu li.Menu-Hover li.Menu-Hover span,
    // New lines
    .navmenu ul.Menu li:hover li:hover li:hover a,
    .navmenu ul.Menu li:hover li:hover li:hover span,
    .navmenu ul.Menu li.Menu-Hover li.Menu-Hover li.Menu-Hover a,
    .navmenu ul.Menu li.Menu-Hover li.Menu-Hover li.Menu-Hover span,
    // end new lines 
    .navmenu ul.Menu li:hover li:hover ul a:hover,
    .navmenu ul.Menu li:hover li:hover ul span.Asp-Menu-Hover,
    .navmenu ul.Menu li.Menu-Hover li.Menu-Hover ul a:hover,
    .navmenu ul.Menu li.Menu-Hover li.Menu-Hover ul span.Asp-Menu-Hover
    {
        color: @color-white;
        cursor: pointer;
        @media (max-width: @screen-sm-max) {
            background: none;
        }

    }

    .navmenu ul.Menu li:hover ul a,
    .navmenu ul.Menu li:hover ul span,
    .navmenu ul.Menu li.Menu-Hover ul a,
    .navmenu ul.Menu li.Menu-Hover ul span,
    .navmenu ul.Menu li:hover li:hover ul a,
    .navmenu ul.Menu li:hover li:hover ul span,
    .navmenu ul.Menu li.Menu-Hover li.Menu-Hover ul a,
    .navmenu ul.Menu li.Menu-Hover li.Menu-Hover ul span,
    .navmenu ul.Menu li:hover li:hover li:hover ul a,
    .navmenu ul.Menu li:hover li:hover li:hover ul span,
    .navmenu ul.Menu li.Menu-Hover li.Menu-Hover li.Menu-Hover ul a,
    .navmenu ul.Menu li.Menu-Hover li.Menu-Hover li.Menu-Hover ul span,
    .navmenu ul.Menu li:hover li:hover li:hover li:hover ul a,
    .navmenu ul.Menu li:hover li:hover li:hover li:hover ul span,
    .navmenu ul.Menu li.Menu-Hover li.Menu-Hover li.Menu-Hover li.Menu-Hover ul a,
    .navmenu ul.Menu li.Menu-Hover li.Menu-Hover li.Menu-Hover li.Menu-Hover ul span
    {
        color: @color-white;
        cursor: pointer;
        @media (max-width: @screen-sm-max) {
            background: none;
        }

    }

    // While you hover over a list item (li) you are also hovering over a link or span because
    // the link or span covers the interior of the li.  So you can set some hover-related styles
    // in the rule (above) for the li but set other hover-related styles in this (below) rule.
    .navmenu ul.Menu li a:hover,
    .navmenu ul.Menu li span.Asp-Menu-Hover
    {
        color: @navbarFontHoverColor;
        //background: @navbarHoverColor; // DVPAL20140611 - removed hover color to prevent background from blocking text
        @media (max-width: @screen-sm-max) {
            background: none;
    }

    }

    .navmenu ul.Menu li.Menu-Leaf a:hover
    {
        background-image: none;
        @media (max-width: @screen-sm-max) {
            background: none;
    }

    }

    // When the Menu control's Orientation property is Horizontal the adapter wraps the menu with DIV
    // whose class is Menu-Horizontal. 
    // Note that the example menu in this web site uses absolute positioning to force the menu to occupy 
    // a specific place in the web page.  Your web site will likely use a different technique to position your 
    // menu.  So feel free to change all the properties found in this CSS rule if you clone this style sheet.
    // There is nothing, per se, that is magical about these particular property value choices.  They happen to 
    // work well for the sample page used to demonstrate an adapted menu.

    .navmenu .Menu-Horizontal
    {
        //TODO: Remove
        //position: absolute;
        //left: 0;
        //top: 5px;
        z-index: 8500;
    }

        // This rule controls the width of the top tier of the horizontal menu.
        // BE SURE TO MAKE THIS WIDE ENOUGH to accommodate all of the top tier menu items that are lined
        // up from left to right. In other words, this width needs to be the width of the individual
        // top tier menu items multiplied by the number of items.
        .navmenu .Menu-Horizontal ul.Menu
        {
            width: auto;

            ul {
                width: 100%;
                min-width: 100% !important;
            }
        }

            // This rule effectively says:style all tiers EXCEPT THE TOP TIER in the menu this way...
            // In other words, this rule can be used to style the second and third tiers of the menu without impacting 
            // the topmost tier's appearance.
            // Remember that only the topmost tier of the menu is horizontal.  The second and third tiers are vertical.
            // So, they need a much smaller width than the top tier.  Effectively, the width specified here is simply
            // the width of a single menu item in the second and their tiers.
            .navmenu .Menu-Horizontal ul.Menu ul
            {
                min-width: 150px; // second level width
                left: 0;
                top: 100%;
            }

                .navmenu .Menu-Horizontal ul.Menu ul ul
                {
                    top: 0px;
                    width: auto; // third level width
                }

            // Generally, you use this rule to set style properties that pertain to all menu items. 
            // One exception is the width set here.  We will override this width with a more specific rule (below) 
            // That sets the width for all menu items from the second tier downward in the menu. 
            .navmenu .Menu-Horizontal ul.Menu > li
            {

                /*DVPEP20140515 - NTN7.2 - Assigned new class to menu chevron to keep track of functionality and specific styles. We want this span to display in smaller viewports. - BEGIN */
                div.menu-chevron{
                   display:none;
                }
                div.menu-cat-link{
                    display:inline-block;
                    width:100%;   
                }
                /*DVPEP20140515 - NTN7.2 - Assigned new class to menu chevron to keep track of functionality and specific styles. We want this span to display in smaller viewports. - END */

                // first level width - DVPDS20080825 - This is a minimum width value. The actual
                // width is now determined by the length of the category name (without wrapping). 
                // Code to prevent wrapping is contained in the MenuAdapter.vb file (app_code\Adaptors) 
                // Style setting for the first level links:.navmenu ul.Menu li a, 
                text-align: left;
                //min-height: @navbarHeight + 2px;
                
                & > a,
                & > ul {
                    border-top: 0px solid #3399cc;                 
                }

                & > ul {

                }

                   @media (max-width: @screen-sm-max) {
                    width: 100%; 
                    &.Menu-WithChildren {
                       /*DVPDQ20140513-Comment out to remove the after*/
                       /*
                         & > a:after {
                            font-family: FontAwesome;
                            content: "\f078";
                            font-size: 1.2em;
                            display: inline-block;
                            position: absolute;
                            top: 5px;
                            right: 10px;
                            color: #3399cc;
                        }*/

                    /*DVPEP20140515 - NTN7.2 - Assigned new class to menu chevron to keep track of functionality and specific styles. We want this span to display in smaller viewports. - BEGIN */
                    div.menu-chevron{

                        display:inline-block;
                        width:10%;
                        text-align:right;
                        vertical-align:bottom; // DVPDS - added these three lines to position the chevron
                        margin-bottom:6px;
                        margin-top:-2px;

                        & a > span.chevron-right{
                            display:none;
                        }

                        & a > span{
                            display:inline;
                            padding:0px;
                            float:right;
                            //transition: all 0.3s ease-out 0s;
                        }

                         & a > span.chevron{
                             //DVPAL20150415 - disabled drop shadow
                             /*text-shadow: 3px 3px 2px #272656;*/
                               transition: .3s ease-out;
                         }
                         
                         & a > span.chevron:active{
                             /*text-shadow: 0px 0px 0px #272656;*/
                             top:3px;
                         }

                        & a > span:after{
                            clear:right;
                        }

                    }
                    div.menu-cat-link
                    {
                        width:85%; /*DVPAL20140521 changed to 85% from 89% to prevent chevrons from being pushed onto a new line*/
                        display:inline-block;
                        text-align:left;
                    }
                    /*DVPEP20140515 - NTN7.2 - Assigned new class to menu chevron to keep track of functionality and specific styles. We want this span to display in smaller viewports. - END */


                    }
                    span {
                        display: none; 
                    }
                    & > a {
                        background: @themeColor-dark; //DVPDS - set using variable instead (old: #123579)
                    }
                }

            }

            // This rule establishes the width of menu items below the top tier.  This allows the top tier menu items
            // to be narrower, for example, than the sub-menu items.
            // This value you set here should be slightly larger than the left margin value in the next rule. See
            // its comment for more details.
            .navmenu .Menu-Horizontal ul.Menu ul li
            {
                text-align: left;
                width: auto;
                float: none;
                & > div
                {
                    a, span
                    {
                        //padding: 5px 25px 5px 10px; // DVPAL20140522 - removed for aligning non-tier 1 chevrons
                        padding: 5px 5px 5px 10px;
                    }
                }
            }


            .Menu-WithChildren {
                    & > div {
                        & > a, 
                        & > span
                        { /*DVPDQ20140513-Comment out to remove the after*/
                          /*
                            &:after
                            {
                                font-family: FontAwesome;
                                content: "\f078";
                                font-size: 1.2em;
                                display: inline-block;
                                position: absolute;
                                top: 5px;
                                right: 10px;
                                color: #3399cc;
                            }
                           */
                        }
                    }
                    
                    @media (max-width: @screen-sm-max) {
                        display: block;
                        //float: none !important; //DVPAL20150306 - commented out to prevent navbar breaking in SM vp
                        .Menu-Selected  {
                            //background: #3399cc !important;
                        }

                        .Menu-ParentSelected {
                            /*background: #fff;
                            a {
                                color: #333 !important;                               
                            }*/
                        }
                    }
                }

            

            .navmenu .Menu-Horizontal ul.Menu ul ul li
            {
                width: auto;
            }

            /*DVPEP20140520 - NTN7.2 - Added for second tier children. - BEGIN */
            .navmenu .Menu-Horizontal ul.Menu li ul
            {
                width: auto; // DVPJL20140702 - ntn72013 - fix top nav category too small issue.
                
                li div.menu-cat-link{
                    width:70%;
                }
                li div.menu-chevron{
                    text-align:right;
                    width:30%; /*DVPAL20140521 - changed to 15% from 30% to allow for same-line positioning*/
                    //padding-right:10px;
                }

                @media (max-width: @screen-sm-max)
                {
                    //background-color:#1471cc; /*DVPAL20140522 - tier 2 category colour*/
                    background-color:@menubarlevel2Background; //DVPDS - updated to use new variable
                }


                /*DVPEP20140515 - NTN7.2 - Assigned new class to menu chevron to keep track of functionality and specific styles. We want this span to display in smaller viewports. - BEGIN */
                div.menu-chevron{
                   display:inline-block;
                    & a > span{
                        display:none;
                    }
                    & a > span.chevron-right{
                        display:inline-block;
                    }
                }
                /*DVPEP20140515 - NTN7.2 - Assigned new class to menu chevron to keep track of functionality and specific styles. We want this span to display in smaller viewports. - END */

            }
            /*DVPEP20140520 - NTN7.2 - Added for second tier children. - END */

            // Third tier menus have to be positioned differently than second (or top) tier menu items because
            // they drop to the side, not below, their parent menu item. This is done by setting the last margin 
            // value (which is equal to margin-left) to a value that is slightly smaller than the WIDTH of the
            // menu item. So, if you modify the rule above, then you should modify this (below) rule, too.
            .navmenu .Menu-Horizontal ul.Menu li ul li ul
            {
                top:14px;
                left:0px;
                margin: -1em 0 0px 100%;
                @media (max-width: @screen-sm-max) {
                margin: 0;
                //background-color:#287dd0; /* DVPAL20140522 - tier 3 category colour*/
                background-color:@menubarlevel3Background; //DVPDS - updated to use new variable
                }

            }

                // START fourth tier menu
                .navmenu .Menu-Horizontal ul.Menu li ul li ul li ul
                {
                    /*DVPAL20140521 - removed in order to inherit margins from third tier menus (IE compatibility) */
                    //margin: 0px 0 0 178px; /*DVPDQ20140512-menuposition*/ 
                           @media (max-width: @screen-sm-max) {
                        margin: 0;
                        //background-color:#3b87d4; /* DVPAL20140522 - tier 4 category colour*/
                        background-color:@menubarlevel4Background; //DVPDS - updated to use new variable
                    }
                }
                 // START fifth tier menu
                .navmenu .Menu-Horizontal ul.Menu li ul li ul li ul li ul
                {
                    /*DVPAL20140521 - removed in order to inherit margins from third tier menus (IE compatibility) */
                   // margin: 0px 0 0 178px; /*DVPDQ20140512-menuposition*/
                    @media (max-width: @screen-sm-max) {
                        margin: 0;
                        //background-color:#4c91d8; /* DVPAL20140522 - tier 5 category colour, tier 6 does not exist, but if it did, use #5b9adb as colour*/
                        background-color:@menubarlevel5Background; //DVPDS - updated to use new variable
                    }
                }



.changecat
{
    line-height: 24px;
}



// sets DIV area for Change Catalog section
div#category
{
    width: @categoryWidth;
    height: @categoryHeight;
    // border-right: @categoryBorders; //DVPAL20140528 - removed to prevent off-color right border
    position: @categoryPosition;
    margin-top: @categoryTop;

    color: @color-white;
    display: inline-block;
    text-align: center;
    vertical-align: top;
    float: left;
    
    &:hover {
        #gradient > .vertical(@menuBarGradientTop, @menuBarGradientBottom);
    }

    // used for Change Category link
    a.categorytext
    {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        text-align: left !important;
        padding: 7px 10px;
        color: @color-white;
        background: @themeColor-light;//DVPDS - old blue: #3399cc
        width: 100%;
        display: block;
        height: 100%;

    }

    

    @media (max-width: @screen-sm-max) {
        display: block;
        float: none !important;
        width: 100%;
        border-bottom:0px solid @color-white; /*DVPDQ20140512Border-bottome*/
    }
}


.navmenu .Menu-Horizontal ul.Menu li.Menu-WithChildren {
    & > ul {
        // hide the third levels
        @media (max-width: @screen-sm-max) {
            display: none;
        }
    }

    &.Menu-Selected > ul {
        // hide the third levels
        @media (max-width: @screen-sm-max) {
            display: block;
        }
    }
}

@media (max-width: @screen-sm-max) {
    .navmenu .Menu-Horizontal ul.Menu .Menu-WithChildren.Menu-Selected.active,
    .navmenu .Menu-Horizontal ul.Menu .Menu-WithChildren.active {
        & > a {
            background: #3399cc;
       
            // the arrow
            &:after {
                color: @color-white;
                content: "\f077"; /*DVPDQ20140512*/
            }
        }

        & > ul {
            display: block !important;
        }
    }
}

// defines the top category section within the MainArea div
div#TopCategory
{
    z-index: 5000;
    display: inline-block;
    //#gradient > .vertical(@menuBarGradientTop, @menuBarGradientBottom); /* DVPAL20140522 - removed gradient*/
    background-color: @themeColor; /* DVPAL20140522 - Tier 1 category colour*/
    float: left;

    @media (max-width: @screen-sm-max) {
        display: block;
        float: none !important;
        width: 100%;
    }
}

/* DVPAL20140610 - specific Font awesome adjustments for navbar*/

i.fa.fa-sign-in.mright, i.fa.fa-sign-out.mright{
    margin-left:-15px; // added for offset to center text in login/logout button
}
li div.navwelcomemsg 
{
    margin-left:10px;
    /*border-radius: @border-radius-base;*/
    border-radius:0;
    height:22px;
    margin-top:15px;
    border:1px solid @themeColor;
    color:@themeColor-dark;
    padding: 1px 10px 0px 10px;
    a{
        font-size:13px !important;
    }
}


// DVPAL20150603 - for top-level navbar styling - START

/*div.navmenu > div.Menu-Horizontal > ul.Menu > li > div > div > a{
    border-style here
}*/

// DVPAL20150603 - for top-level navbar styling - END


/*.edge{
    i.fa.fa-chevron-right{
        &:before{
            content:"\f053";
        }
    }
}*/
/* END navbar.less */