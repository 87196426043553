﻿@import "../variables.less";
@import "../../bootstrap/variables.less";
@import "../../bootstrap/mixins.less";

/* for the search element of the reports pages */
.searchreports {
    div{
        float:left;
        margin-right:5px;
        line-height:32px;
    }
}

/* for the navigation on the reports pages */
.reports-navigate{
    div{
        float:left;
        margin-right: 3px;
        margin-bottom:3px;
        background-color:@background-default;
        width:24%;

        a {
            position:relative;
            display:block;
            padding:3px;
            text-decoration:none;

            &:hover {
                background:@background-lighter;
            }
        }
    }
}
