@import "../../bootstrap/variables.less";
@import "../variables.less";

/* START productlightbox.less */
// - - - - START - This is for the IMAGE mouseovers on product templates - - - -
div#dhtmlgoodies_slideshow {
	width:214px;    // Total width of slideshow
	padding:10px 0 0 0;
    text-align:center;
    margin:0 auto;
    //@media (max-width: @screen-sm-max){ commented out since the gallery preview is always centered
     //   margin:0 auto; //DVPAL20140603 - center the gallery slideshow in <MD viewports
    //}
}

div#dhtmlgoodies_slideshow2 {
	width:150px;    // Total width of slideshow - added for alternate item template
	padding:10px 0 0 0;
}

div#galleryContainer {
	//height:46px;	// Height of the images + 2 (DVPDS - removed duplicate height)
	position:relative;
	overflow:hidden;
	padding:1px;
	// CSS HACK
	//height:48px;	// IE 5.x - Added 2 pixels for border left and right
	//height/* */:/**/46px;	// Other browsers
	//height:/**/46px;
    height: 55px;
}

#xarrow_left,
#xarrow_right {
    height: 100%;
}

div#xarrow_left {
	position:absolute;
	vertical-align:middle;
	left:0;
	z-index:100;
    background:@background-default;
	padding:0;
	cursor:pointer;
}

div#xarrow_right {
	position:absolute;
	vertical-align:middle;
	right:0;
	z-index:100;
    background:@background-default;
	padding:0;
	cursor:pointer;
}

div#theImages {
	position:absolute;
	left:15px;
	width:2000px;
	z-index:50;
}

div#theImages #slideEnd {float:left; left:15px;}

div#theImages img {
	float:left;
	padding:1px;
	-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=50)"; // IE8
	opacity:0.5;  // other browsers   
	filter:alpha(opacity=50); // IE 7 
	cursor:pointer;
	border:solid 3px @default-border-color;
    .transition(all 200ms ease-in-out);
}

div#theImages .imageCaption {display:none;}

/* END productlightbox.less */