@import "../../bootstrap/variables.less";
@import "../../bootstrap/mixins.less";
@import "../variables.less";

/* START shoppingcart.less */
// used on the payment control only
div#d_calendar
{
    position: absolute;
    background-color: @background-default;
    padding: 0;
    width: 300px; // set the width here to cover hidden My Favorites dropdown
    height: 180px; // set the height here to cover hidden My Favorites dropdown
    vertical-align: middle;
}

div#hideshow
{
    position: absolute;
    border: 0;
    background-color: @background-default;
    padding: 0;
    width: 300px; // set the width here to cover hidden My Favorites dropdown
    height: 180px; // set the height here to cover hidden My Favorites dropdown
    vertical-align: middle;
}

// used on the Admin dropped cart summary page only
div#d_calendar2
{
    position: absolute;
    background-color: @background-default;
    padding: 0;
    width: 300px; // set the width here to cover hidden My Favorites dropdown
    height: 180px; // set the height here to cover hidden My Favorites dropdown
    vertical-align: middle;
}

// used on the Admin dropped cart summary page only
div#d_calendar3
{
    position: absolute;
    background-color: @background-default;
    padding: 0;
    width: 300px; // set the width here to cover hidden My Favorites dropdown
    height: 180px; // set the height here to cover hidden My Favorites dropdown
    vertical-align: middle;
}

td.scart
{
    background-color: @background-lightest;
    padding: 5px 3px;
}

// Affects: Saved Carts
//=====================
.detailssc
{
    color: @color-black;
    background-color: @background-default;
    position: relative;
    float: left;
    width:100%;
    //width: 628px;// DVPAL20140624 - removed to make tables responsive
    margin: 0;
}

.detailssc2
{
    display:block;
    color: @color-grey-dark;
    background-color: @background-lighter;
    position: relative;
    padding: 10px 5px;
    margin-top:-1px;
    .borderStyle-base;
    //width: 621px;// DVPAL20140624 - removed to make tables responsive

    &:hover, &:focus
    {
        background-color: @color-grey-light2;
    }
}

div.detailssc3
{
    background-color: @background-lightest;
    position: relative;
}

td.detailssc-buttons
{
    background-color: @background-lighter;
    padding: 0 5px;
}

// Table headers in reports
td.detailsheader
{
    padding: 3px;
    background-color: #e3e9ed;
}

.details .tdhdr
{
    background-color: @background-light;
}

// used on the preview.aspx page, used for the processing order area when submit is clicked
div#processingOrder
{
    position: relative;
    left: 0;
    top: -4em;
    display:none;
    //visibility: hidden;
    .borderStyle-base;
    background-color: @background-default;
    width: 170px; // set the width here to cover hidden My Favorites dropdown
    height: 100px; // set the height here to cover hidden My Favorites dropdown
    vertical-align: middle;
    margin:0 auto; /*DVPEP20140604 - NTN7.2 - Added to center processor indicator div above submit button when visibile.*/
}

div#processingFile
{
    padding:10px;
    text-align:center;
    position: relative;
    .borderStyle-base;
    background-color: @background-default;
    width: 170px; // set the width here to cover hidden My Favorites dropdown
    height: 100px; // set the height here to cover hidden My Favorites dropdown
    vertical-align: middle;
}

// used for the save cart button on the shopping cart
.cartsavebtn
{
    display: none;
    visibility: hidden;
}

// used for various sections in checkout
// DVPDS - not in use
//table.checkoutsec
//{
//    border: solid 1px #ccc;
//   background-color: #fbfbfb;
//}

/* END shoppingcart.less */