﻿@import "../../bootstrap/variables.less";
@import "../../bootstrap/buttons.less";
@import "../../bootstrap/forms.less";
@import "../../base/forms.less";
@import "../variables.less";

/* START misc.less */
.asterisk,
.error
{
    color: @state-danger-text;// DVPAL20140612 - color changed to bootstrap's built-in 'danger' color
}

.asterisk
{
    font-size: 7px; // DVPAL20140612 - font size reduced to 7 from 12 because FA scales differently than regular text

    &:before{ // DVPAL20140612 - added to have FA icon built into the class
        content: "\F069";
        font-family: FontAwesome;
        position:absolute;
    }
}

.error
{
    font-size: @font-size-small;
}


// Used in:AccountMenu.ascx, LeftCartSummary.ascx, leftcategories.ascx LeftCategoryWithoutTree.ascx,
// LoginSummary.ascx, RecentlyViewed.ascx, Searchbar.ascx, WhatsHot.ascx, predictor.ascx, product_control_alt.ascx
// Used for Sales Agent title in LoginSummary.ascx
//DVPDS20140708 - changed ID to Class (# --> .)
div.sectionTitles,
div.sectionTitlesSA
{
    background:@sectionTitleBG;
    padding: 0 0 1px 5px;
}

// Used for section titles:AccountMenu.ascx, LeftCartSummary.ascx
// leftcategories.ascx, LeftCategoryWithoutTree.ascx, LoginSummary.ascx, RecentlyViewed.ascx
// Searchbar.ascx, WhatsHot.ascx, predictor.ascx, product_control_alt.ascx
.ctrltitle
{
    font-size: 16px;
    color: @ctrlTitleColor;
    font-weight: normal;
    text-align: left;
}

// Used for section titles:CatalogHighlightItems.ascx
.ctrltitle2
{
    .ctrltitle;
    font-weight: bold;
}

// DVPDS - no longer used
// Used in:category_image_bg.ascx, category_second_level.ascx, CategoryItems.ascx
// subcategorylist.ascx, tcategory.ascx, ItemList.ascx
//td.midtitle
//{
//    padding: 5px 0 4px 0;
//}

// used for Your Price on the product details page
//DVPAL20150610 - no longer used, see .bold for generic bold class
/*span.bold
{
    font-weight: 700;
}*/

// DVPAL20150204 - used for review score label on product pages when there are no reviews
span.noreviewlbl{
    font-size:12px;
    color:@themeColor;
    @media (max-width:@screen-xs-max){
        display:block;
    }
}

// overrides the GeneralAJAXCalendar breaking the layout
span.msg
{
    display: inline !important;
}

// Used for title on Displayaddress.ascx 
span.tabletitle
{
    font-size: 12px;
}

.break-top
{
    margin-top: 10px;
}

ul.min
{
    font-size: @font-size-small;
    margin: -10px 0 5px 0;
    list-style: inside square;
}

    ul.min li
    {
        color: @color-grey-dark;
    }

input.small-squared
{
    font-size: @font-size-small;
    padding: 2px;
    margin-bottom: 2px;
    border-radius: 0;
}

//---------------
// CONTROL SIZES
//---------------
span.large
{
    font-size: 15px;
    color: @color-grey-dark;
    padding-left: 0;
}

select.wided
{
    width: 120px; // Or whatever width you want.
    background-color: @background-default;
}

select.wide
{
    max-width: 120px;
    width: auto; // Or whatever width you want.
}

select.expand
{
    width: auto;
}

select.expanded
{
    width: auto;
    background-color: @background-default;
}

/* used for hidden elements */
.hidden {
    display: none;
}

/* used for highlight images (for example, on the logged in welcome page) */
img.highlite {
    border:solid 3px @color-white;
    width:56px;
    height:56px;
    &:hover {
        border:solid 3px @default-border-color;
    }
}


.align-center {
    text-align: center;
}

// Affects: shipment method area of Payment.aspx
div#yellowbox {
    .borderStyle-base;
    background-color: #fffdd4;
    padding: 2px;
    float:right;
    margin-right: 10px;
    @media (max-width: @screen-xs-max) {
        //.col-xs-12
        width:98%;
    display: inline-block;
    /*margin-left:10px;*/
    float:left;
    }

    @media (min-width: @screen-xs-max) {
        //.col-xs-12
        display:inline-block;
    }
}
/*DVPLL20140516-add new style for favoritediv*/
.favoritediv{
    display:inline-block;
}
//used for the SendXML control
div.XMLbodycontainer{
    width:100%;
    div.xmltextblock,div.xmlimgpreview{
        width:49%;
        display:inline-block;
        vertical-align:top;
        @media (max-width: @screen-sm-max){
            width:100%;
        }
    }
    div.xmlimgpreview{
        img{
            width:100%;
        }
    }

}

span.xml-body {
    display: inline-block;
    width: 100%;
    height: 350px;
    overflow-y: scroll;
    border: 1px solid @default-border-color-dark;
    padding: 10px;
    border-radius: @border-radius-base;
    word-wrap: break-word;
}

// used for the print preview page
div.disableddiv {
    width: 670px;
    margin: 0 auto;
  }

//DVPAL20150206 - error page styles

h1.errheader{
    font-size:@font-size-large;
    font-weight:bold;
    color:@themeColor;
}

div.errorcontent{
    p, ul{
        line-height:1;
        font-size:@font-size-base;
        color:@text-color;
    }
}

//DVPAL20150209 - coupon promotion side

div.coupon-promotion{
    /*margin-bottom:15px;*/
    width:100%;
    font-size:0;
    p {
        width:100%;
        margin-bottom:0px;
        border-bottom:1px solid @leftCategoryBodyLinkHoverColor;
        &:last-of-type{
            border:none;
        }
        @media (max-width:@screen-xs-max){
            /*width:50%;*/
            display:inline-block;
        }

        img{
        width:100%;
        margin:0
        }
    }
}


//DVPAL20150209 - coupon pages

div.coupon-content{
    p{
        font-size:@font-size-base;
        line-height:1.2;
    }
    img{
        @media (max-width:@screen-sm-max){
            width:100%;
        }
    }
}

h1.titleheading{
    font-size:@font-size-large;
    font-weight:bold;
    color:@themeColor;
}

//Newsletter form

div#newsletter{
    margin-bottom:10px;
    div.captchadiv{
        margin-top:10px;
    }
    div.newslettersignup{
    color:@themeColor-dark;
    padding:5px;
    border:1px solid @default-border-color;
    border-top:none;
    }
    .ctrltitle;
    span[id*=validator]{
        display:block;
        line-height:1.2;
        font-size:12px;
        color:@alert-danger-text !important;
    }
    img.captchaimg{
        margin: 0 auto;
        display: block;
    }
    .formInstruction, .captchadesc{
        font-size: 13px;
        margin-top: 10px;
    }
    div.emailfield{
        .formInstruction{
            margin-top:0;
        }
    }
    div{
        input[type=text]{
            .form-control;
        }
        input[type=submit]{
            .btn-primary;
            margin-top:10px;
            width:100%;
        }
    }
}

//DVPAL20150310 - infobox with caption - START
div.infobox{
    /*.hoverShadow;*/
    .transition(all 200ms ease-in-out);
    position:relative;
    overflow:hidden;
    cursor:pointer;
    width:100%;
    height:auto;
    border:1px solid @default-border-color;

    img{
        .hoverZoomImg(1.05, 200ms);
        max-height:100%;
        height:auto !important;

    }

    a:focus{
        display:block;
        border:3px solid @themeColor;
    }
    a:active{
        border:none !important;
    }

    &:hover .info-caption{
        -moz-transform: translateY(-100%);
        -o-transform: translateY(-100%);
        -webkit-transform: translateY(-100%);
        opacity: 0.7;
        transform: translateY(-100%);
    }

    .info-caption{
        .transition(all 200ms ease-in-out);
        height:30px;
        display:block;
        bottom:-30px;
        width:100%;
        line-height:25px;
        text-align:center;
        opacity:0;
        position:absolute;
        color:@color-white;
        left:0;
        z-index:100;
        background:@color-black;
    }
}

//DVPAL20150310 - infobox with caption - END

span.shortdescr{
    .breakword;
}

.savedText{
    color:@color-red;
    font-weight:600;
}

//DVPAL20150416 - hover zoom effect for images
img.hoverZoom{
    .hoverZoomImg(1.1,150ms);
}

//DVPAL20150702 - advanced search result top label

.searchresultlabel{
    font-weight:600;
    font-size:@font-size-base;
    margin-bottom:5px;
}

//DVPAL20150707 - required asterisk indication container
.requiredIndicator{
    float: left;
    width: 100%;
    margin-bottom: 10px;
    margin-top: -55px;
    @media (max-width: @screen-sm-max){
          margin: 10px 0;
    }
}

h1.headertitle{
    font-size:30px;
    font-weight:300;
    color:@themeColor;
    @media (max-width: @screen-xs-max){
        text-align:center;
    }
}

/* END misc.less */