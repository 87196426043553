﻿@import "../variables.less";
@import "../../bootstrap/variables.less";

/* START subcategorylist.less */
.subcategory-list-container
{
    .row;
    margin-left: -5px;
    margin-right: -5px;
    .clearfix;

    padding: 10px 0;

    /*@media (max-width: @screen-xs-max) {
        margin-right: -15px;
        margin-left: -15px;
    }*/

    .each-sub-category
    {
        /*.hoverShadow;*/
        background-color: @background-default;
        text-align: center;
        margin: 0 5px 5px;

        //border: solid 1px #dfdfdf;
        height: auto;
        padding: 5px;
        .clearfix;

        // NOTE: due to the responsive nature of the columns, we cannot target last/first-child since it will vary based on viewsize
        // so we will offset the difference in the various view queries.
    
        // ALOT of media queries place in order of precedence descending
        @media (max-width: @screen-md-max) {
            &.col-md-3 {
            }
        }

        @media (max-width: @screen-sm-max) {
            &.col-sm-5 {
            }
        }

        @media (min-width: @screen-lg-min) {
            &.col-lg-3 {
                width:14.5%
            }

        }
    
        // This is our special case of row layout
        @media (max-width: @screen-xs-max) {
            padding: 10px 20px;
            margin: 0 0 10px 0;

            &.col-xs-12 {
                width: 100%;
            }

            .clearfix;

            .sub-category-image {
                float: left;
                margin-right: 20px;
            }

            .sub-category-short-description {
                //DVPAL20150511 - changed to text align center in XS VP
                text-align: center;
            }
        }


        .sub-category-image
        {
            a
            {
                display: block;

                img
                {
                    width: 110px;
                    height: 110px;
                    border: 0;
                }
            }
        }
        .sub-category-short-description {
            font-size: 13px;
            font-weight: 700;
            color: @color-grey-darker;
            margin: 10px 0 0 0;
        }
          
     }
   
}

/* END subcategorylist.less */