﻿/* START cursors.less */
// CURSORS
//===============================
// Note: These are mainly CSS3 cursors
.cursor-pointer {
    cursor: pointer;
}

.cursor-zoom-out {
    cursor: -webkit-zoom-out;
    cursor: -moz-zoom-out;
}

.cursor-zoom-in {
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
}

/* END cursors.less */