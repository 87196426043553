@import "variables.less";
@import "mixins.less";

/* START FA bordered-pulled.less */
// Bordered & Pulled
// -------------------------

.@{fa-css-prefix}-border {
  padding: .2em .25em .15em;
  border: solid .08em @fa-border-color;
  border-radius: .1em;
}

.pull-right { float: right; }
.pull-left { float: left; }

.@{fa-css-prefix} {
  &.pull-left { margin-right: .3em; }
  &.pull-right { margin-left: .3em; }
}
/* END FA bordered-pulled.less */