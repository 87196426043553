﻿//FONTS
// -------------------------

@font-opensans:"Open Sans", Arial, sans-serif;

//Open Sans
@font-face{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight:normal;
    src: url('../fonts/opensans/OpenSans-Regular-webfont.eot');
    src: url('../fonts/opensans/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/opensans/OpenSans-Regular-webfont.woff') format('woff'),
         url('../fonts/opensans/OpenSans-Regular-webfont.ttf') format('truetype'),
         url('../fonts/opensans/OpenSans-Regular-webfont.svg#open_sansregular') format('svg');
}

@font-face{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight:300;
    src: url('../fonts/opensans/OpenSans-Light-webfont.eot');
    src: url('../fonts/opensans/OpenSans-Light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/opensans/OpenSans-Light-webfont.woff') format('woff'),
         url('../fonts/opensans/OpenSans-Light-webfont.ttf') format('truetype'),
         url('../fonts/opensans/OpenSans-Light-webfont.svg#open_sanslight') format('svg');
}

@font-face{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight:600;
    src: url('../fonts/opensans/OpenSans-Semibold-webfont.eot');
    src: url('../fonts/opensans/OpenSans-Semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/opensans/OpenSans-Semibold-webfont.woff') format('woff'),
         url('../fonts/opensans/OpenSans-Semibold-webfont.ttf') format('truetype'),
         url('../fonts/opensans/OpenSans-Semibold-webfont.svg#open_sanssemibold') format('svg');
}

@font-face{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight:700;
    src: url('../fonts/opensans/OpenSans-Bold-webfont.eot');
    src: url('../fonts/opensans/OpenSans-Bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/opensans/OpenSans-Bold-webfont.woff') format('woff'),
         url('../fonts/opensans/OpenSans-Bold-webfont.ttf') format('truetype'),
         url('../fonts/opensans/OpenSans-Bold-webfont.svg#open_sansbold') format('svg');
}

//DVPAL20150610 - added font-weight classes

.bold{
    font-weight:700;
}
.semibold{
    font-weight:600;
}
.normal{
    font-weight:500;
}
.light{
    font-weight:300;
}
