@import "variables.less";
@import "mixins.less";
@import "../base/variables.less";

/* START BS breadcrumbs.less */

//
// Breadcrumbs
// --------------------------------------------------


.breadcrumb {
  padding: @breadcrumb-padding-vertical @breadcrumb-padding-horizontal;
  margin-bottom: @line-height-computed;
  list-style: none;
  background-color: @breadcrumb-bg;
  border-radius: @border-radius-base;
  border:solid 1px @breadcrumbBorderColor; //DVPDS - copied from the old style
  width: 100%;
  word-wrap: break-word; //DVPJL20140430 - Fixed left column cutting-off on SM & XS issue
  clear: both;

  > li {
    display: inline-block;

    + li:before {
      content: "@{breadcrumb-separator}\00a0"; // Unicode space added since inline-block means non-collapsing white-space
      padding: 0 5px;
      color: @breadcrumb-color;
    }
  }

  > .active {
    color: @breadcrumb-active-color;
  }
}

//DVPJL20140430 - Fixed left column cutting-off on SM & XS issue
@media (max-width: 394px)
{
    .breadcrumb
    {
        //width: 250px;
        word-wrap: break-word;
        margin-left:auto;
        margin-right:auto;
    }
}

/* END BS breadcrumbs.less */