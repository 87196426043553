﻿@import "../variables.less";
@import "../../bootstrap/variables.less";

/* START address.less */
/* DVPDS - used for the address control (used on the checkout page) */
.address {
    width: 100%;
    tbody tr td:first-child {
        width:35%;
    }

    tbody tr td:last-child {
        width:65%
    }

    tbody tr td {
        vertical-align:top;
        padding: 2px;
        /*DVPEP20140603 - NTN7.2 - Below added so asterisk appears right before div.*/
        div:first-of-type:before {
            content: attr(data-required) !important;
            font-weight: bold !important;
            white-space: nowrap !important;
            color: @state-danger-text !important;
            vertical-align: middle !important;
            position: absolute;
            top: 13px;
            left: -7px;
        }

        div:first-of-type {
            position: relative;
        }


        div {
            float: left;
            width: 95%;

            @media (max-width: @screen-xs-max) {
                width:95%;
            }

            @media (max-width: @screen-sm-max) {
                width:95%;
            }

            &.inline {
                /*float: left;*/ /*DVPEP20140530 - NTN7.2 - Commented out.*/
                /*width: 5%;*/ /*DVPEP20140530 - NTN7.2 - Replaced with larger width of 100% to grow with parent and have enough room for content.*/
                width: 100%;
                display: block;
                padding-left: 3px;

                .popup-message {
                    position: relative;
                    left: -150px;
                    top: 32px;
                    width: 150px;
                    background-color: @background-lightest;
                    .borderStyle-base;
                    padding: 5px;
                }
            }
        }
    }
}


/* DVPDS - used for the customer.ascx control (used on the customerinfo page) */
.customer-info {
    display:inline-block;
    vertical-align:top;
    width: 100%;

    @media (max-width: @screen-sm-max){
        width:100%;
    }

    .ci-container{
        vertical-align:top;
        padding-right:15px;
        width:49%;
        display:inline-block;
        margin-bottom:10px;
        @media (max-width: @screen-sm-max){
            width:100%;
            padding:0;
        }
    }
    .ci-container.activeCB{
        div{
            display:inline-block;
            vertical-align:middle;
            width:auto;
        }
    }
   
}

/* DVPDS - used for the customer.ascx control (used on the customerinfo page) */
.ship-to-address {
    width: 100%;
    tbody tr td  {
        padding:2px;
    }
    tbody tr td:first-child{
        width:35%;
    }
}

/* DVPDS - used for the shopper.ascx control (used on various pages) */
.shopper {
    width: 100%;
    tbody tr td  {
        padding:2px;
    }
}

/* END address.less */