﻿@import "../../bootstrap/variables.less";
@import "../../bootstrap/mixins.less";
@import "../../bootstrap/buttons.less";
@import "../variables.less";

/* START socialmedia.less */
// This element holds injected scripts inside iframes that in some cases may stretch layouts.
// So, we're just hiding it.
#fb-root {
    display: none;
}
 
// To fill the container and nothing else
.fb_iframe_widget, 
.fb_iframe_widget span, 
.fb_iframe_widget span iframe[style] {
    width: 100% !important;
}


//DVPAL20140603 - re-added social div to adjust its height to prevent overlap with buttons
div.socialdiv{
    @media (max-width: @screen-sm-max)
    {
       // margin-top:5px;
    }

}
//DVPDS - no longer used
//div.socialdiv
//{
//    background-color: #fff;
//    margin: 0 0 7px 0;
//    padding: 3px 0 0 0;
//    text-align: center;
//}

//DVPDS - no longer used
//div.socialdiv table
//{background-color: #fff;}

iframe.fbframe
{
    height: 28px;
    /*margin: 5px 0 5px 0;*/ /*DVPEP20140605 - NTN7.2 - Commented out so height of fb social div will align with other social media containers.*/
    width: 100% !important;
}

/*DVPEP20140605 - NTN7.2 - Added to space the social buttons from the item template button list. - BEGIN*/
div.socialdiv{
    margin-top:5px;
    margin-bottom:5px;
}
/*DVPEP20140605 - NTN7.2 - Added to space the social buttons from the item template button list. - END*/

/*DVPEP20140605 - NTN7.2 - Added to space out site wide social networking icons horizontally.- BEGIN*/
div.site-socialitems > div{
    display:inline-block;
}
/*DVPEP20140605 - NTN7.2 - Added to space out site wide social networking icons horizontally.- END*/
/* END socialmedia.less */