﻿/* START - quickorder.less */

.quickorderrepeater{
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom:10px
}

div.quickorderrepeater div input {
    margin-bottom: 6px;
}

div.qoTable {
    padding:0 50px 0 0;
    display: table !important;
    @media (max-width: @screen-sm-max){
        padding:0;
    }
}

div.qoRow {
    display: table-row !important;
}

div.qoCell, div.qoCellsm {
    display: table-cell !important;
    padding-right: 5px;
    
    .form-control {
        display: inline-block;
    }
}

div.qoCell {
    width: 33%;
}

div.qoCellsm {
    width: 33%;
}
/* START - quickorder.less */